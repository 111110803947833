/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:87a378a7-e177-43ed-933d-984d31f513e0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yooEiPqsI",
    "aws_user_pools_web_client_id": "395nrv6jomfn0mb5u4rtm0a2ho",
    "oauth": {},
    "aws_content_delivery_bucket": "datalinkwebsite-amplify-cloudfront-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dgdspv1xly5gm.cloudfront.net"
};


export default awsmobile;
