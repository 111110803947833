import { Injectable, Inject, OnInit } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { AppService } from "./app.service";
import * as jwt_decode from "jwt-decode";
import { PaulJWT } from "@shared/shared.models";
import { AuthService } from "./auth/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  //private _subMode: Subscription = new Subscription(); //from appSvc
  private isLive: boolean = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    @Inject("DSS_PROD_URL") private dssProd: string,
    @Inject("DSS_TEST_URL") private dssTest: string,
    private appSvc: AppService,
    private lsvc: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log('interceptor called: '+JSON.stringify(request))
    let origin = this.document.location.origin; //https://localhost:4200 or test.inventiv
    //console.log(`origin: ${origin}`);
    let predesturl = request.url; //method called by svc eg pdfParse or rdsquery
    let tok: string;

    //checks logged in
    try {
      tok = localStorage.getItem("datalink_jwt");
      //checks that token not expired
      let decodedtok = jwt_decode(tok);
      //console.log(`otk: `, tok);
    } catch (e) {
      //token expired
      alert(
        `your token has expired... you are being redirected to the home page. Please login again.`
      );
      this.lsvc.logout();
      window.location.href = origin + "/home";
    }

    //URL remap to make sure all localhost req point to test. only prod url goes to prod lambdas
    //URL FORMAT: https://e5183ieny1.execute-api.us-east-1.amazonaws.com/alpha/api/login
    this.isLive = this.appSvc._selectedEnvIsLiveSub.value;
    //  console.log("token env updated to: " + islive);
    //  this.isLive = islive;
    //});
    let uu = (this.isLive ? this.dssProd : this.dssTest) + predesturl;
    let r = request.clone({
      headers: request.headers.append("Authorization", `${tok}`),
      url: uu,
    });
    console.log(
      `URL live: ${this.isLive}: ${uu}: ${tok.substring(
        0,
        10
      )}...${tok.substring(tok.length - 10)}`
    );

    //console.log("interceptor added token: Authorization: " + `${tok}`);
    return next.handle(r);
  }
}
