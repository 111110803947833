import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { /*PDF,*/ InvFileSpecCol } from "@shared/shared.models";
import { DialogResponse, DialogSend } from "@shared/ui.models";
import { Page } from "@shared/apiinterfaces";

//THE DIALOG
@Component({
  selector: "editdialog",
  templateUrl: "./editdialog.component.html",
  styleUrls: ["./editdialog.component.css"],
})
export class EditDialogComponent implements OnInit {
  public strtrans;
  public filcol;
  public selectedFirstRowVal; //populated after select from dropdown
  public notes = "";
  public firstline = "";
  inputmappingname: string = "";
  outputmappingname: string = "";

  public custcols: InvFileSpecCol[]; //name list
  public customerSqlCols: object[]; //sql data to run test command on

  public type: "PDF" | "SQL";
  //public customerPdf: PDF = null;
  public firstPageLines: string = "";

  constructor(
    public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public d: DialogSend
  ) {
    //NOTE d IS AN OBJECT (not a DialogSend object)
    //constructors below convert the JSON representation of the object to the real thing
    //using 'AS' works for UI, but at compile the gets rid of that

    this.notes = d.trans.note;
    this.filcol = d.trans.val.substr(0, d.trans.val.indexOf("="));
    this.strtrans = d.trans.val.substr(d.trans.val.indexOf("=") + 1);
    this.inputmappingname = d.inputmappingname;
    this.outputmappingname = d.outputmappingname;

    this.type = d.type;
    console.log("instanceof Object: " + (d instanceof Object));

    d.colArray = d.colArray.sort((a, b) =>
      a.val.toLowerCase().localeCompare(b.val.toLowerCase())
    ); //properly ordered by name
    this.custcols = d.colArray;
    this.customerSqlCols = d.objArray as Page[]; //raw json to run test transform on
    if (d.type == "PDF") {
      let page0: object = this.customerSqlCols[0];
      console.log("page 0: " + JSON.stringify(page0).substring(0, 100) + "...");
      Object.keys(page0).forEach((val) => {
        //looping through each line
        this.firstPageLines +=
          val + ":" + page0[val].map((cell, idx) => `[${idx}]${cell}`) + "\n";
      });
    }
  }

  //copies to clipboard so can PASTE
  set infield(s: string) {
    console.log("set field to: " + s);
    let textarea = document.createElement("textarea");
    textarea.style.height = "0px";
    textarea.style.left = "-100px";
    textarea.style.opacity = "0";
    textarea.style.position = "fixed";
    textarea.style.top = "-100px";
    textarea.style.width = "0px";
    document.body.appendChild(textarea);

    // Set and select the value (creating an active Selection range).
    textarea.value = "tablecol['" + s + "']";
    textarea.select();
    console.log(`textarea: ${JSON.stringify(textarea)}`);
    console.log(`document: ${JSON.stringify(document.body)}`);

    document.execCommand("copy");

    this.selectedFirstRowVal =
      this.customerSqlCols[0][s] == null
        ? "-NULL-"
        : this.customerSqlCols[0][s];
    //this.strtrans=this.strtrans.concat("tablecol['"+s+"']")
  }

  submitTransform() {
    let dialogresp = {
      filcol: this.filcol,
      trans: this.strtrans,
      notes: this.notes,
    } as DialogResponse;
    this.dialogRef.close(dialogresp);
  }

  //gets column of Customer data and transforms it
  public runFirstLine() {
    let comm = this.filcol + "=" + this.strtrans;
    console.log(comm);

    if (!this.customerSqlCols) {
      alert("no valid sql data to run transform on");
      return;
    } else
      console.log(
        `running sql transform for rows: ${this.customerSqlCols.length}`
      );
    let resultarr = [];
    try {
      for (let custrow of this.customerSqlCols) {
        //let c=eval('(' + comm + ')')
        let fil = {};
        Function(
          "fil",
          "tablecol",
          '"use strict";return (' + comm + ")"
        )(fil, custrow);
        resultarr.push(fil[this.filcol.replace("fil.", "")]);
      }
      return resultarr.map((x) => x).join("\n---\n");
    } catch (e) {
      return `ERR: ${e}\nparams: ${comm}\n\n did you convert it to a string? possible that tablecol['whatever'].toString() might help`;
    }
  }

  ngOnInit() {}
}
