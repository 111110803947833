import { BehaviorSubject, of } from "rxjs";
import { Injectable } from "@angular/core";
import { LoggedInUser } from "@shared/apiinterfaces";
import { Customer, CustomerName } from "@shared/customer";
import { Auth } from "aws-amplify";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //subscribe to this to get user status updates
  public subUser = new BehaviorSubject<LoggedInUser>(null);
  public subCustomers = new BehaviorSubject<Customer[]>(null);

  //so can watch for updates
  public _subUserObservable() {
    return this.subUser.asObservable();
  }
  public _subCustomersObservable() {
    return this.subCustomers.asObservable();
  }

  //URLS ARE FROM THE API GATEWAY
  constructor(private httpClient: HttpClient) {
    this.setClaimsFromToken();
  }

  //https://github.com/awslabs/aws-support-tools/tree/master/Cognito/decode-verify-jwt
  async setClaimsFromToken(): Promise<boolean> {
    try {
      let sess = await Auth.currentSession();
      let accessToken = sess.getAccessToken();
      let jwt = accessToken.getJwtToken();
      //required for lambda auth
      //console.log(`JWT: ${JSON.stringify(jwt)}`);
      localStorage.setItem("datalink_jwt", jwt);

      //https://medium.com/@dantasfiles/three-methods-to-get-user-information-in-aws-amplify-authentication-e4e39e658c33
      //console.log(`SESS: ${JSON.stringify(sess)}`);
      //claims
      //console.log(`PAYLOAD: ${JSON.stringify(sess["idToken"]["payload"])}`);
      let tok = sess["idToken"]["payload"];
      /*
      PAYLOAD: {"sub":"355020ec-561f-49df-9977-4b082398ad91","cognito:groups":["Admin"],
      "email_verified":true,"iss":"https://cognito-idp.us-east-1.amazonaws.com/us-east-1_Jjsn2fxDm",
      "cognito:username":"paul.weiler@inventiv.com","aud":"1hpe5d82basv5ct1qu0k3nipoo",
      "event_id":"6b437d5a-9272-4aa6-aaf9-b3eeaebb7ff5","token_use":"id","auth_time":1608586643,
      "custom:CustomerName":"Admin","exp":1608590243,"iat":1608586643,"email":"paul.weiler@inventiv.com"}
      */
      //--------------------
      //console.log(`TOKKKK: ${JSON.stringify(tok)}`);
      let userpools: string[] = tok["cognito:groups"] || []; //NO USER POOLS
      if (userpools.length <= 0) {
        alert(
          `No user pools assigned. Please contact inventiv support and tell them: datalink-paul-111`
        );
      }

      let cust: Customer[] = [];
      //-------------------------------------------------------------------
      //FILTER AND UPDATE THE CUSTOMER LIST
      //-------------------------------------------------------------------
      try {
        cust = this.subCustomers.value;
        if (!cust) {
          cust = await this.GetCustomers(userpools);
        }
        console.log(`userpools: `, userpools);
        //console.log(`CUST2:`, cust);
        if (!cust || cust.length <= 0) throw `no customers available`;
        console.log(`CUST: `, cust.length);
        this.subCustomers.next(cust);
      } catch (e) {
        console.log(`ERR setting cust list: `, e);
      }
      //-------------------------------------------------------------------
      //FILTER AND SET THE Logged In User
      //-------------------------------------------------------------------
      let louser: LoggedInUser = {
        email: tok["email"],
        isEmployee: tok["email"].endsWith("@inventiv.com") || false,
        allowedCust: cust,
        isLoggedIn: true,
      };
      //console.log(`LOUSEER: ${JSON.stringify(louser)}`);
      this.subUser.next(louser);
    } catch (err) {
      console.log(`invalid token: ${err}`);
      return false;
    }
    return true;
  }

  async logout() {
    try {
      //console.log('token removed')
      localStorage.removeItem("datalink_jwt");
      this.subUser.next({
        isLoggedIn: false,
        isEmployee: false,
        allowedCust: [],
        email: "",
      });
      this.subCustomers.next([]);
      await Auth.signOut();
    } catch (e) {
      alert(`err on logout: ${e}`);
    }
  }

  public GetCustomers = async (custnames: string[]): Promise<Customer[]> => {
    let c: Customer[] = [];
    if (!custnames) throw `cannot GetCustomers(): userpools undefined`;
    let isAdminPool = custnames.includes("Admin");
    console.log(`getCustomers() is admin ${isAdminPool}:`, custnames);
    try {
      c = await this.httpClient
        .post<Customer[]>(
          "getCustomersFromDynamo",
          JSON.stringify({
            custname: "Admin", //ignored but needed for CONExt to work insde lamnda
            allcust: isAdminPool ? undefined : custnames, //pass in undefined to get whole list, otherweise is filtered
            //list of customers eg Murphy,MurphyTest
          })
        )
        // .pipe(
        //   catchError((e) =>
        //     this.handleError<Customer[]>(e, "getCustomers():", [])
        //   )
        //)
        .toPromise();
      console.log(`got ${c.length} customers`);
      if (c.length <= 0) throw `dynamo failed. resorting to hardcode`;
    } catch (e) {
      console.log(`err getting customers: `, e);
      alert(
        `TELL PAUL> THIS MAY CAUSE ISSUES: pulling hardcoded backup customers (not from Dynamo). custnames: ${custnames}`
      );
      c = Customer.CustomerListOLD;
    }

    //----------------------------
    //FILTER LIST
    //----------------------------
    //dont display these in the list at all
    c = c.filter(
      (x) =>
        ![
          CustomerName.Admin,
          CustomerName.PaulLocalDockerFavoriteBrandsTestCustomer,
        ].includes(x.name)
    );
    if (!isAdminPool) c = c.filter((x) => custnames.includes(x.name)); //eg Murphy and MurphyTest

    console.log(`GetCustomers() length:`, c.length);
    return c;
  };

  public handleError<T>(error, methodname, emptyobj): Observable<T> {
    let errstr = `Err occurred in method: ${methodname}\n`;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errstr += error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errstr += `Backend returned code ${
        error.status
      }\nresponse body was: ${JSON.stringify(error)}`;
    }
    /*
    Err occurred in method: rdsquery
Backend returned code 500
response body was: {"headers":{"normalizedNames":{},"lazyUpdate":null},
"status":500,"statusText":"OK",
"url":"https://e5183ieny1.execute-api.us-east-1.amazonaws.com/alpha/api/rdsquery",
"ok":false,"name":"HttpErrorResponse",
"message":"Http failure response for https://e5183ieny1.execute-api.us-east-1.amazonaws.com/alpha/api/rdsquery: 500 OK",
"error":{"data":[],"err":"rdsquery() bott: localrdsquery() global: creds: sauser:sa-web-db.cpiiu9qemkvf.us-east-1.rds.amazonaws.com:SanAntonio error : query ERR:RequestError: Incorrect syntax near the keyword 'select'. querystring: select top(5000) * from select * from tomas_so_salesorderheader db=gk","version":"2.7.0"}}
    */
    console.log(errstr);

    // alert(
    //   "return value was invalid for: " +
    //     methodname +
    //     "\nCopy and paste the error from the console (press f12) to send to Paul. Heres the important info:\n" +
    //     error.message +
    //     "\n---\n" +
    //     JSON.stringify(error.error)
    //);
    return of(emptyobj as T);
  }
}
