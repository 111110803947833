import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { EditTransformComponent } from "./edit-transform/edit-transform.component";
import { HeaderComponent } from "./header/header.component";
import { EditTransformService } from "./edit-transform/edittransform.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HomeComponent } from "./home/home.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule } from "@angular/forms";
import { TokenInterceptor } from "./token.interceptor";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EditDialogComponent } from "./edit-transform/editdialog/editdialog.component";
import { StatusviewComponent } from "./statusview/statusview.component";
/* Add Amplify imports */
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";
import { AuthService } from "./auth/auth.service";
import { RoleGuardService } from "./auth/RoleGuardService";
import { SortPipe } from "./pipes";
import { CoSelectComponent } from "./CoSelectComponent/coSelect";

/* Configure Amplify resources */
Amplify.configure(awsmobile);

@NgModule({
  declarations: [
    AppComponent,
    EditTransformComponent,
    HeaderComponent,
    HomeComponent,
    EditDialogComponent,
    StatusviewComponent,
    CoSelectComponent, //used inside Status/EditTransform
    SortPipe,
  ],
  entryComponents: [EditDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    FontAwesomeModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    AmplifyUIAngularModule,
  ],
  providers: [
    {
      //PROD
      provide: "DSS_PROD_URL",
      useValue:
        "https://rs57b4r0l7.execute-api.us-east-1.amazonaws.com/alpha/api/",
    },
    {
      //WEBSITE 2
      provide: "DSS_TEST_URL",
      useValue:
        "https://en96r4aw09.execute-api.us-east-2.amazonaws.com/alpha/api/",
    },

    //IF NEED SEPERATE PDF URL??
    //{ provide: "PDF_URL", useValue: "https://sxhezc0x74.execute-api.us-east-1.amazonaws.com/beta"},

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, //appends token from local storage to request
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    EditTransformService,
    AuthService,
    RoleGuardService,
    EditDialogComponent,
    SortPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
