const pkgg = require("../../../package.json");
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject, Subscription } from "rxjs";
import { AppService } from "../app.service";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { LoggedInUser } from "@shared/apiinterfaces";
import { Component, OnInit, Inject } from "@angular/core";
import { Customer } from "@shared/customer";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public version: string = pkgg.version;
  public livetest: string = ""; //or test
  public _sub: Subscription;
  public _subUser: Subscription;
  private _subCustomers: Subscription;
  public name: string;
  isEmployee: boolean = false;
  customers: Customer[] = [];
  selectedCustomer: Customer = null;
  private _subSelectedCustomer: Subscription;

  get modeUpdated() {
    return this.livetest;
  }
  //set from dropdown select. calls svc to update across subscriibed components
  set modeUpdated(s: string) {
    console.log("uiupdated: " + s);
    this.appSvc.setLg(s as any);
  }

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private lsvc: AuthService,
    public appSvc: AppService,
    public router: Router
  ) {}

  ngOnDestroy() {
    this._sub.unsubscribe();
    this._subUser.unsubscribe();
    this._subCustomers.unsubscribe();
    this._subSelectedCustomer.unsubscribe();
  }

  ngOnInit() {
    let origin = this.document.location.origin;
    console.log(origin);
    this._sub = this.appSvc
      ._selectedEnvIsLiveObservable()
      .subscribe((y: boolean) => {
        this.livetest = y ? "live" : "test";
        console.log("header updated: " + this.livetest);
      });

    //if (origin.includes("localhost")) this.appSvc.setLg("test");
    //else this.appSvc.setLg("live");
    this.appSvc.setLg("live");

    this._subUser = this.lsvc
      ._subUserObservable()
      .subscribe(async (z: LoggedInUser) => {
        if (z) {
          //console.log("zz:" + JSON.stringify(z));

          this.name = (z.email || "@").split("@")[0];
          console.log(`NAME: ${this.name}`);
          this.isEmployee = z.isEmployee;
        }
      });

    //THIS IS PULLED FROM AUTH, WHEN USER TOKEN IS SET
    this._subSelectedCustomer = this.appSvc
      ._selectedCustomerObservable()
      .subscribe(async (z: Customer) => {
        if (z) {
          //console.log("zz:" + JSON.stringify(z));
          this.selectedCustomer = z;
        }
      });
  }
}
