<div class="col col-lg-12">
  <h5>Lambda IP: {{lambdaip}}</h5>
  <div *ngIf="!lambdaip.includes('WARN')">
    <div *ngIf="loading$.length>0">
      <mat-spinner></mat-spinner>
      <br>Loading: {{loading$}}
    </div>

    <div *ngIf="loading$.length<=0">

      <coselect></coselect>
      <hr />
      <p>Last saved on {{DateSaved}} by {{LastUser}}
      </p>
      <mat-checkbox [(ngModel)]="isModTr" [disabled]="!selectedCompany">Modify GLOBAL transform</mat-checkbox>
      &nbsp;
      <mat-checkbox [disabled]="!selectedCompany" #bigbuttons>Global run</mat-checkbox>
      &nbsp;
      <mat-checkbox [disabled]="!selectedCompany" #adddetail>Add File to Map</mat-checkbox>
      &nbsp;
      <mat-checkbox [disabled]="!selectedCompany" #copytr>Copy Transform to another Company</mat-checkbox>
      <br>
      <!--------------Mod emergency transform------------------>
      <div *ngIf="isModTr&&selectedCompany">
        <br>
        <mat-spinner *ngIf="this.strtransformfile.length <= 0"></mat-spinner>
        <textarea [(ngModel)]="this.strtransformfile" style="width:90%;" rows="7"></textarea>
        <br />the default value is: stored in bitbucket S3_files folder
        <br />
        <button mat-raised-button color="primary" (click)="updateTransform('overwrite')">Update transform
        </button>
        <br>
      </div>
      <!----------end mod emergency transform------>

      <!--------------copy/overwrite transform to another company------------------>
      <div *ngIf="copytr.checked">
        <br>
        <label>Select Customer to COMPLETELY OVERWRITE with the transform you picked above
          (used for new customer setup, or migrating a company from Test to Prod)</label>
        <p>
          <select [(ngModel)]="selectedCustomerCopyTo">
            <!--binds to obj not individual value-->
            <option *ngFor="let t of this.lgsvc.subUser.value.allowedCust| sort:'asc':'name'" [ngValue]="t">{{t.name}}-
              {{t.pockadvservername}}</option>
          </select>
          <!------set company-------->
        <div *ngIf="selectedCustomerCopyTo">
          <select [(ngModel)]="selectedCompanyCopyTo">
            <option *ngFor="let t of selectedCustomerCopyTo.companies| sort:'asc':'name'" [ngValue]="t">
              {{t.name}}-
              {{t.awscoid}}({{t.twodigitcoid}})</option>
          </select>
        </div>
        <br />
        <button mat-raised-button color="primary" (click)="copyCustTo()">Copy To This Cust
        </button>
        <br>
      </div>
      <!----------end mod emergency transform------>

      <!----------add file to transform object------>
      <div *ngIf="adddetail.checked">
        <br>
        <label>Enter the name (eg Customer, or Detail22):</label>
        <input type="text" #fname> &nbsp;
        <select #selectedfileUI>
          <option value="">Select File Type</option>
          <option *ngFor="let x of enumlistui" [ngValue]="x">
            {{x}}
          </option>
        </select>
        <br>
        <label>enter the input table sql query:</label>
        <input type="text" #isq>
        <br>
        <label>enter the output table sql query:</label>
        <input type="text" #osq>
        <br>
        <button mat-raised-button color="primary"
          (click)="addFile(fname.value,selectedfileUI.value,isq.value,osq.value)">Add File</button>
        <br>
      </div>
      <br>
      <!--------end add transform--------------->

      <!--------big buttons----->
      <div *ngIf="bigbuttons.checked">
        <hr>
        <button [disabled]="!this.selectedCustomer.CanGenerateFiles" mat-raised-button color="primary"
          style="margin-bottom: 5px;" (click)="generateICTD()">
          Generate All Item,Customer,Transact,Detail
        </button>
        <br>
        <button [disabled]="!this.selectedCustomer.CanGenerateFiles" mat-raised-button color="primary"
          style="margin-bottom: 5px;" (click)="generateICBlankTD()">
          Generate Item,Customer with BLANK Transact,Detail
        </button>
        <br>
        <br>dont forget to create order in app (or PockAdvSetup on the server) before writing orders
        <br>
        <button [disabled]="!this.selectedCustomer.CanGenerateFiles" mat-raised-button color="primary"
          (click)="writeOrdersToCust()">
          Write Orders To Customer
        </button>
        <br>
      </div>
      <!------end big buttons-->
      <div *ngIf="selectedCustomer && selectedCompany">
        <label>Select File to Edit (to add new file modify the global transform)</label>
        <mat-spinner *ngIf="!this.tFileKeys||this.tFileKeys.length <= 0"></mat-spinner>

        <p>
          <select [(ngModel)]="parseInvFile">
            <option value="">Select File for Mapping</option>
            <option *ngFor="let x of tFileKeys" [ngValue]="x">
              {{getLongFilename(x)}}
            </option>
          </select>
          <br />
          <br />
        </p>
        <button mat-raised-button color="primary" (click)="loadTransform()">
          Refresh transform
        </button>
      </div>

      <div
        *ngIf="loading$.length<=0 &&this._selectedInvFile&& this._selectedInvFile.name &&this._selectedInvFile.name.length > 0">
        <!-------------------------------TRANSFORM DATA---------------------------->

        <mat-checkbox #modtb>Modify table to get input/output columns from:</mat-checkbox>
        <div *ngIf="modtb.checked">
          <!-------------------------------------------------------------->
          <label>Transform Type</label>
          <select [(ngModel)]="fileType">
            <option value="">Select File Type</option>
            <option *ngFor="let x of enumlistui" [ngValue]="x">
              {{x}}
            </option>
          </select>
          <br>
          <!-------------------------------------------------------------->

          <span *ngIf="selectedCustomer.name=='Convoy'">Enter Name of PDF. syntax is THREE DIGIT
            CODE:FILENAME.pdf</span>
          <label *ngIf="selectedCustomer.name!='Convoy'">Modify input table</label>
          <br>
          <textarea [(ngModel)]="this.selectedInvFile.inputtablename" style="width:60%;"></textarea>
          <ul>
            <li *ngIf="this.selectedInvFile.inputtablename.startsWith('dbo.')"><b>Youve selected a Custom table from
                the
                dropdown
                above. This sql query
                should likely be: SELECT TOP (X) * FROM PockAdv.dbo.OrdersDotText where CompanyID='--two digit
                id--'</b>
            </li>
            <li>You can specify ANY number of rows inside the top(X). Then when you edit a transform and hit "Test
              Transform" you will see that many results seperated by "---". There is no need to reset it to top(1)
              anymore</li>
            <li>when using a join you must specify the columns exactly to avoid
              duplicates</li>
            <li>an example query: select top(5) * from
              FROMMAS_AR_Customer</li>
            <li>Dont include dbname in the query</li>
          </ul>
          <br />
          <!-------------------------------------------------------------->

          <label>Modify output table.
            <span>Where are you sending the data? (Inventiv File eg Transact,
              or if mapping output to sql it would be the name of the sql table eg
              dbo.SalesOrderHeader)</span></label>
          <br>
          <textarea [(ngModel)]="this.selectedInvFile.outputtablename" style="width:60%;"></textarea>
          <br />
          <ul>
            <li>Dont include dbname in the query</li>
          </ul>
          <br>
          <!----------------Save updated transform-------------------------->
          <button mat-raised-button color="primary" (click)="updateTransform('reload')">
            Update transform
          </button>
          <!--end save-->
        </div>
        <hr />

        <br />
        <h2>Add New Transform to {{this.selectedInvFile.name}} table below</h2>

        <label>Select output table field from {{parseTableName(this.selectedInvFile.outputtablename)}} to add to
          mapping</label>
        <select #outfieldval>
          <option *ngFor="let t of selectedInvFile.outputCol; index as i;" [value]="t.val">{{(i+1)}}) {{ t.val }} -
            {{ t.type }}</option>
        </select>
        <button mat-raised-button color="primary" (click)="outfield(outfieldval.value)">
          Add Transform
        </button>
        <br>

        <h2>Edit existing transforms</h2>
        <mat-checkbox #showinfo>Show transform diagram</mat-checkbox>
        <div *ngIf="showinfo.checked">
          <h2>The anatomy of a transform:</h2>
          <p>fil.name=custrow['CustomerName'].toString().toLowerCase()</p>

          <ol>
            <li>
              <b>fil.name</b> means output should be saved in the Customer.fil
              file matching the property 'name'
            </li>
            <li>
              <b>custrow</b> is a reference to the sql table row you want data
              from. This should not be changed
            </li>
            <li><b>['CustomerName']</b> is the property you want from sql</li>
            <li>
              the rest of the string is the transformation you want to make to the
              data. In this case converting it to a string and making it all
              lowercase
            </li>
          </ol>
          <br>
          <ul>
            <li>press the <fa-icon [icon]="['fas', 'skull']"></fa-icon> to delete
            </li>
            <li>press the <fa-icon [icon]="['fas', 'edit']"></fa-icon> to edit a transform
            </li>
            <li>If <span style="background-color: brown;">color</span> eg fil.XX means XX doesnt exist in output table
              (or output table has no rows... is your WHERE clause wrong?)
              data
            </li>
            <li>If <span style="background-color: pink;">color</span> then tablecol['WHATEVER'] is invalid because
              WHATEVER is not a column in input table
            </li>
            <li>If <span style="background-color: yellow;">color</span> then inventiv field (the fil.__) is missing or
              occurs more than once
            </li>
            <li>Numbers below correspond to the Inventiv field number in the middleware specs eg 3- fil.XX=... is the
              third field in the file spec
            </li>
          </ul>
        </div>
        <br>
        <div>Link to the wiki with setup notes: <a
            href="https://inventivsoftware.atlassian.net/wiki/spaces/WEB/pages/959152142/DSS+Notes"
            target="_blank">Click me</a>
        </div>
        <hr />
        <div *ngIf="loading$.length<=0 && (selectedInvFile.transforms && selectedInvFile.transforms.length>0)">
          <div class="example-box" *ngFor="let s of selectedInvFile.transforms; index as i" [ngStyle]="getRowStyle(s)">
            <span>{{ getFieldNumber(s) }} - {{ s.val }}</span>
            <span style="background-color:lightslategray;align-self: center;">{{s.note}}</span>
            <span>
              <fa-icon style="padding:30px;align-self: right;" [icon]="['fas', 'edit']" (click)="onEdit(i)">
              </fa-icon>
              <fa-icon style="padding:10px;" [icon]="['fas', 'skull']" (click)="onDelete(i)">
              </fa-icon>
            </span>
          </div>
        </div>
        <hr />
        <button mat-raised-button color="primary" (click)="this.updateTransform('')" style="margin-left: 5px;"
          [disabled]="(loading$.length>0) || !selectedInvFile|| !selectedInvFile.name">
          Save file
        </button>
        <hr>
        <button *ngIf="selectedCustomer.CanGenerateFiles" mat-raised-button color="primary"
          (click)="generateFile(true,fileType)"
          [disabled]="(loading$.length>0) || !selectedInvFile|| !selectedInvFile.outputtablename">
          Generate {{fileType}} file
        </button>
        <span *ngIf="!selectedCustomer.CanGenerateFiles">Not allowed to generate files yet because Paul isnt
          done</span>
        <hr>
        <span *ngIf="!selectedCustomer.CanGenerateFiles">
          <button *ngIf="fileType=='Transact'" mat-raised-button color="primary"
            (click)="this.generateFile(true,fileType)">
            Generate blank Transact
          </button>

          <button *ngIf="fileType=='Detail'" mat-raised-button color="primary"
            (click)="this.generateFile(true,fileType)">
            Generate blank Detail
          </button>
        </span>
        <hr />
        files will be retrieved from the S3 bucket every 5 minutes by the server
        <hr />
        <hr />
        <div>
          <h3>Trans results (generate file in S3 and show every 10th row (max of 50 displayed) of generated file here)
          </h3>
          <h5>If you see a bunch of empty lines in this document its likely because theres a detail file included that
            doesnt have any transforms. Ask Paul (or hit Modify Transform and edit the text) to remove the file from
            the
            transform.</h5>
          <div *ngIf="transResult">
            <ul>
              <div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <td *ngFor="let colname of transColList; let i = index">
                        {{ i + 1 }}
                      </td>
                    </tr>
                    <tr>
                      <td *ngFor="let colname of transColList">
                        {{ colname }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let s of transResult">
                      <td *ngFor="let idx of s">
                        {{ idx }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<br />