import {
  Transform,
  TransformParts,
  InvFileSpec,
  InvFileSpecCol,
} from "./shared.models";
import { SqlApiObject } from "./apiinterfaces";
import { clog } from "./clog";

//access properties eg Customer by iterating over keys. eg
//Object.keys(this.tFile).filter(k=>k!=='stringval').forEach(k=>this.tFile[k]) returns a transformParts object
export class TransformFile {
  //Customer: TransformParts. Left as example. this has been deprecated
  stringval: string;

  constructor(obj: string) {
    try {
      this.stringval = obj;
      //maps all obj in JSON to class properties: https://stackoverflow.com/questions/38127416/is-it-possible-to-destructure-instance-member-variables-in-a-javascript-construc
      if (obj != null) Object.assign(this, JSON.parse(obj));
      //clog(JSON.stringify(JSON.parse(obj)));
    } catch (err) {
      throw `cant parse transform. Err: ${err} obj was: ${obj}`;
    }
  }

  toString() {
    try {
      let v: any = [];
      let trf: TransformFile = this;

      Object.keys(trf)
        .filter((k) => k !== "stringval")
        .forEach((k) => {
          //k=Customer,Detail12< the names of the json objects
          let s: string = new TransformParts(k, this[k]).toString();
          //clog(k + ": \n" + s + "\n\n\n");
          v.push(s.replace(/\r\n/g, "")); //add the transform string, remove any stray newline characters inside the transform
        });
      let ret = "{" + v.join(",") + "}";
      //clog(ret)
      return ret;
    } catch (e) {
      clog(`err writing to string: ${e}`);
    }
    return null;
  }
}

export interface DialogSend {
  type: "PDF" | "SQL"; //changes how data is displayed
  inputmappingname: string;
  outputmappingname: string;
  trans: Transform; //the data being sent into the dialog

  objArray: object[]; //to use when doing transform: [{"ItemCode":"DMS1926","ItemType":"1"...},{...}]
  colArray: InvFileSpecCol[]; //sql column name and datatypes for dropdown
}

export interface DialogResponse {
  filcol: string;
  trans: string;
  notes: string;
}

export class TransformPartsUI extends TransformParts {
  public inputObjArray: object[]; //ALWAYS going to be SQL object
  public inputCol: InvFileSpecCol[] = []; //sql column name and datatype

  //either return of sql data rows([{"ItemCode":"DMS1926","ItemType":"1"...},{...}])
  public outputObjArray: object[];
  public outputCol: InvFileSpecCol[] = []; //sql column name and datatype

  constructor(
    tr: TransformParts,
    inputdata: SqlApiObject,
    inputcoltypes: InvFileSpec,
    outputdata: SqlApiObject,
    outputcoltypes: InvFileSpec
  ) {
    super(tr.name, tr);

    try {
      //dont init any of the ui elements. this will let you fix a transform tho if the sql tables are wrong
      if (
        inputdata == null &&
        inputcoltypes == null &&
        outputdata == null &&
        outputcoltypes == null
      ) {
        clog("Compatabilty mode engaged");
        return;
      }

      //--------------------------------------------------------
      try {
        clog("ui.models parse input");
        //since the input data may be a join this collist can be pulled from sql data
        this.inputCol = this.getColList(inputdata, inputcoltypes).collist;
        //this must be provided
        this.inputObjArray = inputdata.data;
        clog("input parsed");
      } catch (e) {
        throw "incol: " + e;
      }
      //----------------------------------------------------------
      try {
        clog("ui.models parse output");
        //the output should be one table (not a join) so this should work, if not throw err
        this.outputCol = outputcoltypes.collist;
        this.outputObjArray = outputdata.data;
        clog("output parsed");
      } catch (e) {
        throw "ocol: " + e;
      }
    } catch (e) {
      let x = `TransformPartsUI constructor err: ${e}`;
      clog(x);
      throw x;
    }
  }

  getColList(data: SqlApiObject, coltypes: InvFileSpec): InvFileSpec {
    let ret: InvFileSpec = new InvFileSpec();
    try {
      let hasData = data != null && data.data != null && data.data.length > 0;
      let hasColData =
        coltypes != null &&
        coltypes.collist != null &&
        coltypes.collist.length > 0;
      clog("ui.model data: " + hasData + " colnames: " + hasColData);

      if (hasColData) return coltypes;

      if (hasData) {
        ret.collist = Object.keys(data.data[0]).map((x) => {
          return { val: x, type: "" } as InvFileSpecCol;
        });
      } else
        throw (
          "invalid coldata returned: " +
          JSON.stringify(data) +
          " col types were: " +
          JSON.stringify(coltypes)
        );
    } catch (e) {
      throw "getColList() " + e;
    }
    return ret;
  }

  //checkDuplicateCols(inputdata.data[0])
  checkDuplicateCols(row: object) {
    let colnames = Object.keys(row).sort();
    if (colnames.length !== new Set(colnames).size) {
      let dupes: string[] = [];
      for (var i = 0; i < colnames.length - 1; i++) {
        if (colnames[i + 1] == colnames[i]) {
          dupes.push(colnames[i]);
        }
      }
      return (
        "You have duplicate column names. This will cause an error when evaluating functions. Check modify table box and remove duplicate columns (they may be hidden by a *). Duplicates: " +
        dupes
      );
    }
    return "";
  }
}
