import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import Auth from "@aws-amplify/auth";
import { AuthService } from "./auth/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Datalink";
  constructor(private lsvc: AuthService, public router: Router) {}
}
