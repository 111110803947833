import {
  InvFileSpec,
  TransformParts,
  InvFileTypes,
  TransformV2SqlRecord,
} from "./shared.models";
import {
  parseEvent,
  getTableCols,
  localrdsquery,
  parseAuthContext,
  getCustomerFromDynamo,
} from "./_helperfunc";
import { parseTableName } from "./helper";
import { Customer, CustDbNames, CustomerName, Company } from "./customer";
import { clog } from "./clog";
import { PocketOrders, PocketOrderSummary, rndcOrder } from "./apiinterfaces";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const uuidv4 = require("uuid/v4");

export class CustomerApiEvents {
  orderReturnKey: string = ""; //BHSoNum/ BHRoNum
  requestType: "header" | "line" | "close";
  logs: DataSentLog[] = [];
  status: number;
}
export class ConExt {
  authContext: AuthContextLambda | AuthContextWebsite;
  cust: Customer = new Customer();
  admincust: Customer = new Customer();
  fileGenerationUuid: string; //generated when you call generateFile() intended to track a single run of generate file method
  body: object; //EVENT object body
  company: Company | null = null;

  //takes in a token payload and a method body through an event.
  //determines the users customer & company
  /*
    let fakeCon: ConExt = await new ConExt({
      custname: cust.name,
      awscoid: company.awscoid,
    }).init();
  */
  constructor(xxxNOUSE: any) {
    try {
      this.fileGenerationUuid = uuidv4();
      clog("parsing body");
      this.body = parseEvent(xxxNOUSE);
      try {
        clog("setting auth using body: ", this.body);
        //THIS PARSES THE PAYLOAD FROM AUTHORIZER (TOKEN PERMISSIONS)
        this.authContext = parseAuthContext(xxxNOUSE);
        if (!this.body["custname"] || this.body["custname"].length <= 0) {
          console.log(`conext err22: `, this.body);
          throw `invalid custname in conext`;
        }
      } catch (e) {
        clog("ERR PARSING CONTEXT:", JSON.stringify(e));
        throw "ERR PARSING CONTEXT:" + JSON.stringify(e);
      }
      //check that cust token can run queries as this permission
      let canRunQueriesAsCust =
        this.authContext.isAdmin() ||
        this.authContext.customerNames.split(",").includes(this.cust.name);

      //TODO validate that user has access to company
      if (!canRunQueriesAsCust)
        throw `user with customer permission: ${this.authContext.customerNames} cannot run queries for: ${this.body["custname"]}`;

      if (!this.cust) throw "invalid cust";
      clog(`Conext using awscoid: ${this.body["awscoid"]}`);

      //this is a hack because I used to pass in awscoid="00" when pulling data to ensure I had a valid company
      //but it is not needed, and setting to null for backward compatability with validation below
      if (this.body["awscoid"] && this.body["awscoid"].toString() == "00")
        this.body["awscoid"] = null;

      //if you pass an awscoid, make sure its valid for the customer
      if (
        this.body["awscoid"] &&
        this.body["awscoid"].length > 0 &&
        this.cust.name !== CustomerName.Admin &&
        !this.company
      )
        throw `invalid company passed for customer: ${this.cust.name}`;
    } catch (e) {
      clog("Conext error event: " + e);
      throw "Conext error event: " + e;
    }
  }

  async init() {
    this.cust = await getCustomerFromDynamo(this.body["custname"]); //SET CUST TO EG FB
    this.admincust = await getCustomerFromDynamo(CustomerName.Admin);
    this.company = this.cust.companies.filter(
      (c) => c.awscoid == this.body["awscoid"]
    )[0];
    //if no company passed throw a warning
    if (!this.company)
      clog(
        `WARNING: invalid company found when filtering ${this.cust.name} on awscoid: ${this.body["awscoid"]}`
      );
    return this;
  }

  async getTransform(): Promise<TransformParts[]> {
    //PULL FROM S3
    //let jj = await S3.getObject({ Bucket: this.cust.bucket, Key: this.cust.key }).promise();
    //return JSON.parse(jj.Body);

    if (!this.company) throw `invalid company in getTransform()`;
    if (!this.cust) throw `invalid cust in getTransform()`;
    //PULL FROM SQL- must use admin creds
    let aa = `select top(1) * from ICTD..Transform_v2 where UPPER(CustomerName)=UPPER('${this.cust.name}') and AwsCoId=${this.company.awscoid} order by DateSaved desc`;
    //console.log(`transform puled with query:`, aa);

    let qres = await localrdsquery(this.admincust, "gk", aa);
    // console.log(
    //   `qqres (${this.cust.name}, ${this.company.awscoid}) creds: ${creds.name}:`,
    //   qres
    // );
    if (!qres || !qres.result || (qres.result && qres.result.length <= 0)) {
      console.log(`query w err:`, aa);
      throw `no transform for ${this.cust.name} co: ${this.company.awscoid}`;
    }
    let transRecord: TransformV2SqlRecord = qres
      .result[0] as TransformV2SqlRecord;
    transRecord.strTransforms = qres.result[0]["Transform"];
    transRecord.objTransforms = JSON.parse(
      qres.result[0]["Transform"]
    ) as TransformParts[];

    //for each transform...trname=Customer,Detail etc
    Object.keys(transRecord.objTransforms).map((trname) => {
      //console.log(`oo key: `, trname, transRecord.objTransforms[trname]);
      //add a derived param allrow table based on
      transRecord.objTransforms[trname].allrowtable = transRecord.objTransforms[
        trname
      ].inputtablename
        .replace(/[top|TOP|Top]+\s*\([0-9]+\)/, "")
        .replace(/\$*[top|TOP|Top]+=[0-9]+/, "");
      (transRecord.objTransforms[trname] as TransformParts).allrowoutputtable =
        (transRecord.objTransforms[trname] as TransformParts).outputtablename
          .replace(/[top|TOP|Top]+\s*\([0-9]+\)/, "")
          .replace(/\$*[top|TOP|Top]+=[0-9]+/, "");

      //when reading in {Cust:{x:y}} the "Cust" is dropped when iterating through the children,
      //so must be added back as name property (for reference, not used for anything)
      //set the name property of each transform eg Customer,Detail10 etc
      transRecord.objTransforms[trname].name = trname; //tv2['Customer'].name='Customer'
    });

    //turn transRecord.objTransforms which is a SINGLE object with keys for each transform
    //into an array of transforms
    return Object.keys(transRecord.objTransforms).map(
      (k) => transRecord.objTransforms[k]
    );
  }

  //returns: transobj: transform as object, colorder: the order of the inventiv cols for the filename, and transToRun: array of transformParts to run to generate text
  //note that i slice off the top(1) restrictions and sql will return the whole datsaet
  GetFileParts = async (type: InvFileTypes): Promise<GenerateFileParts> => {
    try {
      //getTransform from SQL
      let trans: TransformParts[] = await this.getTransform();

      if (!trans || Object.keys(trans).length < 1) {
        clog(trans);
        throw "invalid transform, or other json parsing err when parsed from SQL";
      }
      //must loop through multiple Detail files that may or may not exist.
      //Get list of instantiated files in transform and use those
      //assume the transforms to run all share the SAME outputtable eg FileDetail10
      console.log(`TRANS::`, Object.keys(trans));
      let transToRun: TransformParts[] = trans.filter(
        (x) => x.filetype == type
      );
      if (!transToRun || transToRun.length <= 0)
        throw "no transforms with filetype: " + type;

      //check that every table (eg Detail1..Detail86 specified in transform) has the same sql/sage requirement
      transToRun.forEach((tt) => {
        if (!tt.inputtablename.toLowerCase().startsWith("select"))
          throw `${tt.inputtablename} doesnt follow the pattern. expected sql`;
        if (!tt.outputtablename.toLowerCase().startsWith("select"))
          throw `${tt.outputtablename} doesnt follow the pattern. expected sql`;
      });

      let otn: string = parseTableName(transToRun[0].outputtablename);
      clog(
        "parsing tablename to get output order (pre):",
        transToRun[0].outputtablename,
        ` (parsed): `,
        otn
      );
      let colorder: InvFileSpec = await getTableCols(otn, this.cust);
      if (!colorder) throw "invalid InvFileCols on tablename: " + otn;
      if (!colorder.collist) throw "invalid collist object inside the colorder";

      return { transobj: trans, colorder, transToRun };
    } catch (e) {
      throw "GetFileParts():" + e;
    }
  };
}
//----------------------------------------------------------------------------------
//FOR USE ONLY IN MAP ORDERS>>>>>>> THIS MAY CHANGE
export interface OrdersDotText {
  LineGuid: string; //uniqueidentifier
  OrdersDotTextID: string;
  OrderSummaryID: string;
  CompanyID: string;
  SalesRepID: string;
  CustomerID: string;
  Comment: string;
  SignatureDate: string;
  NameOnSignature: string;
  ExpectedEntries: string;
  DateSent: string;
  PullAttempts: string;
  Instructions: string;
  IPAddress: string;
  Comment2: string;
  PONumber: string;
  DeliveryInstructions: string;
  InvoiceBreak: string;
  ItemID: string;
  OrderType: string;
  DateCreated: string;
  BackOrder: string;
  OutOfStock: string;
  ClassificationString: string;
  ClassificationOther: string;
  DeliveryDate: string;
  CasesConfirmed: string;
  UnitsConfirmed: string;
  Voided: string;
  VoidedConfirmed: string;
  Cases: string;
  Units: string;
  PriceOverride: string;
  DiscountOverride: string;
  DiscountPercent: string;
  DealCode: string;
  RetailPrice: string;
  DisplayCases: string;
  InstoreCases: string;
  InstoreUnits: string;
  SentToHost: string;
  SentToHostAt: string;
  CasePrice: string;
  UnitPrice: string;
  Vintage: string;
  PricingChoice: string;
  BillAndHold: string;
  ItemComment: string;
  LumpSumDiscount: string;
  OrderReason: string;
  Comment3: string;
  Comment4: string;
  DealCode2: string;
  Optional1: string;
  Optional2: string;
  Optional3: string;
  Optional4: string;
  Optional5: string;
  Optional6: string;
  Optional7: string;
  Optional8: string;
  Optional9: string;
  Optional10: string;
  Optional11: string;
  Optional12: string;
  Optional13: string;
  Optional14: string;
  Optional15: string;
  Optional16: string;
  Optional17: string;
  Optional18: string;
  Optional19: string;
  Optional20: string;
  NetCasePrice: string;
  NetUnitPrice: string;
  NoSplitCharge: string;
  Future75: string;
  Future76: string;
  Future77: string;
  Future78: string;
  Future79: string;
  Future80: string;
  DA1: string;
  DA2: string;
  DA3: string;
  DA4: string;
  DA5: string;
  err?: ApiResult; //populated if line fails to send
  grouping?: string; //used to split orders by company rules eg FB
}

//[PockAdv].[dbo].[PDPayments] on ecloud16< this IS A VIEW
export interface PDPaymentsView {
  DelivDate: string;
  AdvCloudID: string;
  Route: string;
  Stop: string;
  CustomerID: string;
  PaymentType: string;
  AmountCollected: string;
  AmountCollectedConfirmed: string;
  CheckNumber: string;
  CheckNumberConfirmed: string;
  PaymentConfirmed: string;
  CheckInName: string;
  InvoiceDate: string;
  RouteDateTime: string;
  DateSent: string;
  ConnectionDate: string;
  DateID: string;
  ClientHardwareID: string;
  Company2ID: string;
  SenttoHostat: string;
  SenttoHost: string;
  DateReceived: string;
  PaymentGroup: string;
  IPAddress: string;
  CompanyID: string;
  SalesRepID: string;
}

export interface vwPocketOrders {
  CompanyID: string;
  SalesRepID: string;
  CustomerID: string;
  ItemID: string;
  OrderType: string;
  DateCreated: string;
  BackOrder: string;
  OutofStock: string;
  ClassificationString: string;
  ClassificationOther: string;
  DateSent: string;
  DeliveryInstructions: string;
  DeliveryDate: string;
  CasesConfirmed: string;
  UnitsConfirmed: string;
  Voided: string;
  VoidedConfirmed: string;
  Cases: string;
  Units: string;
  PriceOverride: string;
  DiscountOverride: string;
  DiscountPercent: string;
  DealCode: string;
  RetailPrice: string;
  DisplayCases: string;
  InstoreCases: string;
  InstoreUnits: string;
  SenttoHost: string;
  SentToHostAt: string;
  OrderSummaryID: string;
  CasePrice: string;
  UnitPrice: string;
  InvoiceBreak: string;
  Vintage: string;
  PricingChoice: string;
  BillAndHold: string;
  Comment: string;
  LumpSumDiscount: string;
  OrderReason: string;
  Rejected: string;
  Approval: string;
  InStoreCasesDetail: string;
  InStoreUnitsDetail: string;
  ConnectionDate: string;
  OrderSumDateID: string;
  DealCode2: string;
  Config1: string;
  Config2: string;
  Config3: string;
  Config4: string;
  Config5: string;
  NoSplitCharge: string;
  Config6: string;
  Config7: string;
  Config8: string;
  Config9: string;
  Config10: string;
  NetCasePrice: string;
  NetUnitPrice: string;
  Config11: string;
  Config12: string;
  Config13: string;
  Config14: string;
  Config15: string;
  Config16: string;
  Config17: string;
  Config18: string;
  Config19: string;
  Config20: string;
  OrderID: string;
  DistanceFromCreated: string;
  OrderGuid: string;
  SentToAWS: string;
  SentToAwsType: string;
  SentToAWSAt: string;
  OrderSource: string;
  Disposition: string;
  RecordGUID: string;
  BillAddress1: string;
  BillAddress2: string;
  BillCity: string;
  BillCountryID: string;
  BillState: string;
  BillZip: string;
  ChainName: string;
  CompanyName: string;
  CreditTermsName: string;
  CustCategoryName: string;
  CustTypeName: string;
  Phone: string;
  ShipAddress1: string;
  ShipAddress2: string;
  ShipCity: string;
  ShipCountryID: string;
  ShipCountyID: string;
  StatusName: string;
  TradeName: string;
  Wholesaler: string;
  PONumber: string;
  ShipZip: string;
  ShipState: string;
  CoID: string;
  LineGuid: string; //NOT CREATED YET @TODO
  err?: ApiResult;
}

//[PockAdv].[dbo].[PDF_Urls] on ecloud16<< populated by paul program
export interface PdfUrl {
  url: string;
  filepath: string;
  invoicenum: string;
  dateUploaded: string;
  SentToHost: string;
  GUID: string;
}

//[PockAdv].[dbo].[vwFB45_Return] on ecloud16
export interface PocketDeliveryReturnsView {
  LineGuid: string; //uniqueidentifier
  TrUnitsPerCase: string;
  BHLotId: string;
  ReturnInvoiceNumber: string;
  ReturnsCompleted: string;
  CompanyID: string;
  SalesRepID: string;
  OrderSumID: string;
  OrderID: string;
  CustomerID: string;
  CustomerName: string;
  ItemID: string;
  CasesReturned: string;
  UnitsReturned: string;
  ExtPriceReturned: string;
  ReturnReasonCode: string;
  DateCreated: string;
  DateSent: string;
  ConnectionDate: string;
  SentToHost: string;
  SentToHostAt: string;
  InvoiceBreak: string;
  OrderType: string;
  OrderSumDateID: string;
  DelivGUID: string;
  CheckInName: string;
  Route: string;
  DeliveryDate: string;
  Restockable: boolean; //THIS IS A BOOL on INventiv side
  AdjustmentType: string;
  ClientRecordID: string;
  AdvCloudID: string;
  SalesUnitSymbol: string;
  CategoryID: string;
  //LNFOriginalSalesOrderNumber: string;-- no longer used per 7/22 email
  RMASalesId_PDTrOpt03: string;
  DealCode: string;
  UnitsRestockableNeg: string;
  err?: ApiResult;
}

//gk server Logs..FavoriteBrands_DataSentLogs
export interface DataSentLog {
  InvLineGuid: string | null;
  InvOrderSumId: string | null;
  BHsoRoId: string | null;
  InvOrderType: string | null;
  Notes: string;
  status: number;
  ItemId: string | null;
  CustomerId: string | null;
  SalesRepId: string | null;
  obj: object | null;
  RetriesAttempted?: number;
}
export type DataSentLogSql = DataSentLog & {
  DateSentToBH: string;
  ObjectSent: object | null;
  TwoDigitCoId: string;
  AwsCoId: number;
  DLCustomerName: string;
};

//PockAdv..vwFb45_SP_v2.... now same as return with diff filters
//where ordertype=g9 (SP)
//ordertype=N9 and ReturnReasonCode=CNF is Adjustment to Scheduled Pickup
export interface SchedPickupKegReturnsView extends PocketDeliveryReturnsView {
  RMASalesId_PDTrOpt03: string; //should always be blank. THIS IS BEING RETRIEVED FROM FB
}

//for rndc incoming edi orders
export interface IncomingOrderTransformResult {
  inputRow: rndcOrder;
  transformedRow: PocketOrders | PocketOrderSummary;
}

//for map orders
export interface OrderTransformResult {
  inputRow: OrdersDotText | vwPocketOrders;
  transformedRow: object;
}

//used for lipman write Detail to SQL
export interface NightlyTransformResult {
  inputRow: object;
  transformedRow: object;
  sqlTableWriteTo: string;
}

export interface ReceiptTransformResult {
  inputRow: PdfUrl;
  transformedRow: object;
}
export interface PaymentTransformResult {
  inputRow: PDPaymentsView;
  transformedRow: object;
}

export interface SignedReceiptResult {
  inputRow: PdfUrl;
  transformedRow: object;
}

//for map orders- group on SalesOrderNumber/LNFInventivId
export interface GroupedOrderTransformResult {
  header: OrderTransformResult;
  lines: OrderTransformResult[];
  orderType: OrderType;
}

export interface GroupedSqlTransformResult {
  sqlKeyColumns: string[];
  lines: OrderTransformResult[];
  orderType: OrderType;
  sqlTableToWriteTo: string;
}

//stores the object sent to Axios, and the customers reply
export interface CustomerApiResult {
  ev: ConExt;
  transformedLine: OrderTransformResult;
  sentToAxios: object;
  axiosResult: ApiResult | null;
  axiosStatus: number;
  wasResent: boolean;
}

export enum OrderType {
  //SalesOrder
  Regular = "Regular",
  Sample = "Sample",
  HotshotRepPickup = "HotshotRepPickup",
  HotshotCustPickup = "HotshotCustPickup",

  //PickupRequest,
  //RETURNS
  Return = "Return",
  Reship = "Reship",
  ScheduledPickup = "ScheduledPickup",
  KegReturn = "KegReturn",
  PickupRequest = "PickupRequest",
  Coop = "Coop",
  BillAndHold = "BillAndHold",
  BillAndHoldReturn = "BillAndHoldReturn",
  NightlyFileGKToPAServer = "NightlyFileGKToPAServer",
}

//for map returns- group on OrderSumId
export interface GroupedReturnTransformResult {
  header: ReturnTransformResult;
  lines: ReturnTransformResult[];
  orderType: OrderType;
}

//for map returns
export interface ReturnTransformResult {
  inputRow: PocketDeliveryReturnsView;
  transformedRow: object;
}
//for generateFile
export interface ICTDTransformResult {
  inputRow: object;
  transformedRow: object;
}

export interface GenerateFileParts {
  transobj: Object; //original transform file
  colorder: InvFileSpec;
  transToRun: TransformParts[]; //eg [Item], or [Detail1,Detail2,etc]
}

export class AuthContextLambda {
  customerNames: string = ""; //comma seperated string
  awscoid: number | null = null;
  email: null;
  public isAdmin() {
    return true;
  }
  public constructor(init?: Partial<AuthContextLambda>) {
    Object.assign(this, init);
  }
}
export class AuthContextWebsite {
  customerNames: string = ""; //comma seperated string
  email: string = "";
  awscoid: number | null = null;
  public isAdmin() {
    return this.email.endsWith("@inventiv.com");
  }
  public constructor(init?: Partial<AuthContextWebsite>) {
    Object.assign(this, init);
  }
}

export interface ApiResult {
  query: string;
  //result: Object[]; //object[] if select.... string if insert, but retturned as object[] for simplicity
  axRequest: AxiosRequestConfig;
  axResponse: AxiosResponse;
  status: number; //200 or 500 typically
  errmsg: string | AxiosError;
  retriesAttempted: number;
  data: object | object[];
}

export interface SqlResult {
  query: string;
  result: object[];
  err_msg: string;
  isSuccess: boolean;
  rowsAffected: number;
}

export interface Token {
  type: "Bearer" | "Basic" | "x-api-key" | "Oauth";
  accesstoken: string;
  status: number;
  err: string;
}

export interface SqlVarType {
  cname: string; //"SalesOrderNo"
  max_length: string; //"7"
  name: string; //"varchar"
}

export interface TransformAsStringObj {
  transformedStrArr: string[];
  err: string | null;
}

export class SqlCreds {
  customerName: CustomerName;
  user: string;
  database: CustDbNames; //'webreader',
  password: string; //'Goat@Chicken2',
  gkinv: "gk" | "inventiv";
  server: string; //url or ip
  port: number;
  pool: {
    connectionTimeout: number; //MS default 15000
    requestTimeout: number; //MS default 15000
  };

  //INVENTIV SERVERS
  static CredsList: SqlCreds[] = [
    //----------------------------------------------------------
    //INVENTIV AWS 54235
    //----------------------------------------------------------
    {
      customerName: CustomerName.InventivAws,
      user: "pweiler",
      password: "n1ghtFl00r#",
      database: CustDbNames.Inventiv,
      server: "54.235.177.206",
      gkinv: "inventiv",
      port: 1433,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    //used for CsvToSql for Provi FROM THEIR SERVER
    {
      customerName: CustomerName.WinebowWest, //HenryWines
      user: "sa",
      password: "!H3nryW1ne$W1neB0w#",
      database: CustDbNames.PockAdv,
      server: "localhost",
      gkinv: "inventiv",
      port: 1433,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    {
      customerName: CustomerName.WinebowEast,
      user: "sa",
      password: "Memphis99!",
      database: CustDbNames.PockAdv,
      server: "localhost",
      gkinv: "inventiv",
      port: 1433,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    //used for CsvToSql for Provi FROM THEIR SERVER
    {
      customerName: CustomerName.WinebowWestTest,
      user: "sa",
      password: "!H3nryW1ne$W1neB0w#",
      database: CustDbNames.PockAdv,
      server: "localhost",
      gkinv: "inventiv",
      port: 1433,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    //inventiv
    {
      customerName: CustomerName.RNDCWestTest,
      user: "sa",
      password: "Inventiv123",
      database: CustDbNames.PockAdv,
      server: "199.48.172.157",
      gkinv: "inventiv",
      port: 50309,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    {
      customerName: CustomerName.RNDCWest,
      user: "sa",
      password: "Inventiv123",
      database: CustDbNames.PockAdv,
      server: "199.48.172.156",
      gkinv: "inventiv",
      port: 56671,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    {
      customerName: CustomerName.LipmanTest,
      user: "sa",
      password: "Inventiv123",
      database: CustDbNames.PockAdv,
      server: "50.234.204.141",
      gkinv: "inventiv",
      port: 1433,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
    {
      customerName: CustomerName.Bacchus,
      user: "sa",
      password: "memphis99?",
      database: CustDbNames.PockAdv,
      server: "bacchussfaprd.breakthrubev.com", //"65.216.133.10",
      gkinv: "inventiv",
      port: 1433,
      pool: {
        connectionTimeout: 15000,
        requestTimeout: 15000,
      },
    },
  ];
}
