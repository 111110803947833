export enum CustDbNames {
  SanAntonio = "SanAntonio",
  Murphy = "Murphy",
  Calyx = "Calyx",
  FavoriteBrands = "FavoriteBrands",
  Convoy = "Convoy",
  WinebowEast = "WinebowEast",
  WinebowWest = "WinebowWest",
  Logs = "Logs",
  EDIOrders = "EDIOrders",
  Opici = "Opici",
  //FTP CSV> into GK sql> DL map to PAU> read by ecomm
  //OpiciEcomm="OpiciEcomm", //using harrisons pau parser instead of DL
  PockAdv = "PockAdv",
  FavoriteBrandsTest = "FavoriteBrandsTest",
  SanAntonioTest = "SanAntonioTest",
  MurphyTest = "MurphyTest",
  Epic = "Epic",
  eComm = "eComm",
  FredrickWildman = "FredrickWildman",
  FredrickWildmanTest = "FredrickWildmanTest",
  MartinesWine = "MartinesWine",
  Inventiv = "Inventiv",
  ConvoyTest = "ConvoyTest",
  ChambersAndChambers = "ChambersAndChambers",
  ChambersAndChambersTest = "ChambersAndChambersTest",
  CrownTest = "CrownTest",
  WilsonDanielsTest = "WilsonDanielsTest",
  WilsonDaniels = "WilsonDaniels",
  UsaWines = "UsaWines",
  RNDCWest = "RNDCWest",
  RNDCWestTest = "RNDCWestTest",
  LipmanTest = "LipmanTest",
  OpiciEcomm = "OpiciEcomm",
  BacchusTest = "BacchusTest",
  Bacchus = "Bacchus",
  Skurnik = "Skurnik",
}

export enum CustomerName {
  //EDI
  WineWarehouseEdi = "WineWarehouseEdi",
  MajorBrandsEdi = "MajorBrandsEdi",
  LipmanEdi = "LipmanEdi",
  MSWalkerEdi = "MSWalkerEdi",
  LipmanTestEdi = "LipmanTestEdi",
  MSWalkerTestEdi = "MSWalkerTestEdi",
  MajorBrandsTestEdi = "MajorBrandsTestEdi",
  SkurnikTestEdi = "SkurnikTestEdi",
  WinebowWestEdi = "WinebowWestEdi",

  //DataLink
  SanAntonio = "SanAntonio",
  SanAntonioTest = "SanAntonioTest",
  Murphy = "Murphy",
  MurphyTest = "MurphyTest",
  Calyx = "Calyx",
  FavoriteBrands = "FavoriteBrands",
  Convoy = "Convoy",
  Opici = "Opici",
  Admin = "Admin",
  Ecloud15 = "Ecloud15",
  SkurnikEdi = "SkurnikEdi",
  FavoriteBrandsTest = "FavoriteBrandsTest",
  Epic = "Epic",
  PaulLocalDockerFavoriteBrandsTestCustomer = "PaulLocalDockerFavoriteBrandsTestCustomer",
  FredrickWildman = "FredrickWildman",
  FredrickWildmanTest = "FredrickWildmanTest",
  MartinesWine = "MartinesWine",
  InventivAws = "InventivAws",
  ChambersAndChambers = "ChambersAndChambers",
  ChambersAndChambersTest = "ChambersAndChambersTest",
  OpiciTest = "OpiciTest",
  Crown = "Crown",
  CrownTest = "CrownTest",
  WilsonDanielsTest = "WilsonDanielsTest",
  WilsonDaniels = "WilsonDaniels",
  UsaWineImports = "UsaWineImports",
  RNDCWest = "RNDCWest",
  RNDCWestTest = "RNDCWestTest",
  WinebowWest = "WinebowWest",
  WinebowEast = "WinebowEast",
  WinebowWestTestEdi = "WinebowWestTestEdi",
  LipmanTest = "LipmanTest",
  OpiciEcomm = "OpiciEcomm",
  WinebowWestTest = "WinebowWestTest",
  BacchusTest = "BacchusTest",
  EmpireDistTestEdi = "EmpireDistTestEdi",
  Bacchus = "Bacchus",
  Lipman = "Lipman",
  EmpireDistEdi = "EmpireDistEdi",
  Skurnik = "Skurnik",
  AthensTestEdi = "AthensTestEdi",
}

//http://..../apixx/salesorder
//[   BASE   ][rsc][endpoint]
export interface UrlParam {
  rsc?: string;
  endpoint?: string; //if not passed this is assumed to be the name
}

export interface OdataEndpoint {
  name: string;
  urlparam: UrlParam; //for fb. after reosurce name eg Items/data/... then append params
  queryparam: object; // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
  indexSQLCol: string | null;
  isPost: boolean; // used to confirm Odata URL and eventually hide on website
  bodyParams?: {
    //USED BY SANANTONIO
    publicname: string;
    key: string;
    filterSqlList?: string;
    environment: string;
  };
}
export interface PkgApiEndpoint {
  name: string;
  param?: string; // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
  legalEntity: string;
  fileExtension: ".csv" | ".xlsx";
  savedAs: string; //if the sql table name is different from the file
  indexSQLCol: string | null;
}

export interface CustomEndpoint {
  name: string;
  body: string;
  headers: object;
  url: string;
  bodyParams: {
    publicname: string;
    key: string;
    filterSqlList?: string;
    environment: string;
  };
}

export class OdataCreds {
  //tenant:string //="87cb5c86-0d85-4784-b5ab-b0bf8f994202";
  tokenPostAddress: string; // = "https://login.microsoftonline.com/" + tenant + "/oauth2/token";
  grant_type: "client_credentials" | "basic";
  client_id: string; // "9f7935c6-ebb2-41b9-8611-xxxxx"
  resource: string | null; //"https://xxxxx.sandbox.ax.dynamics.com"
  client_secret: string; //"xxxxxx"
  endpoints: OdataEndpoint[];
  pkgApiEndpoints: PkgApiEndpoint[];
}

export enum PockAdvServerName {
  Ecloud16 = "ecloud16",
  Ecloud1 = "ecloud1",
  Null = "null",
  Ecloud2 = "ecloud2",
  WinebowTest = "WinebowTest",
  Ecloud15 = "Ecloud15",
  Ecloud18 = "Ecloud18",
  Ecloud5 = "Ecloud5",
  Ecloud17 = "Ecloud17",
  EcommTest = "EcommTest",
  Localhost = "Localhost",
  Aws54234 = "Aws54234",
  Ecloud7 = "Ecloud7",
  Ecloud19 = "Ecloud19",
  Ecomm1 = "Ecomm1",
  Ecloud12 = "Ecloud12",
  Acloud20 = "Acloud20",
  Acloud21 = "Acloud21",
  RndcWest = "RndcWest",
  RndcWestTest = "RndcWestTest",
  LipmanTest = "LipmanTest",
  Bacchus = "Bacchus",
}

//the subtrDays is used by Customer FB when pullin data from transact in customer.ts
//DEPRECATED WHEN MOVED TO DYNAMO
export var getSqlDateCustom = (subtrDays: number): string => {
  let d = new Date(new Date().setDate(new Date().getDate() + subtrDays));
  return d.toISOString().slice(0, 19).split("T")[0] + "T12:00:00Z"; //2020-06-30 13:45:42
};

export interface Company {
  name: string;
  awscoid: number;
  twodigitcoid: string;
  customerCoid?: string; //if they need specific data in the endpoint URL eg wilson daniels/Murphy
}

export enum EdiCustomer {
  Provi = "Provi",
  SevenFifty = "SevenFifty",
  TotalWine = "TotalWine",
  Craftable = "Craftable",
  //Salesforce = "Salesforce", //for winebow
  Winebow = "Winebow",
}

export class Customer {
  name: CustomerName; //"SanAntonio"
  dbname: CustDbNames; //"SanAntonio"
  companies: Company[];
  lgid: number;
  CanGenerateFiles: boolean; //boolean whether to show generateFiles button in UI
  //outputtables: string[];
  pockadvservername: PockAdvServerName;
  odataCreds: OdataCreds | null;
  customApiCreds?: CustomEndpoint[] | null;
  allowAsync: boolean;
  id: string; //for compatability with Dynamo

  //this is the CUSTOMER TRANSFORMED field that matches to our OrderSummaryId.
  //this designates how the order should be split
  //eg for Murphy their SalesOrderNo field exactly matches our OrderSummaryId. we send orders over line by line with no grouping
  //eg for FB we send orders grouped by our orderSummaryId, and the Category (BEER/WINE). thus their LNFSalesId is not 1-1 with our OrderSummaryId
  //orderSplitColumn: { header: string; detail: string } | null;
  //returnSplitColumn: { header: string; detail: string } | null;

  //LIST OF CUSTOMERS
  static CustomerListOLD: Customer[] = [
    {
      name: CustomerName.Skurnik,
      id: "16218973455532",
      dbname: CustDbNames.Skurnik,
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 1572,
          twodigitcoid: "19",
          name: "MSWines",
          customerCoid: "",
        },
      ],
      lgid: 37,
      allowAsync: true,
      pockadvservername: PockAdvServerName.Ecloud1,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        client_id: "",
        resource: "http://nav.skurnikwines.com:7048/TEST/ODataV4/",
        client_secret: "SU5WRU5USVY6RkQjIVlAOEpKWUJCd003Yw==",
        endpoints: [
          {
            name: "WC_PA_OrderConf",
            urlparam: {
              rsc: "Company('Michael%20Skurnik%20Wines')",
            }, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: {}, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
          },
        ],
        pkgApiEndpoints: null,
      },
    },
    {
      name: CustomerName.SanAntonio,
      id: "1628897393065",
      dbname: CustDbNames.SanAntonio,
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 1779,
          twodigitcoid: "10",
          name: "SoCal",
          customerCoid: "48d8a4c5-1c8b-ea11-a813-000d3a4e631c",
        },
      ],
      lgid: 66,
      allowAsync: true,
      pockadvservername: PockAdvServerName.Ecloud1,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        client_id: "",
        resource: "https://12.18.191.130:8443/api1/x3/erp/SAW/",
        client_secret: "SU5WRU5USVY6RkQjIVlAOEpKWUJCd003Yw==",
        endpoints: [
          {
            name: "YPAITM",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: { representation: "YPAITM.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "ITMREF",
              environment: "SAW",
            },
          },
          {
            name: "YBPC",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: {
              representation: "YBPC.$query",
              where: "YBILLTOCUS ne 2",
            }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "BPCNUM",
              environment: "SAW",
              //filterSqlList: `YBILLTOCUS ='false'`,//unused because parent did filter
            },
          },
          {
            name: "YTUN",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: { representation: "YTUN.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "", //empty because NO CHILD TABLE
              environment: "SAW",
            },
          },
          {
            name: "YSIV",
            urlparam: null, //for fb. after resource name eg Items/data/... then append params
            queryparam: { representation: "YSIV.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "NUM",
              environment: "SAW",
              //filterSqlList: `YBILLTOCUS ='false'`,
            },
          },
          {
            name: "YSID",
            urlparam: null, //for fb. after resource name eg Items/data/... then append params
            queryparam: { representation: "YSID.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "", //no children
              environment: "SAW",
              //filterSqlList: `YBILLTOCUS ='false'`,
            },
          },
          {
            name: "YARINV",
            urlparam: null, //for fb. after resource name eg Items/data/... then append params
            queryparam: { representation: "YARINV.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "", //no children
              environment: "SAW",
              //filterSqlList: `YBILLTOCUS ='false'`,
            },
          },
          {
            name: "YSTO",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: {
              representation: "YSTO.$query",
              //where:
              //  "STOFCY eq 'WSAW' or STOFCY eq 'WFUL' or STOFCY eq 'WVNC' or STOFCY eq 'WVSC'",
            },
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "",
              environment: "SAW",
              //filterSqlList: `YBILLTOCUS ='false'`,
            },
          },
        ],
        pkgApiEndpoints: null,
      },
      customApiCreds: [
        {
          name: "Order",
          url: "https://12.18.191.130:8443/soap-generic/syracuse/collaboration/syracuse/CAdxWebServiceXmlCC",
          body: null,
          headers: {
            SOAPAction: "*",
            "Content-Type": "application/json",
            Authorization: `Basic SU5WRU5USVY6RkQjIVlAOEpKWUJCd003Yw==`, //username:INVENITV
          },
          bodyParams: {
            environment: "SAW",
            publicname: "ZSOH",
            key: "",
          },
        },
      ],
    },
    //pull from TEST SQL PA App server(13.56..), write to SanAntonioTEST db on GK server
    {
      name: CustomerName.SanAntonioTest,
      id: "1628897393132", //new Date().getTime().toString(),
      dbname: CustDbNames.SanAntonioTest,
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 10009,
          twodigitcoid: "10",
          name: "SoCalAws",
          customerCoid: "48d8a4c5-1c8b-ea11-a813-000d3a4e631c",
        },
      ],
      lgid: 66,
      allowAsync: false,
      pockadvservername: PockAdvServerName.Acloud20,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        client_id: "",
        resource: "https://12.18.191.130:8443/api1/x3/erp/PREPROD/",
        client_secret: "SU5WRU5USVY6RkQjIVlAOEpKWUJCd003Yw==",
        endpoints: [
          {
            name: "YPAITM",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: { representation: "YPAITM.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "ITMREF",
              environment: "PREPROD",
            },
          },
          {
            name: "YBPC",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: {
              representation: "YBPC.$query",
              where: "YBILLTOCUS ne 2",
            }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "BPCNUM",
              environment: "PREPROD",
              //filterSqlList: `YBILLTOCUS ='false'`,//unused because parent did filter
            },
          },
          {
            name: "YTUN",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: { representation: "YTUN.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "", //empty because NO CHILD TABLE
              environment: "PREPROD",
            },
          },
          {
            name: "YSIV",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: { representation: "YSIV.$query" }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "NUM",
              environment: "PREPROD",
              //filterSqlList: `YBILLTOCUS ='false'`,
            },
          },
          {
            name: "YSTO",
            urlparam: null, //for fb. after reosurce name eg Items/data/... then append params
            queryparam: {
              representation: "YSTO.$query",
              where: "STOFCY eq 'WSAW' or STOFCY eq 'WFUL' or STOFCY eq 'WVNC' or STOFCY eq 'WVSC'",
            }, // eg param:"?company=FoleyFulfillmentTEST" to append to url directly
            indexSQLCol: null,
            isPost: false,
            bodyParams: {
              publicname: "", //only for custom
              key: "",
              environment: "PREPROD",
              //filterSqlList: `YBILLTOCUS ='false'`,
            },
          },
        ],
        pkgApiEndpoints: null,
      },
      customApiCreds: [
        {
          name: "Order",
          url: "https://12.18.191.130:8443/soap-generic/syracuse/collaboration/syracuse/CAdxWebServiceXmlCC",
          body: null,
          headers: {
            SOAPAction: "*",
            "Content-Type": "application/json",
            Authorization: `Basic SU5WRU5USVY6RkQjIVlAOEpKWUJCd003Yw==`, //username:INVENITV
          },
          bodyParams: {
            environment: "PREPROD",
            publicname: "ZSOH",
            key: "",
          },
        },
      ],
    },
    //BacchusTest
    {
      name: CustomerName.BacchusTest,
      id: "16288973940499",
      dbname: CustDbNames.BacchusTest,
      pockadvservername: PockAdvServerName.Acloud20,
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 10135,
          twodigitcoid: "01",
          name: "Bacchus Importers",
          customerCoid: "100b95b9-a3ad-eb11-9b52-000d3a1ec9ee",
        },
      ],
      lgid: 8,
      allowAsync: true,
      odataCreds: {
        grant_type: "basic",
        resource:
          "https://api.businesscentral.dynamics.com/v2.0/b0b1d46f-f0e1-4732-a328-e4176a2d0165/Sandbox/api/wci/bacchus/v1.0/companies(100b95b9-a3ad-eb11-9b52-000d3a1ec9ee)/",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "REVTTU9ORC5KT0hOU09OOnAxUDU1aXFKUFVPbFlUVXJhenkxQ3Rlc3VJemxPb3hmaDlKbW9qek9jNU09",
        endpoints: [
          {
            name: "pocketAdvantageCustomersInfoSyns",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },

          {
            name: "pocketAdvantageBackordersData",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageCustomersInfo",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageExportTransactionsInfoSIL",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageExportTransactionsInfoCredit",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageItemsInfoSyns",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageItemsInfo",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails10",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails12",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails20",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails21",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails22",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails23",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails25",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails30",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails32",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails33",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails35",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails41",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails42",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails43",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails50",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails52",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails55",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails56",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails62",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails61",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
        ],
        pkgApiEndpoints: null,
        tokenPostAddress: null,
      },
    },
    {
      name: CustomerName.Bacchus,
      id: "1633037298452",
      dbname: CustDbNames.Bacchus,
      pockadvservername: PockAdvServerName.Acloud20, //PockAdvServerName.Bacchus,
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 1589,
          twodigitcoid: "01",
          name: "Bacchus Importers",
          customerCoid: "100b95b9-a3ad-eb11-9b52-000d3a1ec9ee",
        },
      ],
      lgid: 8,
      allowAsync: true,
      odataCreds: {
        grant_type: "basic",
        resource:
          "https://api.businesscentral.dynamics.com/v2.0/b0b1d46f-f0e1-4732-a328-e4176a2d0165/Production/api/wci/bacchus/v1.0/companies(100b95b9-a3ad-eb11-9b52-000d3a1ec9ee)/",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "REVTTU9ORC5KT0hOU09OOjlyL1RzMjNVM0Q3ZCtDcUw4M2dGaTFoMCtjRzBZbC9ORFgwSVpQN21tT009",
        endpoints: [
          {
            name: "pocketAdvantageCustomersInfoSyns",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },

          {
            name: "pocketAdvantageBackordersData",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageCustomersInfo",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageExportTransactionsInfoSIL",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageExportTransactionsInfoCredit",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageItemsInfoSyns",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageItemsInfo",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails10",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails12",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails20",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails21",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails22",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails23",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails25",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails30",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails32",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails33",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails35",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails41",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails42",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails43",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails50",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails52",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails55",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails56",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails62",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
          {
            name: "pocketAdvantageDetails61",
            indexSQLCol: null,
            queryparam: {},
            isPost: false,
            urlparam: {},
          },
        ],
        pkgApiEndpoints: null,
        tokenPostAddress: null,
      },
    },
    //Only writing orders out, not generating nightly files
    {
      id: "1628897393140",
      name: CustomerName.MurphyTest,
      dbname: CustDbNames.MurphyTest,
      pockadvservername: PockAdvServerName.Acloud20,
      odataCreds: {
        grant_type: "basic",
        resource: "https://api.businesscentral.dynamics.com/v2.0/147d71a3-adc7-4c4c-9cdb-0ff904aba6e7/MurphyDistributorsTest",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "cG9ja2V0YWR2YW50YWdlOkwzTXYwZ3dsOVd4SG5mNHlZRmx2aUVhdEJZWmJWK2JMbkRQc290SDVjRDQ9",
        endpoints: null,
        pkgApiEndpoints: null,
        tokenPostAddress: null,
      },
      CanGenerateFiles: false, //CANT GENERATE FILE BECAUSE data not provided by BC api
      companies: [
        {
          awscoid: 10092,
          twodigitcoid: "83",
          name: "MurphyTest",
          customerCoid: "21b5927d-9c70-eb11-b854-000d3a715c91",
        },
      ],
      lgid: 70,
      allowAsync: true,
    },
    //reads in data from sage- Dylan does a batch upload to GK sql server
    //outputs processed orders to GK SQL, then Dylan imports into sage
    {
      id: "1628897393152",
      name: CustomerName.Murphy,
      dbname: CustDbNames.Murphy,
      CanGenerateFiles: true,
      allowAsync: true,
      pockadvservername: PockAdvServerName.Ecloud1,
      companies: [
        {
          awscoid: 1833,
          twodigitcoid: "83",
          name: "Murphy",
          customerCoid: "8b40e6f9-816c-eb11-86a5-000d3af1ac6a",
        },
      ],
      odataCreds: {
        grant_type: "basic",
        resource: "https://api.businesscentral.dynamics.com/v2.0/147d71a3-adc7-4c4c-9cdb-0ff904aba6e7/Production",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "cG9ja2V0YWR2YW50YWdlOjQvTG1WN3pXK0ZFYnVmeDRBbUFiU1dFNjJ0Qm0yVlQzR0NyTm5YY3MyRHM9",
        endpoints: null,
        pkgApiEndpoints: null,
        tokenPostAddress: null,
      },
      lgid: 70,
    },
    //used as test customer
    {
      id: "1628897393163",
      name: CustomerName.Calyx,
      dbname: CustDbNames.Calyx,
      pockadvservername: PockAdvServerName.Null,
      CanGenerateFiles: false,
      allowAsync: false,
      companies: [
        { awscoid: 1844, twodigitcoid: "63", name: "CalyxNorth" },
        { awscoid: 1843, twodigitcoid: "62", name: "CalyxSouth" },
      ],
      lgid: 71,
      odataCreds: null,
    },
    //FB- they have d365 api calls
    {
      name: CustomerName.FavoriteBrands,
      dbname: CustDbNames.FavoriteBrands,
      id: "1628897393171",
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecloud16,
      companies: [
        { awscoid: 10007, twodigitcoid: "45", name: "Dallas" },
        { awscoid: 10053, twodigitcoid: "46", name: "Austin" },
        { awscoid: 10054, twodigitcoid: "47", name: "Houston" },
        { awscoid: 10060, twodigitcoid: "48", name: "Albuquerque" },
      ],
      lgid: 83,
      allowAsync: true,
      odataCreds: {
        tokenPostAddress: "https://login.microsoftonline.com/87cb5c86-0d85-4784-b5ab-b0bf8f994202/oauth2/token",
        grant_type: "client_credentials",
        client_id: "9f7935c6-ebb2-41b9-8611-c3a31bb37e1c",
        resource: "https://fb-lf-prod.operations.dynamics.com",
        client_secret: "8N2p1PiXGMGcJ/s3MUE:BTNqJ_zmPpf=",
        endpoints: [
          {
            name: "INVCustomers",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "CustomerItemGroupSetup",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: "LNFCustAccount",
            isPost: false,
          },
          {
            name: "InventivItems",
            urlparam: { rsc: "data" },
            queryparam: {
              $filter: "SalesProcessingStopped eq Microsoft.Dynamics.DataEntities.NoYes'No'",
            },
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "WarehouseInventoryStatusesOnHand",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "WorkCalendarTimeTemplates",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          //only last 30 days- removed on test server because might not have invoices recently
          {
            name: "SalesInvoiceV2Lines",
            urlparam: { rsc: "data" },
            queryparam: {
              //$filter: `InvoiceDate gt ${getSqlDateCustom(-2)}`,
              $orderby: `InvoiceDate desc`,
              $top: `8000`,
            },
            indexSQLCol: null,
            isPost: false,
          },
          //POST endpoint for order...specified BC rscparam is different
          {
            name: "services/LNFInventivIntegrations/LNFINVPaymentLine/createPaymentJournal",
            urlparam: { rsc: "api" },
            queryparam: {},
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "FoxPriceChannels",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "FoxPriceFamily",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "FoxPriceFreeGoods",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          }, //PKG API??- IGNORE FOR NOW (jim)
          {
            name: "FoxPriceFrontLine",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "FoxPriceUpCharge",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "BrandCountyExclusions",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFReturnReasonCodes",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFItemSamplePolicies",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "INVLicenseEligibilityCodes",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "OpenCustomerTransactions",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "PurchaseOrderLinesV2",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "KegShellReturnTracking",
            urlparam: { rsc: "data" },
            queryparam: {
              //$filter: `RMACreationDate gt ${getSqlDateCustom(-7)}`,
            },
            indexSQLCol: null,
            isPost: false,
          }, //only returns that are not closed (and kegs) have status 'Awaiting'
          {
            name: "LNFReturnOrderLines",
            urlparam: { rsc: "data" },
            queryparam: {
              $filter: `ReturnStatus eq Microsoft.Dynamics.DataEntities.ReturnStatusLine'Awaiting'`,
            },
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFReturnOrderHeader",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "CustomerItemRestrictions",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "Registrations",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFINVCustomerSalesReps",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "InventivOnHandInventory",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          }, //replacement for WarehouseInventoryOnHand
        ],
        pkgApiEndpoints: [
          //V1
          //{ name: "Dallas Inventory on-hand", savedAs: "DallasWarehouseInventoryStatusesOnHand", legalEntity: "FB", fileExtension: ".csv" },
          //V2
          {
            name: "Inventiv Dallas On-Hand Inventory",
            savedAs: "DallasWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Houston On-Hand Inventory",
            savedAs: "HoustonWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Austin On-Hand Inventory",
            savedAs: "AustinWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Albuquerque On-Hand Inventory",
            savedAs: "AlbuquerqueWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv customers",
            savedAs: "INVCustomers_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Customer Item Group Setup",
            savedAs: "CustomerItemGroupSetup_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: "LNFCustAccount",
          },
          {
            name: "Customer item restrictions",
            savedAs: "CustomerItemRestrictions_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Customer sales reps",
            savedAs: "LNFINVCustomerSalesReps_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv license eligibility codes",
            savedAs: "INVLicenseEligibilityCodes_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: "ACCOUNTNUM",
          },
          {
            name: "Inventiv Keg Shell Return Tracking",
            savedAs: "InventivKegShellReturnTracking_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Purchase Order Lines",
            savedAs: "InventivPurchaseOrderLines_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Open Customer Transactions",
            savedAs: "InventivOpenCustomerTransactions_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Item Sample Policies",
            savedAs: "InventivItemSamplePolicies_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Fox Price Front Line",
            savedAs: "InventivFoxPriceFrontLine_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Fox Price Channels",
            savedAs: "InventivFoxPriceChannels_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Registrations",
            savedAs: "InventivRegistrations_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Return Order Lines",
            savedAs: "InventivReturnOrderLines_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
        ],
      },
    },
    //TEST FB server &  (ecloud 18)
    {
      name: CustomerName.FavoriteBrandsTest,
      dbname: CustDbNames.FavoriteBrandsTest,
      id: "1628897393250",
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecloud18,
      companies: [
        { awscoid: 10048, twodigitcoid: "45", name: "DallasTest" },
        { awscoid: 10067, twodigitcoid: "46", name: "AustinTest" },
        { awscoid: 10068, twodigitcoid: "47", name: "HoustonTest" },
        { awscoid: 10069, twodigitcoid: "48", name: "AlbuquerqueTest" },
      ],
      lgid: 83,
      allowAsync: true,
      odataCreds: {
        tokenPostAddress: "https://login.microsoftonline.com/87cb5c86-0d85-4784-b5ab-b0bf8f994202/oauth2/token",
        grant_type: "client_credentials",
        client_id: "9f7935c6-ebb2-41b9-8611-c3a31bb37e1c",
        resource: "https://fb-lf-test.sandbox.operations.dynamics.com",
        client_secret: "8N2p1PiXGMGcJ/s3MUE:BTNqJ_zmPpf=",
        endpoints: [
          {
            name: "INVCustomers",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "CustomerItemGroupSetup",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: "LNFCustAccount",
            isPost: false,
          },
          {
            name: "InventivItems",
            urlparam: { rsc: "data" },
            queryparam: {
              $filter: "SalesProcessingStopped eq Microsoft.Dynamics.DataEntities.NoYes'No'",
            },
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "WarehouseInventoryStatusesOnHand",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "WorkCalendarTimeTemplates",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          //only last 30 days- removed on test server because might not have invoices recently
          {
            name: "SalesInvoiceV2Lines",
            urlparam: { rsc: "data" },
            queryparam: {
              //doesnt work when pulled from Lambda b/c not calc date
              //$filter: `InvoiceDate gt ${getSqlDateCustom(-30)}`,
              //this sorts then returns the top 10k (which should be last 3 days)
              $orderby: `InvoiceDate desc`,
              $top: `8000`,
            },
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "SalesOrderHeadersV2",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "LNFReturnOrderLines",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "services/LNFInventivIntegrations/LNFINVPaymentLine/createPaymentJournal",
            urlparam: { rsc: "api" },
            queryparam: {},
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "FoxPriceChannels",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "FoxPriceFamily",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "FoxPriceFreeGoods",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          }, //PKG API??- IGNORE FOR NOW (jim)
          {
            name: "FoxPriceFrontLine",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "FoxPriceUpCharge",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "BrandCountyExclusions",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFReturnReasonCodes",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFItemSamplePolicies",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "INVLicenseEligibilityCodes",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "OpenCustomerTransactions",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "PurchaseOrderLinesV2",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "KegShellReturnTracking",
            urlparam: { rsc: "data" },
            queryparam: {
              //$filter: `RMACreationDate gt ${getSqlDateCustom(-7)}`,
            },
            indexSQLCol: null,
            isPost: false,
          }, //only returns that are not closed (and kegs) have status 'Awaiting'
          {
            name: "LNFReturnOrderLines",
            urlparam: { rsc: "data" },
            queryparam: {
              $filter: `ReturnStatus eq Microsoft.Dynamics.DataEntities.ReturnStatusLine'Awaiting'`,
            },
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFReturnOrderHeader",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "CustomerItemRestrictions",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "Registrations",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "LNFINVCustomerSalesReps",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "InventivOnHandInventory",
            urlparam: { rsc: "data" },
            queryparam: {},
            indexSQLCol: null,
            isPost: false,
          }, //replacement for WarehouseInventoryOnHand
        ],
        pkgApiEndpoints: [
          //V1
          //{ name: "Dallas Inventory on-hand", savedAs: "DallasWarehouseInventoryStatusesOnHand", legalEntity: "FB", fileExtension: ".csv" },
          //V2
          {
            name: "Inventiv Dallas On-Hand Inventory",
            savedAs: "DallasWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Houston On-Hand Inventory",
            savedAs: "HoustonWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Austin On-Hand Inventory",
            savedAs: "AustinWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Albuquerque On-Hand Inventory",
            savedAs: "AlbuquerqueWarehouseInventoryStatusesOnHand_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv customers",
            savedAs: "INVCustomers_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Customer Item Group Setup",
            savedAs: "CustomerItemGroupSetup_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: "LNFCustAccount",
          },
          {
            name: "Customer item restrictions",
            savedAs: "CustomerItemRestrictions_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Customer sales reps",
            savedAs: "LNFINVCustomerSalesReps_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv license eligibility codes",
            savedAs: "INVLicenseEligibilityCodes_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: "ACCOUNTNUM",
          },
          {
            name: "Inventiv Keg Shell Return Tracking",
            savedAs: "InventivKegShellReturnTracking_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Purchase Order Lines",
            savedAs: "InventivPurchaseOrderLines_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Open Customer Transactions",
            savedAs: "InventivOpenCustomerTransactions_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Item Sample Policies",
            savedAs: "InventivItemSamplePolicies_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Fox Price Front Line",
            savedAs: "InventivFoxPriceFrontLine_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Fox Price Channels",
            savedAs: "InventivFoxPriceChannels_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Registrations",
            savedAs: "InventivRegistrations_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
          {
            name: "Inventiv Return Order Lines",
            savedAs: "InventivReturnOrderLines_pkgapi",
            legalEntity: "FB",
            fileExtension: ".csv",
            indexSQLCol: null,
          },
        ],
      },
    },
    //martines
    {
      id: "1628897393340",
      name: CustomerName.MartinesWine,
      dbname: CustDbNames.MartinesWine,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecloud2,
      companies: [
        { awscoid: 10078, twodigitcoid: "01", name: "CA" },
        { awscoid: 10079, twodigitcoid: "09", name: "IL" },
        { awscoid: 10080, twodigitcoid: "14", name: "NATL" },
        { awscoid: 10081, twodigitcoid: "15", name: "NY" },
      ],
      lgid: 92,
      allowAsync: true,
      odataCreds: null,
    },
    //opici
    {
      name: CustomerName.Opici,
      dbname: CustDbNames.Opici,
      id: "1628897393348",
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecloud17,
      companies: [
        { awscoid: 10051, twodigitcoid: "30", name: "OpiciWines" },
        { awscoid: 10091, twodigitcoid: "31", name: "UNY" },
      ],
      lgid: 89,
      allowAsync: false,
      odataCreds: {
        tokenPostAddress: "https://login.windows.net/theopicigroup.onmicrosoft.com/oauth2/token",
        grant_type: "client_credentials",
        client_id: "0e8812de-989a-4bae-a0a1-50c376752d4a",
        resource: "https://opici-test.sandbox.operations.dynamics.com",
        client_secret: "]9NOD.I?m1[D6V2u-7_m.../f@2JlqB-",
        endpoints: [
          {
            name: "CustomersV3",
            queryparam: {},
            urlparam: {},
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "ReleasedProductsV2",
            queryparam: {},
            urlparam: {},

            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "salesOrder",
            queryparam: {},
            urlparam: {
              rsc: "api/Services/TOGIntegrationServiceGroup/TOGInventivSalesOrderService",
            },
            indexSQLCol: null,
            isPost: true,
          },
        ],
        pkgApiEndpoints: [
          {
            name: "INVENTIV_ITEM_MASTER",
            savedAs: "InventivItemMaster_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_MASTER",
            savedAs: "InventivCustomerMaster_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_STATUS",
            savedAs: "InventivCustStatus_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_OPEN_AR",
            savedAs: "InventivCustOpenAr_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_INV",
            savedAs: "InventivCustInvoices_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BACKORDERS",
            savedAs: "InventivCustBackorders_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_RETURN_ORDER",
            savedAs: "InventivReturnOrder_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_COOP",
            savedAs: "InventivCustCoop_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BH_SHARED_GROUP",
            savedAs: "InventivBhSharedGroup_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BH_PULL_ORDER",
            savedAs: "InventivBhPullOrder_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BH_CURRENT_STOCK",
            savedAs: "InventivBhCurrentStock_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_SALES_HIERARCHY",
            savedAs: "InventivSalesHierarchy_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_SALES_ORDER",
            savedAs: "InventivSalesOrder_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_SALES_ORDER",
            savedAs: "InventivSalesOrder_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_SALESPERSON",
            savedAs: "InventivSalesperson_1000_pkgapi",
            legalEntity: "1000",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          //-----------------------------------------------------------------------
          {
            name: "INVENTIV_ITEM_MASTER",
            savedAs: "InventivItemMaster_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_MASTER",
            savedAs: "InventivCustomerMaster_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_STATUS",
            savedAs: "InventivCustStatus_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_OPEN_AR",
            savedAs: "InventivCustOpenAr_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_INV",
            savedAs: "InventivCustInvoices_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BACKORDERS",
            savedAs: "InventivCustBackorders_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_RETURN_ORDER",
            savedAs: "InventivReturnOrder_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_CUST_COOP",
            savedAs: "InventivCustCoop_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BH_SHARED_GROUP",
            savedAs: "InventivBhSharedGroup_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BH_PULL_ORDER",
            savedAs: "InventivBhPullOrder_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_BH_CURRENT_STOCK",
            savedAs: "InventivBhCurrentStock_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_SALES_HIERARCHY",
            savedAs: "InventivSalesHierarchy_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
          {
            name: "INVENTIV_SALESPERSON",
            savedAs: "InventivSalesperson_2001_pkgapi",
            legalEntity: "2001",
            fileExtension: ".xlsx",
            indexSQLCol: null,
          },
        ],
      },
      customApiCreds: [
        {
          name: "APLDISC_ZERO_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("APLDISC_ZERO", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "APLFRONT_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("APLFRONT", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "Deals4_QDs_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("QDs", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "MGRDEAL_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("MGRDEAL$", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "MGRDEALNET_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("MGRDEALNET", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "OVERRIDE_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("OVERRIDE", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "POSTOFF_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("POSTOFF$", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "QDs_Total_Book_Assort_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("QDs Total Book Assort", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "RIP_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("RipQty$", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "Frontline_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("FRONTLINE", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "SPLIT_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("SPLIT$", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "SPOTD_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("SPOTD$", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "TOPDEAL_1000_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("TOPDEAL$", "1000"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },

        //----------------------------------------2001
        {
          name: "APLDISC_ZERO_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("APLDISC_ZERO", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "APLFRONT_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("APLFRONT", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "Deals4_QDs_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("QDs", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "MGRDEAL_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("MGRDEAL$", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "MGRDEALNET_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("MGRDEALNET", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "OVERRIDE_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("OVERRIDE", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "POSTOFF_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("POSTOFF$", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "QDs_Total_Book_Assort_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("QDs Total Book Assort", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "RIP_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("RipQty$", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "Frontline_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("FRONTLINE", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "SPLIT_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("SPLIT$", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "SPOTD_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("SPOTD$", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
        {
          name: "TOPDEAL_2001_custom",
          url: "http://op-ff-services.eastus.cloudapp.azure.com:8007/Flintfox/optest/RMxPricing",
          body: getFFBody("TOPDEAL$", "2001"),
          headers: {
            SOAPAction: "http://tempuri.org/ICustomNpriceService/GetComponentPrices",
            "Content-Type": "application/soap+xml",
          },
          bodyParams: null,
        },
      ],
    },
    //opici ecomm- pull nightly data from ecom server, updated by Harrison
    {
      name: CustomerName.OpiciEcomm,
      dbname: CustDbNames.OpiciEcomm,
      id: "1628897393703",
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        { awscoid: 10118, twodigitcoid: "40", name: "NJ" },
        { awscoid: 10119, twodigitcoid: "41", name: "NYU" },
      ],
      lgid: 89,
      allowAsync: true,
      odataCreds: null,
      customApiCreds: null,
    },
    //epic
    {
      id: "1628897393717",
      name: CustomerName.Epic,
      dbname: CustDbNames.Epic,
      CanGenerateFiles: false,
      companies: [{ awscoid: 1692, twodigitcoid: "17", name: "EpicWines" }],
      lgid: 22,
      allowAsync: false,
      pockadvservername: PockAdvServerName.Ecloud5,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        resource: "https://st-118045.dynamicstocloud.com:1303/ST-118045-2/OData/Company('TeraVina')",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "VGVzdFVzZXIxOk1FamowMTk5OSE=",
        endpoints: [
          {
            name: "Item",
            queryparam: { $format: "json" },
            urlparam: {},
            indexSQLCol: null,
            isPost: false,
          },
        ],
        pkgApiEndpoints: [],
      },
    },
    //winebow (124 records)
    {
      id: "1628897393732",
      name: CustomerName.WinebowWest,
      dbname: CustDbNames.WinebowWest,
      CanGenerateFiles: true,
      companies: [{ awscoid: 1705, twodigitcoid: "10", name: "CA" }],
      lgid: 64,
      allowAsync: false,
      pockadvservername: PockAdvServerName.WinebowTest,
      odataCreds: null,
    },
    //winebow WEST TEST (washington through Provi)
    {
      name: CustomerName.WinebowWestTestEdi,
      id: "1628897393742",
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 10122,
          twodigitcoid: "40",
          name: "Washington Test",
        },
      ],
      lgid: 64,
      allowAsync: false,
      odataCreds: null,
    },
    //winebow WEST (washington through Provi)
    {
      name: CustomerName.WinebowWestEdi,
      id: "1628897393753",
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 1823,
          twodigitcoid: "40",
          name: "Washington",
        },
      ],
      lgid: 64,
      allowAsync: false,
      odataCreds: null,
    },
    //winebow (East/MidAtlantic)
    {
      id: "1628897393763",
      name: CustomerName.WinebowEast,
      dbname: CustDbNames.WinebowEast,
      CanGenerateFiles: true,
      companies: [
        { awscoid: 10123, twodigitcoid: "11", name: "DC Test" },
        { awscoid: 10124, twodigitcoid: "12", name: "DE Test" },
        { awscoid: 10125, twodigitcoid: "13", name: "FL Test" },
        { awscoid: 10126, twodigitcoid: "14", name: "NC1 Test" },
        { awscoid: 10127, twodigitcoid: "15", name: "NC2 Test" },
        { awscoid: 10128, twodigitcoid: "16", name: "NC3 Test" },
        { awscoid: 10129, twodigitcoid: "17", name: "NC4 Test" },
        { awscoid: 10130, twodigitcoid: "18", name: "NC5 Test" },
        { awscoid: 10131, twodigitcoid: "19", name: "SC Test" },
        { awscoid: 10132, twodigitcoid: "20", name: "VA Test" },
      ],
      lgid: 64,
      allowAsync: false,
      pockadvservername: PockAdvServerName.WinebowTest,
      odataCreds: null,
    },
    /*
    {
      //used for stupid return hack testing for FB conn to ecloud15 instead
      name: CustomerName.Ecloud15,
      dbname: CustDbNames.Logs,
      pockadvservername: PockAdvServerName.Ecloud15,
      bucket: "gk-website",
      orderSplitColumn: null,
      returnSplitColumn: null,
      CanGenerateFiles: false,
      twodigitcoid: "45",
      useTestServer: true,
      outputtables: [],
      odataCreds: null,
    },
    */
    //Admin
    {
      id: "1628897393781",
      name: CustomerName.Admin,
      dbname: CustDbNames.Logs,
      pockadvservername: PockAdvServerName.Null,
      CanGenerateFiles: false,
      allowAsync: false,
      companies: [],
      lgid: 1,
      odataCreds: null,
    },
    //InventivAws
    {
      name: CustomerName.InventivAws,
      id: "1628897393792",
      dbname: CustDbNames.Inventiv,
      pockadvservername: PockAdvServerName.Aws54234,
      CanGenerateFiles: false, //CANT GENERATE FILE BECAUSE POINTED TO LIVE SERVER (in order to pull data for testing)
      companies: [{ awscoid: 1038, twodigitcoid: "01", name: "Inventiv" }],
      lgid: 1,
      allowAsync: false,
      odataCreds: null,
    },
    //WineWarehouseEdi
    {
      id: "1628897393805",
      name: CustomerName.WineWarehouseEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        { awscoid: 1035, twodigitcoid: "10", name: "South" },
        { awscoid: 1036, twodigitcoid: "20", name: "North" },
        //{ awscoid: 10029, twodigitcoid: "10", name: "SouthTest" } in ARGOS, but doesnt exist on server
      ],
      lgid: 63,
      allowAsync: false,
      odataCreds: null,
    },
    //LipmanEdi
    {
      id: "1628897393815",
      name: CustomerName.LipmanEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 20,
          twodigitcoid: "01",
          name: "Lipman Bros Inc",
        },
        {
          awscoid: 21,
          twodigitcoid: "02",
          name: "RS Lipman Co",
        },
      ],
      lgid: 34,
      allowAsync: false,
      odataCreds: null,
    },
    //LipmanTestEdi
    {
      name: CustomerName.LipmanTestEdi,
      id: "1628897393828",
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 10142, //10075
          twodigitcoid: "01",
          name: "Lipman Test",
        },
        {
          awscoid: 10143,
          twodigitcoid: "02",
          name: "RS Lipman Co Test",
        },
      ],
      lgid: 34,
      allowAsync: false,
      odataCreds: null,
    },
    //MSWalkerEdi
    {
      name: CustomerName.MSWalkerEdi,
      dbname: CustDbNames.EDIOrders,
      id: "1628897393841",
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        { awscoid: 1590, twodigitcoid: "02", name: "MS Walker" },
        { awscoid: 1592, twodigitcoid: "04", name: "MS Walker RI" },
        { awscoid: 1593, twodigitcoid: "05", name: "MS Walker NY" },
        { awscoid: 1672, twodigitcoid: "06", name: "New Jersey" },
        { awscoid: 1591, twodigitcoid: "03", name: "MS Walker Premium Cigars" },
      ],
      lgid: 38,
      allowAsync: false,
      odataCreds: null,
    },
    //MSWalkerTestEdi
    {
      name: CustomerName.MSWalkerTestEdi,
      id: "1628897393854",
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        { awscoid: 1900, twodigitcoid: "02", name: "Test MS Walker" },
        { awscoid: 1903, twodigitcoid: "05", name: "Test MS Walker NY" },
        { awscoid: 1902, twodigitcoid: "04", name: "Test MS Walker RI" },
        {
          awscoid: 1901,
          twodigitcoid: "03",
          name: "Test MS Walker Premium Cigars",
        },
      ],
      lgid: 38,
      allowAsync: false,
      odataCreds: null,
    },
    //MajorBrandsEdi
    {
      id: "1628897393870",
      name: CustomerName.MajorBrandsEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: false,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 1760,
          twodigitcoid: "01",
          name: "Kansas City",
        },
        {
          awscoid: 1761,
          twodigitcoid: "02",
          name: "St Louis",
        },
        {
          awscoid: 1762,
          twodigitcoid: "03",
          name: "Columbia",
        },
        {
          awscoid: 1763,
          twodigitcoid: "05",
          name: "Springfield",
        },
      ],
      lgid: 35,
      allowAsync: false,
      odataCreds: null,
    },
    //MajorBrandsTestEdi
    {
      id: "1628897393890",
      name: CustomerName.MajorBrandsTestEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: false,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 10052,
          twodigitcoid: "01",
          name: "DR Kansas City",
        },
        {
          awscoid: 10063,
          twodigitcoid: "02",
          name: "DR St Louis",
        },
        {
          awscoid: 10064,
          twodigitcoid: "03",
          name: "DR Columbia",
        },
        {
          awscoid: 10065,
          twodigitcoid: "05",
          name: "DR Springfield",
        },
      ],
      lgid: 35,
      allowAsync: false,
      odataCreds: null,
    },
    //Athens
    {
      id: "",
      name: CustomerName.AthensTestEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: false,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 1572,
          twodigitcoid: "19",
          name: "MS Wines",
        },
      ],
      lgid: 0,
      allowAsync: false,
      odataCreds: null,
    },
    //Skurnik
    {
      id: "1628897393908",
      name: CustomerName.SkurnikEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: false,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 1572,
          twodigitcoid: "19",
          name: "MS Wines",
        },
        {
          awscoid: 1910,
          twodigitcoid: "22",
          name: "Vintner Select",
        },
        {
          awscoid: 1765,
          twodigitcoid: "21",
          name: "CA",
        },
        {
          awscoid: 1766,
          twodigitcoid: "20",
          name: "PA",
        },
      ],
      lgid: 37,
      allowAsync: false,
      odataCreds: null,
    },
    //Skurnik TEST EDI
    {
      id: "1628897393925",
      name: CustomerName.SkurnikTestEdi,
      dbname: CustDbNames.EDIOrders,
      CanGenerateFiles: false,
      pockadvservername: PockAdvServerName.Ecomm1,
      companies: [
        {
          awscoid: 10087,
          twodigitcoid: "19",
          name: "SkurnikTest",
        },
      ],
      lgid: 37,
      allowAsync: false,
      odataCreds: null,
    },
    //FredrickWildman Test
    {
      name: CustomerName.FredrickWildmanTest,
      dbname: CustDbNames.FredrickWildmanTest,
      id: "1628897393935",
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 10059,
          twodigitcoid: "20",
          name: "NJ",
          customerCoid: "58c27275-d130-eb11-846e-000d3a7d9775",
        },
        {
          awscoid: 10061,
          twodigitcoid: "22",
          name: "NYm",
          customerCoid: "58c27275-d130-eb11-846e-000d3a7d9775",
        },
        {
          awscoid: 10066,
          twodigitcoid: "24",
          name: "NYu",
          customerCoid: "58c27275-d130-eb11-846e-000d3a7d9775",
        },
      ],
      lgid: 23,
      allowAsync: false,
      pockadvservername: PockAdvServerName.Acloud20,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        resource: "https://api.businesscentral.dynamics.com/v2.0/32ae7108-82ed-46ff-b599-f5244eb782d7/FRWI-Sandbox",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "SU5WRU5USVY6SUZNTk4xQXN3U1JDSnVwbnRGWGV4NlFCZ3ZXbXJ2ZzYyOWVYTUdORVB6ND0=",
        endpoints: [
          {
            name: "items20",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "itemsTxt",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customers20",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customers22",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customers24",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "items22",
            queryparam: {},
            indexSQLCol: null,
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            isPost: false,
          },
          {
            name: "items24",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions20",
            queryparam: {
              $top: `2000`,
              $orderby: `billDate desc`,
            },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions22",
            queryparam: { $top: `2000`, $orderby: `billDate desc` },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions24",
            queryparam: { $top: `2000`, $orderby: `billDate desc` },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customersTxt",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "sysDetails20",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "sysDetails22",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "sysDetails24",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "orders",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "orderLines",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "webServiceMgt_ChangeSalesOrderStatus?company=58c27275-d130-eb11-846e-000d3a7d9775",
            queryparam: {},
            urlparam: {
              rsc: `/OdataV4/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "paymentTerms",
            queryparam: { $expand: "customerPaymentTerms" },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(32ae7108-82ed-46ff-b599-f5244eb782d7)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
        ],
        pkgApiEndpoints: [],
      },
    },
    //FredrickWildman
    {
      name: CustomerName.FredrickWildman,
      id: "1628897393989",
      dbname: CustDbNames.FredrickWildman,
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 1664,
          twodigitcoid: "20",
          name: "NJ",
          customerCoid: "58c27275-d130-eb11-846e-000d3a7d9775",
        },
        {
          awscoid: 1665,
          twodigitcoid: "22",
          name: "NYm",
          customerCoid: "58c27275-d130-eb11-846e-000d3a7d9775",
        },
        {
          awscoid: 1666,
          twodigitcoid: "24",
          name: "NYu",
          customerCoid: "58c27275-d130-eb11-846e-000d3a7d9775",
        },
      ],
      lgid: 23,
      allowAsync: false,
      pockadvservername: PockAdvServerName.Ecloud7,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        resource: "https://api.businesscentral.dynamics.com/v2.0/32ae7108-82ed-46ff-b599-f5244eb782d7/FRWI-Production",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "SU5WRU5USVY6SUZNTk4xQXN3U1JDSnVwbnRGWGV4NlFCZ3ZXbXJ2ZzYyOWVYTUdORVB6ND0=",
        endpoints: [
          {
            name: "itemsTxt",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customers20",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customers22",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customers24",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "items20",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "items22",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "items24",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions20",
            queryparam: { $top: `2000`, $orderby: `billDate desc` },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions22",
            queryparam: { $top: `2000`, $orderby: `billDate desc` },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions24",
            queryparam: { $top: `2000`, $orderby: `billDate desc` },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "transactions",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "customersTxt",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "sysDetails20",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "sysDetails22",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "sysDetails24",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: false,
          },
          {
            name: "orders",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "orderLines",
            queryparam: {},
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(58c27275-d130-eb11-846e-000d3a7d9775)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "webServiceMgt_ChangeSalesOrderStatus?company=58c27275-d130-eb11-846e-000d3a7d9775",
            queryparam: {},
            urlparam: {
              rsc: `/OdataV4/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
          {
            name: "paymentTerms",
            queryparam: { $expand: "customerPaymentTerms" },
            urlparam: {
              rsc: `/api/iNecta/frwi/v2.0/companies(32ae7108-82ed-46ff-b599-f5244eb782d7)/`,
            }, //rsc:data
            indexSQLCol: null,
            isPost: true,
          },
        ],
        pkgApiEndpoints: [],
      },
    },
    //WilsonDaniels Test
    {
      name: CustomerName.WilsonDanielsTest,
      dbname: CustDbNames.WilsonDanielsTest,
      id: "1628897394044",
      CanGenerateFiles: true,
      companies: [
        {
          awscoid: 10120,
          twodigitcoid: "72",
          name: "Test NY",
          customerCoid: "48d8a4c5-1c8b-ea11-a813-000d3a4e631c",
        },
        {
          awscoid: 10062,
          twodigitcoid: "74",
          name: "Test Oregon",
          customerCoid: "e818c58d-1c8b-ea11-a813-000d3a4e631c",
        },
      ],
      lgid: 66,
      allowAsync: false,
      pockadvservername: PockAdvServerName.Acloud20,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        resource: "https://api.businesscentral.dynamics.com/v2.0/01093d2c-cb20-40d7-b7d9-46a8c0c37165/WILSONDANIELS_TESTPROD",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "V0RQQTpnRi9vT3NnNWY4SUs3RzVuY1dpMi95QUV1dHFhVnpCdXVlMHBJTVJuK1FzPQ==",
        endpoints: [],
        pkgApiEndpoints: [],
      },
    },
    //ChambersAndChambers
    {
      id: "1628897394060",
      name: CustomerName.ChambersAndChambers,
      dbname: CustDbNames.ChambersAndChambers,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Ecloud19,
      companies: [
        { awscoid: 10070, twodigitcoid: "10", name: "CA" },
        { awscoid: 10071, twodigitcoid: "20", name: "HI" },
      ],
      lgid: 91,
      allowAsync: true,
      odataCreds: null,
    },
    //ChambersAndChambers Test
    {
      id: "1628897394069",
      name: CustomerName.ChambersAndChambersTest,
      dbname: CustDbNames.ChambersAndChambersTest,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Acloud20,
      companies: [
        { awscoid: 10088, twodigitcoid: "60", name: "CA" },
        { awscoid: 10089, twodigitcoid: "61", name: "HI" },
      ],
      lgid: 91,
      allowAsync: true,
      odataCreds: null,
    },
    //Crown (for FTP PDF)
    {
      id: "1628897394079",
      name: CustomerName.CrownTest,
      dbname: CustDbNames.CrownTest,
      CanGenerateFiles: false,
      pockadvservername: PockAdvServerName.Ecloud12,
      companies: [{ awscoid: 1040, twodigitcoid: "20", name: "GA" }],
      lgid: 91,
      allowAsync: true,
      odataCreds: null,
    },
    //USA Wine Imports
    {
      id: "1628897394089",
      name: CustomerName.UsaWineImports,
      dbname: CustDbNames.UsaWines,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.Acloud21,
      companies: [
        {
          awscoid: 10121,
          twodigitcoid: "86",
          name: "NY",
        },
      ],
      lgid: 98,
      allowAsync: true,
      odataCreds: null,
    },
    //RNDC WEST Test
    {
      id: "1628897394097",
      name: CustomerName.RNDCWestTest,
      dbname: CustDbNames.RNDCWestTest,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.RndcWestTest,
      companies: [{ awscoid: 10133, twodigitcoid: "80", name: "Denver Test" }],
      lgid: 92,
      allowAsync: true,
      odataCreds: {
        tokenPostAddress: "",
        grant_type: "basic",
        resource: "https://dpe-dev.rndc.vistaar.cloud:8443/PricingEngine/openapi",
        client_id: "",
        //base 64 encoded(?) username/password entered in postman and copied here
        client_secret: "cG9ja2V0QWR2YW50YWdlOkElNCpzWEA2eDlLJFhEZUY=",
        endpoints: [
          {
            name: "getPricingDetails",
            queryparam: {},
            urlparam: {},
            indexSQLCol: null,
            isPost: false,
          },
        ],
        pkgApiEndpoints: [],
      },
    },
    //RNDC WEST
    {
      id: "1628897394113",
      name: CustomerName.RNDCWest,
      dbname: CustDbNames.RNDCWest,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.RndcWest,
      companies: [
        { awscoid: 1444, twodigitcoid: "51", name: "Denver" },
        { awscoid: 1445, twodigitcoid: "31", name: "Albuquerque" },
      ],
      lgid: 92,
      allowAsync: true,
      odataCreds: null,
    },
    //Lipman- Vistaar API direct to Inventiv SQL (no sa-web db for )
    {
      name: CustomerName.LipmanTest,
      id: "1628897394123",
      dbname: CustDbNames.LipmanTest,
      CanGenerateFiles: true,
      pockadvservername: PockAdvServerName.LipmanTest,
      companies: [
        { awscoid: 10142, twodigitcoid: "01", name: "Lipman Test" },
        { awscoid: 10143, twodigitcoid: "02", name: "RS Lipman Co Test" },
      ],
      lgid: 34,
      allowAsync: true,
      odataCreds: null,
    },
    {
      dbname: CustDbNames.EDIOrders, //if edi ends with: EDi
      id: "2343254546456", //new Date().getTime().toString(),
      lgid: 8,
      name: CustomerName.EmpireDistTestEdi,
      allowAsync: false,
      CanGenerateFiles: false,
      companies: [
        {
          awscoid: 10139,
          customerCoid: null, //eg id the customer refers to the company by a GUID (eg Murphy) for API calls
          name: "Empire NC Charlotte Test",
          twodigitcoid: "24",
        },
        {
          awscoid: 10136,
          customerCoid: null,
          name: "Empire NC Asheville Test",
          twodigitcoid: "21",
        },
        {
          awscoid: 10137,
          customerCoid: null,
          name: "Empire NC Raleigh Test",
          twodigitcoid: "22",
        },
        {
          awscoid: 10138,
          customerCoid: null,
          name: "Empire NC Wilmington Test",
          twodigitcoid: "23",
        },
      ],
      odataCreds: null,
      pockadvservername: PockAdvServerName.Ecomm1,
    },
    {
      dbname: CustDbNames.EDIOrders, //if edi ends with: EDi
      id: "24554546456", //new Date().getTime().toString(),
      lgid: 8,
      name: CustomerName.EmpireDistEdi,
      allowAsync: false,
      CanGenerateFiles: false,
      companies: [
        {
          awscoid: 1890,
          customerCoid: null, //eg id the customer refers to the company by a GUID (eg Murphy) for API calls
          name: "Empire NC Charlotte",
          twodigitcoid: "24",
        },
        {
          awscoid: 1887,
          customerCoid: null,
          name: "Empire NC Asheville",
          twodigitcoid: "21",
        },
        {
          awscoid: 1888,
          customerCoid: null,
          name: "Empire NC Raleigh",
          twodigitcoid: "22",
        },
        {
          awscoid: 1889,
          customerCoid: null,
          name: "Empire NC Wilmington",
          twodigitcoid: "23",
        },
      ],
      odataCreds: null,
      pockadvservername: PockAdvServerName.Ecomm1,
    },
  ];
  // static companylist: Company[] = [].concat.apply(
  //   [],
  //   Customer.CustomerList.map((c) => c.companies)
  // );
  // static GetCustomerFromAwsCoId(awscoid: number): Customer | null {
  //   for (let cust of Customer.CustomerList) {
  //     if (cust.companies.map((x) => x.awscoid).includes(awscoid)) return cust;
  //   }
  //   return null;
  // }
}

function getFFBody(param: string, company: "1000" | "2001") {
  return `<s:Envelope xmlns:a="http://www.w3.org/2005/08/addressing" xmlns:s="http://www.w3.org/2003/05/soap-envelope">
            <s:Header>
              <a:Action s:mustUnderstand="1">http://tempuri.org/ICustomNpriceService/GetComponentPrices</a:Action>
              <a:MessageID>urn:uuid:8deb219e-0ef5-4790-b42e-e7dc7f1946ea</a:MessageID>
              <a:ReplyTo>
                <a:Address>http://www.w3.org/2005/08/addressing/anonymous</a:Address>
              </a:ReplyTo>
            </s:Header>
            <s:Body>
              <GetComponentPrices xmlns="http://tempuri.org/">
                <priceParameter xmlns:d4p1="http://schemas.datacontract.org/2004/07/Flintfox.nPrice.Plugin" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
                  <d4p1:Company>${company}</d4p1:Company>
                  <d4p1:ComponentCodeList>${param}</d4p1:ComponentCodeList>
                </priceParameter>
              </GetComponentPrices>
            </s:Body>
          </s:Envelope>`;
}

export function getSaBody(ep: CustomEndpoint, maxLim: number = 9989, value?: string, getList?: boolean) {
  if (value && getList) throw `must specify either value or list, not both`;
  return `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS" xmlns:soapenc="http://schemas.xmlsoap.org/soap/encoding/">
   <soapenv:Header/>
   <soapenv:Body>
      <wss:${getList ? "query" : "read"} soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
         <callContext xsi:type="wss:CAdxCallContext">
            <codeLang xsi:type="xsd:string">ENG</codeLang>
            <poolAlias xsi:type="xsd:string">PREPROD</poolAlias>
            <poolId xsi:type="xsd:string">?</poolId>
            <requestConfig xsi:type="xsd:string">adxwss.optreturn=json</requestConfig>
         </callContext>
         <publicName xsi:type="xsd:string">${ep.bodyParams.publicname}</publicName>
         <objectKeys xsi:type="wss:ArrayOfCAdxParamKeyValue" soapenc:arrayType="wss:CAdxParamKeyValue[]">${
           value ? `\n         <key>${ep.bodyParams.key}</key>` + `\n         <value>${value}</value>` : ``
         }
         </objectKeys>${getList ? `\n         <listSize xsi:type="xsd:int">${maxLim}</listSize>` : ``}
         <objectXml xsi:type="xsd:string">
         </objectXml>
      </wss:${getList ? "query" : "read"}>
   </soapenv:Body>
</soapenv:Envelope>
`;
}
