<div class="col col-lg-12">
    <h5>Lambda IP: {{lambdaip}}</h5>
    <h5 *ngIf="isEmployee"><a style="color:MediumSeaGreen;"
            href="https://inventivsoftware.atlassian.net/wiki/spaces/ID/pages/1848770591/How+To+Use+Status+Page+in+DataLink"
            target="_blank">Click HERE: How to Use the DataLink Status Page</a></h5>
    <div *ngIf="loading$.length>0">
        <mat-spinner></mat-spinner>
        <br>Loading: {{loading$}}
    </div>
    <div *ngIf="loading$.length<=0">

        <coselect></coselect>
        <hr />
        <div *ngIf="selectedCustomer && selectedCompany">

            <div *ngIf="loading$.length>0">
                <mat-spinner></mat-spinner>
                <br>Loading: {{loading$}}
            </div>
            <div *ngIf="loading$.length<=0">
                <!--FOR FAV BRANDS ONLY-->
                <div *ngIf="selectedCustomer.name.startsWith('FavoriteBrands')">
                    <div *ngIf="isEmployee" style="color:MediumSeaGreen;">Pulls SalesInvoiceV2Lines and generates
                        TransactPDRoutes.fil so it
                        can be picked up by
                        Inventiv PA Server<br></div>
                    <button mat-raised-button color="primary" (click)="PullOdataAndGenerateFile('TransactPDRoutes')"
                        style="margin: 5px;">
                        DRIVER: Update PD Driver App
                    </button>
                    <br>
                    <div *ngIf="isEmployee" style="color:MediumSeaGreen;">Pulls SalesInvoiceV2Lines and generates
                        TransactDO.fil so it can be
                        picked up by Inventiv PA
                        Server<br></div>
                    <button mat-raised-button color="primary" (click)="PullOdataAndGenerateFile('TransactDO')"
                        style="margin: 5px;">
                        SALES REP: Update DropOff App
                    </button>
                    <br>
                    <!--
                        Pulls XXX and generates Detail file so it can be picked up by Inventiv PA
                        Server<br>
                        <button mat-raised-button color="primary" (click)="PullOdataAndGenerateFile('TransactDO')"
                            style="margin: 5px;">
                            SALES REP: Update DropOff App
                        </button>
                        <br>
                        -->
                </div>
                <mat-checkbox [(ngModel)]="showAdvanced">Show advanced tools</mat-checkbox>
                <div *ngIf="showAdvanced">
                    <div *ngIf="selectedCustomer.odataCreds">

                        <div *ngIf="isEmployee" style="color:MediumSeaGreen;">Pulls SalesInvoiceV2Lines and generates
                            TransactPDRoutes.fil, TransactPDReturns.fil, TransactKegRet.fil, TransactPDCredit.fil so it
                            can be picked up by
                            Inventiv PA Server<br></div>
                        <button mat-raised-button color="warn"
                            (click)="PullOdataAndGenerateFile('TransactPDRoutes');PullOdataAndGenerateFile('TransactPDReturn');PullOdataAndGenerateFile('TransactPDKegRet');PullOdataAndGenerateFile('TransactPDCredit');"
                            style="margin: 5px;">
                            ALL Transact
                        </button>
                        <h2>Pull ALL Endpoints: {{selectedCustomer.name}}</h2>
                        <button mat-raised-button color="primary" (click)="ReloadAllOdataEndpoints()"
                            style="margin: 5px;">
                            Load ALL Odata Endpoints
                        </button>
                        <br>
                        <select #endo>
                            <!--binds to obj not individual value-->
                            <option *ngFor="let t of selectedCustomer.odataCreds.endpoints| sort:'asc':'name'"
                                [ngValue]="t.name">
                                {{t.name}}
                            </option>
                        </select>
                        <button mat-raised-button color="primary" (click)="ReloadOdataEndpoint(endo.value)"
                            [disabled]="!endo.value" style="margin: 5px;">
                            Load Odata Endpoint
                        </button>
                        <br>
                        <select #endpkg>
                            <!--binds to obj not individual value-->
                            <option *ngFor="let t of selectedCustomer.odataCreds.pkgApiEndpoints| sort:'asc':'name'"
                                [ngValue]="t.name">
                                {{t.name}} saved as: {{t.savedAs}}
                            </option>
                        </select>
                        <button mat-raised-button color="primary" [disabled]="!endpkg.value"
                            (click)="ReloadPkgApiEndpoint(endpkg.value)" style="margin: 5px;">
                            Load PkgApi Endpoint
                        </button>
                        <br>
                        <select #endcustom>
                            <!--binds to obj not individual value-->
                            <option *ngFor="let t of selectedCustomer.customApiCreds| sort:'asc':'name'"
                                [ngValue]="t.name">
                                {{t.name}}
                            </option>
                        </select>
                        <button mat-raised-button color="primary" [disabled]="!endcustom.value"
                            (click)="ReloadCustomEndpoint(endcustom.value)" style="margin: 5px;">
                            Load Custom Endpoint
                        </button>
                    </div>
                    <br>
                    <h2>Get SQL table last updated times</h2>
                    <button mat-raised-button color="primary" (click)="GetLastTableUpdateTime()" style="margin: 5px;">
                        Get SQL table last updated times
                    </button>
                    <br>
                    <button mat-raised-button color="primary" (click)="MapOrders()" style="margin: 5px;">
                        Map All Unsent Orders
                    </button>
                    <br>
                    <button mat-raised-button color="primary" (click)="MapReturns()" style="margin: 5px;">
                        Map All Unsent Returns
                    </button>
                    <br />
                    valid opt:
                    <div *ngIf="selectedCustomer.name.startsWith('Lipman')">
                        <li>FrontLinePricing: InvFileTypes.EIS3_FrontLinePricing</li>
                        <li>Deals (3&4): InvFileTypes.EIS3_Deal101v2</li>
                    </div>
                    <div *ngIf="selectedCustomer.name.startsWith('OpiciEcomm')">
                        <li>InvFileTypes.Transact, //Salesdetails (RAD files)</li>
                        <li>InvFileTypes.Customer, //Customers: ecommopiciaccounts</li>
                        <li>InvFileTypes.Detail, //Payables2: ecommopiciopenar suppliers</li>
                        <li>InvFileTypes.EdiOrderDetail, //Payables: ecommopiciopenar retailer</li>
                    </div>
                    <br />
                    These sql changes will be written from server: sa-web database:{{selectedCustomer.dbname}} TO
                    server: {{selectedCustomer.pockadvservername}} database (whatever the transform output says)
                    <br />
                    <select #endmapsql>
                        <!--binds to obj not individual value-->
                        <option *ngFor="let t of invFileTypes| sort:'asc'" [ngValue]="t">
                            {{t}}
                        </option>
                    </select>
                    <button mat-raised-button color="primary" [disabled]="!endmapsql.value"
                        (click)="mapSql(endmapsql.value)" style="margin: 5px;">
                        Map SQL
                    </button>
                    <br>
                </div>
                <!--END FAV BRANDS-->
                <!--FOR MURPHY/SA-->

                <!--Show unsent orders for customer-->
                <hr>
                <h2>Status View: {{selectedCustomer.name}}</h2>
                <label>View Orders that have NOT been sent to customer</label>
                <p>select * from PockAdv..OrdersDotText where CompanyId='{{selectedCompany.twodigitcoid}}' and
                    SentToHost!=1 and len(Future79)=0</p>
                <p></p>
                <button mat-raised-button color="primary" (click)="InvUnsentOrders()" style="margin: 5px;">
                    Load PockAdv -> GK
                </button>
                <br>
                <!--end show unsent-->
                <div *ngIf="selectedCustomer.name=='Murphy'||selectedCustomer.name=='SanAntonio'">
                    <hr>
                    <label>View Orders that have NOT been picked up (by script/Sage etc)</label>
                    <button mat-raised-button color="primary" (click)="GkUnsentOrders()" style="margin: 5px;">
                        Load GK -> Sage
                    </button>
                    <p>this might have data for murphy (picks up once a day),
                        but if it has data for SA there is a problem on bobs end</p>
                    <p>eg: select * from SanAntonio..TOMAS_SO_SalesOrderDetail or select * from
                        Murphy..vwOrdersNotInSage</p>
                    <br>
                    <hr>
                    <label>View most recent processed order headers</label>
                    <button mat-raised-button color="primary" (click)="FileHeaderArchiveLogs()" style="margin: 5px;">
                        Load processed order header Archive
                    </button>
                    <br>
                    <hr>

                    <label>View Order Writing History Logs (SalesOrderHeader & SalesOrderDetail)</label>
                    <button mat-raised-button color="primary" (click)="salesOrderGenerationLogs()" style="margin: 5px;">
                        Load SalesOrder Files
                    </button>
                    <br>

                    <label>View Nightly File Creation Logs (Item, Cust, Transact, Detail)</label>
                    <button mat-raised-button color="primary" (click)="ictdGenerationLogs()" style="margin: 5px;">
                        ICTD Creation (sa-web Logs)
                    </button>
                    <br>

                    <label>View File processing status (Item, Cust, Transact, Detail)</label>
                    <button mat-raised-button color="primary" (click)="FileProcessLogs()" style="margin: 5px;">
                        Load Processing Logs (on PA Server)
                    </button>
                    <br>
                </div>
                <hr>
                <!--
        <label>Enter text to filter on for all columns</label>
        <input placeholder="Search All Columns" #input>
        <button mat-raised-button color="primary" (click)="search(input.value)" style="margin: 5px;">
            Search
        </button>
        <button mat-raised-button color="accent" (click)="clear()" style="margin: 5px;">
            Clear
        </button>
        <br>
        <hr>
        -->
                <!--START TABLE-->
                <div *ngIf="displayResult">
                    <ul>
                        <div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <td *ngFor="let colname of dataColList; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngFor="let colname of dataColList">
                                            {{ colname }}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let s of displayResult">
                                        <td *ngFor="let idx of s">
                                            <div
                                                *ngIf="idx&&idx.length>0&&idx.includes('Z')&&idx.includes('T')&&idx.includes('2020-');else normalD;">
                                                {{ idx| date:'short'}}</div>
                                            <ng-template #normalD>{{idx}}</ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ul>
                </div>
                <!--END TABLE-->
            </div>
        </div>
    </div>