import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterContentInit,
} from "@angular/core";
import { EditTransformService } from "../edit-transform/edittransform.service";
import {
  SqlApiObject,
  IpModel,
  SqlResponseObject,
} from "@shared/apiinterfaces";
import { AuthService } from "../auth/auth.service";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { MapToDbResult } from "@shared/apiinterfaces";
import {
  Customer,
  CustDbNames,
  CustomerName,
  Company,
  PkgApiEndpoint,
  OdataEndpoint,
  CustomEndpoint,
} from "@shared/customer";
import { InvFileTypes } from "@shared/shared.models";
import { AppService } from "../app.service";

@Component({
  selector: "app-statusview",
  templateUrl: "./statusview.component.html",
  styleUrls: ["./statusview.component.css"],
})
export class StatusviewComponent implements OnInit {
  @ViewChild("input", { static: true }) input: ElementRef;

  loading$: string = "";
  private _selectedCustomer: Customer;
  public _selectedCompany: Company;

  dataColList: string[];
  lambdaip: string;

  public invFileTypes: InvFileTypes[] = Object.keys(
    InvFileTypes
  ) as InvFileTypes[];
  masterDataResult: any[][];
  displayResult: any[][]; //Filtered by select dropdown
  displayResultx: string;
  dataColListx: string;
  public disableCust = false;
  public canAccessFavBrands = false;
  public showAdvanced = false;
  public isEmployee: boolean = false;

  constructor(
    public lgsvc: AuthService,
    private esvc: EditTransformService,
    private appSvc: AppService
  ) {}
  ngOnInit() {
    //default is on for pual.weiler@inventiv.com, OTHERWISE OFF
    this.showAdvanced = [
      "paul.weiler@inventiv.com",
      "desmond.johnson@inventiv.com",
    ].includes(this.lgsvc.subUser.value.email);
    this.isEmployee = this.lgsvc.subUser.value.isEmployee;

    this.loading$ = "warming up the lambdas to check for ghosts";
    this.esvc.getLambdaIp().subscribe((x: IpModel) => {
      this.loading$ = "";
      if (x != null) this.lambdaip = x.ip + " apiversion: " + x.version;
      else
        this.lambdaip =
          "WARNING: likely a problem in your backend somewhere. This should have returned an ip and didnt";
    }); //used for testing

    this.appSvc._selectedCustomerObservable().subscribe((y: Customer) => {
      this.selectedCustomer = y;
    });
    this.appSvc._selectedCompanyObservable().subscribe((y: Company) => {
      this.selectedCompany = y;
    });
  }

  //1- View Orders that have NOT been written GK db
  InvUnsentOrders() {
    this.getSql(
      `select * from PockAdv..OrdersDotText where CompanyId='${this.selectedCompany.twodigitcoid}' and SentToHost!=1 and len(Future79)=0`
    );
  }

  async mapSql(i: InvFileTypes) {
    this.esvc.mapSql(this.selectedCustomer, this._selectedCompany, i);
    alert(
      `started mapping, wait 5 minutes then check the output table on ${this.selectedCustomer.pockadvservername} server for updated paulPulledDate`
    );
  }

  async ReloadOdataEndpoint(endpoint: string) {
    let ep: OdataEndpoint = this.selectedCustomer.odataCreds.endpoints.filter(
      (e) => e.name === endpoint
    )[0];
    if (!ep) {
      alert(
        `no endpoint: ${ep}. POSSIBLE OPTIONS WERE: ${this.selectedCustomer.odataCreds.endpoints
          .map((x) => x.name)
          .join(",")}`
      );
      return;
    }
    let curr = { isCurrent: false };
    try {
      curr = await this.isDataCurrent(ep.name);
    } catch (e) {
      console.log(`err getting table: ${e}. force pulling new data`);
    }
    if (!curr.isCurrent) {
      //data not pulled, call api
      this.esvc.loadOdataEndpoint(
        this.selectedCustomer,
        this.selectedCompany,
        ep.name
      );
      this.CheckEvery30Seconds(ep); //async function, updates loading
    } else {
      alert(`data was pulled in last 5 minutes. not pulling again`);
    }
  }

  async ReloadCustomEndpoint(endpoint: string) {
    let ep: CustomEndpoint = this.selectedCustomer.customApiCreds.filter(
      (e) => e.name === endpoint
    )[0];
    if (!ep) {
      alert(
        `no endpoint: ${ep}. POSSIBLE OPTIONS WERE: ${this.selectedCustomer.customApiCreds
          .map((x) => x.name)
          .join(",")}`
      );
      return;
    }
    let curr = { isCurrent: false };
    try {
      curr = await this.isDataCurrent(ep.name);
    } catch (e) {
      console.log(`err getting table: ${e}. force pulling new data`);
    }
    if (!curr.isCurrent) {
      //data not pulled, call api
      this.esvc.loadCustomEndpoint(
        this.selectedCustomer,
        this.selectedCompany,
        ep.name
      );
      this.CheckEvery30Seconds(ep); //async function, updates loading
    } else {
      alert(`data was pulled in last 5 minutes. not pulling again`);
    }
  }

  async ReloadPkgApiEndpoint(str: string) {
    //ep is WHAT THE USER SEES IN THE DROPDOWN eg: "License Codes saved as: INVLicense_pkgapi"
    let ep: PkgApiEndpoint =
      this.selectedCustomer.odataCreds.pkgApiEndpoints.filter(
        (e) => e.savedAs === str.split("as: ")[1]
      )[0];
    if (!ep) {
      alert(
        `no endpoint: ${ep}. POSSIBLE OPTIONS WERE: ${this.selectedCustomer.odataCreds.pkgApiEndpoints
          .map((x) => x.name)
          .join(",")}`
      );
      return;
    }

    let curr = { isCurrent: false };
    try {
      curr = await this.isDataCurrent(ep.savedAs);
    } catch (e) {
      console.log(`err getting table: ${e}. force pulling new data`);
    }
    if (!curr.isCurrent) {
      //data not pulled, call api
      this.esvc.loadPkgApiEndpoint(
        this.selectedCustomer,
        this.selectedCompany,
        ep.name
      );
      this.CheckEvery30Seconds(ep); //async function, updates loading
    } else {
      alert(`data was pulled in last 5 minutes. not pulling again`);
    }
  }

  async CheckEvery30Seconds(
    ep: OdataEndpoint | PkgApiEndpoint | CustomEndpoint
  ): Promise<boolean> {
    let sqltablename = (ep as PkgApiEndpoint).savedAs || ep.name;
    let curr = await this.isDataCurrent(sqltablename);

    //every 30 seconds check (up to 10 minutes)
    for await (let ct of [...Array(21)].map((_, i) => i)) {
      this.loading$ = `loading data into sql table ${sqltablename}. Last pull was: ${
        curr.lastPulled ? curr.lastPulled.toISOString() : "NEVER"
      }. WAITING FOR ERP TO RESPOND. waited ${
        30 * (ct + 1)
      } seconds so far....`;
      await new Promise((resolve) => setTimeout(resolve, 1000 * 30));
      try {
        curr = await this.isDataCurrent(sqltablename);
      } catch (e) {
        alert(`isDataCurrent Err: ${e}`);
      }
      if (curr.isCurrent) {
        this.loading$ = "";
        return true;
      }
    }
    alert(
      `ERR: TIMED OUT (NO DATA FROM ERP) received in 10 minutes

      Please contact your ERP provider and tell them: "At ${new Date()} this morning ${
        ep.name
      } took too long to send data to Inventiv"`
    );
    this.loading$ = "";
    return false;
  }

  //returns list of all tables and when last modified date
  async GetLastTableUpdateTime() {
    let query = `USE ${this.selectedCustomer.name} SELECT name,modify_date FROM sys.Tables order by name desc`;
    this.getSql(query);
  }

  //was it pulled in last 5 minutes?
  async isDataCurrent(
    tablename: string
  ): Promise<{ lastPulled: Date; now: Date; isCurrent: boolean }> {
    //get last modified date from SalesInvoiceV2Lines if within 5 minutes DONT PULL AGAIN
    let lastPulled: Date = null;
    //CST
    let lasttime = null;
    try {
      lasttime = await this.esvc.getLastUpdatedTime(
        this.selectedCustomer,
        tablename
      );
    } catch (e) {
      console.log(
        `err pulling last updated time of table: ${tablename}. Likely table doesnt exist.`
      );
    }

    //THIS date is in USERS timezone
    let now = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/Chicago",
      })
    );

    //rewrite sql date STRING so angular thinks UTC
    if (!lasttime)
      if (!lastPulled) {
        console.log(`isDataCurrent: data invalid`);
        return {
          lastPulled: null,
          now: now,
          isCurrent: false,
        };
      }
    //This date is in USERS timezone, and converted to CST because thats whats in SQL
    lastPulled = new Date(lasttime) || null;

    if (!lastPulled) {
      console.log(`isDataCurrent: data invalid`);
      return {
        lastPulled: null,
        now: now,
        isCurrent: false,
      };
    }

    let fiveminago = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/Chicago",
      })
    );
    fiveminago.setMinutes(fiveminago.getMinutes() - 5);

    console.log(
      `last pulled from API:\n(string CST): ${lasttime} \
      \nas Date CST: ${lastPulled.toISOString()} \
      \nnow CST: ${now.toISOString()}\
      \nfivemin ago CST: ${fiveminago.toISOString()}`
    );
    return {
      lastPulled: lastPulled,
      now: now,
      isCurrent: lastPulled > fiveminago,
    };
  }

  //FOR FB ONLY
  async PullOdataAndGenerateFile(filetype: string) {
    let endpointname = "";
    switch (filetype) {
      case InvFileTypes.TransactPDKegRet:
        endpointname = "KegShellReturnTracking";
        break;
      case InvFileTypes.TransactPDCredit:
      case InvFileTypes.TransactDO:
        endpointname = "SalesInvoiceV2Lines"; //and items??
        break;
      case InvFileTypes.TransactPDReturn:
        endpointname = "LNFReturnOrderLines";
        break;
      case InvFileTypes.TransactPDRoutes:
        endpointname = "SalesInvoiceV2Lines";
        break;
      default:
        alert(`invalid invfiletype: ${filetype}`);
        return;
    }

    let ep: OdataEndpoint = this.selectedCustomer.odataCreds.endpoints.filter(
      (e) => e.name === endpointname
    )[0];
    let curr = { isCurrent: false };

    this.loading$ = `loading ${endpointname} from D365 into SQL....`;
    try {
      curr = await this.isDataCurrent(ep.name);
    } catch (e) {
      console.log(`err getting table: ${e}. force pulling new data`);
    }
    if (!curr.isCurrent) {
      //data not pulled, call api
      this.esvc.loadOdataEndpoint(
        this.selectedCustomer,
        this.selectedCompany,
        ep.name
      );
      await this.CheckEvery30Seconds(ep); //async function, updates loading, waiting for it to be returned
      this.loading$ = `generating ${filetype} file....`;
      await this.GenerateFile(InvFileTypes[filetype]);
      alert(
        `${endpointname} loaded from SQL and ${filetype} file generated... Please allow 5 to 15 minutes for processing`
      );
    } else {
      this.loading$ =
        "generating file now without pulling data again because data was pulled in last 5 minutes....";
      alert(this.loading$);
      //pulled data within last 5 min, just generate file
      await this.GenerateFile(InvFileTypes[filetype]);
    }

    this.loading$ = "";
  }

  async GenerateFile(ftype: InvFileTypes) {
    //copied form edit transform, need to make generic method later

    this.loading$ = `writing ${ftype} to Inventiv PocketAdvantage server`;
    //generate a file from sql(eg SanAntonio Item,Cust,etc), or api (eg Murphy)
    let generatedFile = await this.esvc
      .generateFile(this.selectedCustomer, ftype, this.selectedCompany)
      .toPromise();
    this.loading$ = "";
    if (!generatedFile || generatedFile == null) {
      alert(`Err generating file ${ftype}: return was null`);
    } else if (generatedFile.err && generatedFile.err.length > 0) {
      alert(
        `ERROR generating ${ftype} for ${this.selectedCustomer}: ` +
          generatedFile.err
      );
    }
  }

  async ReloadAllOdataEndpoints() {
    let errs: string[] = [];
    let success: string[] = [];
    this.loading$ = "loading ALL odata endpoints....";
    let maxlen = this.selectedCustomer.odataCreds.endpoints.length;
    for await (let [
      idx,
      e,
    ] of this.selectedCustomer.odataCreds.endpoints.entries()) {
      if (!(await this.isDataCurrent(e.name))) {
        let oprom = await this.esvc.loadOdataEndpoint(
          this.selectedCustomer,
          this.selectedCompany,
          e.name
        );
        this.loading$ = `now working on ${idx + 1}/${maxlen} ${e.name}`;

        if (oprom.status != 200)
          errs.push(
            `${this.selectedCustomer.name} endpoint(${
              e.name
            }) result: ${JSON.stringify(oprom)}`
          );
        else success.push(e.name);
      } else errs.push(`${e.name} skipped because recently downloaded`);
    }
    if (success.length == maxlen)
      alert(`all endpoints successfully downloaded: ${success.join(",")}`);
    else {
      alert(
        `errors on endpoints: ${errs.join(
          ","
        )}. All others successfully downloaded`
      );
    }
    this.loading$ = "";
  }

  MapOrders() {
    //SanAntonio specific thing. when they select a dropdown item that starts with dbo. we map the data from PocketOrders/OrdersDotText to that table
    this.loading$ = "writing orders to customer output SQL table";
    this.esvc
      .mapOrders(this.selectedCustomer, this.selectedCompany)
      .subscribe((x: MapToDbResult) => {
        this.loading$ = "";
        if (x == null) {
          alert("ERROR: result was null for " + this.selectedCustomer);
          return;
        }
        if (x.status && x.status.length > 0) {
          alert(
            `ERROR mapping ${this.selectedCustomer.name} orders to output database: ${x.status}`
          );
          return;
        }
        console.log("mapOrderToCust: " + x.sqlwritten);
        //if (confirm(`Result of mapping was: \nstatus: ${x.status}\n\nclick "ok" to see debug output in console, or cancel to continue`)) {
        //debug error results in console
        if (x.errmsg) console.log("Mapping result: " + x.errmsg);
        alert("cust data mapped");
        //}
      });
  }
  MapReturns() {
    let doRestock = [
      CustomerName.FavoriteBrands,
      CustomerName.FavoriteBrandsTest,
      CustomerName.PaulLocalDockerFavoriteBrandsTestCustomer,
    ].includes(this._selectedCustomer.name);

    this.loading$ = `writing returns to customer output SQL table ${
      doRestock ? "and doing restock" : ""
    }`;
    this.esvc
      .mapReturns(this.selectedCustomer, this.selectedCompany, doRestock)
      .subscribe((x: MapToDbResult) => {
        this.loading$ = "";
        if (x == null) {
          alert("ERROR: result was null for " + this.selectedCustomer);
          return;
        }
        if (x.status && x.status.length > 0) {
          alert(
            `ERROR mapping ${this.selectedCustomer.name} returns to output database: ${x.status}`
          );
          return;
        }
        console.log("mapReturnToCust: " + x.sqlwritten);
        //if (confirm(`Result of mapping was: \nstatus: ${x.status}\n\nclick "ok" to see debug output in console, or cancel to continue`)) {
        //debug error results in console
        if (x.errmsg) console.log("Mapping result: " + x.errmsg);
        alert("cust data mapped");
        //}
      });
  }

  //View Orders that have NOT been picked up (by script/Sage etc)
  GkUnsentOrders() {
    switch (this.selectedCustomer.name) {
      case CustomerName.Murphy:
        this.getSql(`select * from Murphy..vwOrdersNotInSage`);
        return;
      case CustomerName.SanAntonio:
        this.getSql(`select * from SanAntonio..TOMAS_SO_SalesOrderDetail`);
        return;
      default:
        alert("Customer not set up to view unsent orders. Let Paul know");
        return;
    }
  }

  salesOrderGenerationLogs() {
    this.getSql(
      `select top(50) * from Logs..${this.selectedCustomer.dbname} where Filename like 'SalesOrder%' and Status!='102' order by TimestampUTC desc`
    );
  }

  ictdGenerationLogs() {
    this.getSql(
      `select top(50) * from Logs..${this.selectedCustomer.dbname} where Filename in ('Item','Customer','Transact','Detail') order by TimestampUTC desc`
    );
  }

  //on PA Application server
  FileProcessLogs() {
    this.getSql(
      `select top(50) * from InvInfoHub..AdvEvents ae left join InvInfoHub..EventCategories ec on ec.EventCategoryID=ae.EventCategoryID where CoId=${this.selectedCompany.twodigitcoid} order by StartDT desc`
    );
  }

  //View most recent processed order headers
  FileHeaderArchiveLogs() {
    this.getSql(
      `select top(50) * from Logs..${this.selectedCustomer.dbname}_SalesOrderHeader order by OrderDate desc, SalesOrderNo desc`
    );
  }

  search(s: string) {
    let filter = s.trim();
    this.displayResult = this.masterDataResult.filter((x) => {
      console.log(JSON.stringify(x));
      //return JSON.stringify(x).includes(filter);
    });
  }

  clear() {
    this.displayResult = this.masterDataResult;
  }

  getSql(query: string) {
    console.log(query);
    this.dataColList = [];
    this.displayResult = [];
    this.masterDataResult = [];

    //view the table the queries were written to
    this.loading$ = "getting customer data from SQL/API";
    this.esvc
      .getCustSQL(this.selectedCustomer.name, query, this.selectedCompany, true)
      .subscribe((x: SqlApiObject) => {
        //console.log("cust sql x: " + JSON.stringify(x));
        this.loading$ = "";
        if (!x) {
          alert("Err getting data. check console log for more info");
          return;
        }
        if (x.err.length > 0) {
          alert("ERROR getting cust data: " + x.err);
        }
        if (x.data.length <= 0) {
          alert("no data in table");
          return;
        }
        this.dataColList = Object.keys(x.data[0]);
        //console.log('cols: '+this.transColList)
        this.masterDataResult = x.data.map((y) => Object.values(y)); //array of arrays where each inner array is a row of sql data
        this.displayResult = this.masterDataResult;
        //console.log('trans result: '+this.transResult)
      });
  }

  get selectedCompany() {
    return this._selectedCompany;
  }
  set selectedCompany(c: Company) {
    this._selectedCompany = c;
  }
  get selectedCustomer() {
    return this._selectedCustomer;
  }
  set selectedCustomer(c: Customer) {
    this._selectedCustomer = c;
    this.disableCust = true;
  }
}
