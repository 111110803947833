<div>
    <label>Select Customer to Edit</label>
    <p></p>
    <select [(ngModel)]="selectedCustomer" [disabled]="disableCust">
        <!--binds to obj not individual value-->
        <option *ngFor="let t of this.lgsvc.subUser.value.allowedCust| sort:'asc':'name'" [ngValue]="t">{{t.name}}-
            {{t.pockadvservername}}</option>
    </select>
    <button mat-raised-button color="primary" style="margin-bottom: 5px;"
        (click)="selectedCustomer=null;selectedCompany=null;disableCust=false;">
        Clear selected customer
    </button>
    <button mat-raised-button color="primary" style="margin-bottom: 5px;" (click)="refreshCustomers()">
        refresh customers
    </button>
    <!------set company-------->
    <div *ngIf="selectedCustomer">
        <select [(ngModel)]="selectedCompany">
            <option *ngFor="let t of selectedCustomer.companies| sort:'asc':'name'" [ngValue]="t">{{t.name}}-
                {{t.awscoid}} ({{t.twodigitcoid}})</option>
        </select>
    </div>
    <!------end company-------->
</div>