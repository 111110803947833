<div *ngIf="type=='SQL'">
        <label>Select input (Wholesaler) field name</label>
        <select [(ngModel)]="infield">
                <option>Select a column</option>
                <option *ngFor="let t of custcols" [value]="t.val">{{t.val}}</option>
        </select>
</div>
<div *ngIf="type=='PDF'">
        <textarea [(ngModel)]="firstPageLines" style="width:90%" rows="10"></textarea>

</div>
<p>
<h5>Then paste the value wherever you want in to textbox below</h5>
<label>enter transform: {{filcol}}=</label>
<textarea [(ngModel)]="strtrans" style="width:90%"></textarea>
<label>enter notes (optional)</label>
<textarea [(ngModel)]="notes" style="width:90%"></textarea>

<button mat-raised-button color="primary" (click)="firstline=runFirstLine();">Test Transform</button>
<br>
<textarea *ngIf="firstline&&firstline.length>0" rows="10" [(ngModel)]="firstline" style="width:90%"></textarea>
<br>
mapping from: {{inputmappingname}} TO {{outputmappingname}}
<br>
<button mat-raised-button color="primary" (click)="submitTransform()">Update Transform</button>