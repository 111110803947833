<!DOCTYPE html>
<html lang="en">

<head>
  <title>Data-Link</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
</head>

<body>

  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="/home">DataLink {{version}}</a>
      </div>
      <ul>
        <div class="column" *ngIf="isEmployee">
          <div class="row">Run API on:</div>
          <div class="row">
            <select [(ngModel)]="modeUpdated">
              <option value="live">us-east-1 (PROD)</option>
              <option value="test">us-east-2 (TEST)</option>
            </select>
          </div>
        </div>
      </ul>
      <ul><a routerLink="/transform">Transforms</a></ul>
      <ul><a routerLink="/status">Status</a></ul>
      <ul style="text-align:right" class="nav navbar-nav">
        <li>Welcome {{name}}
          <amplify-sign-out></amplify-sign-out>
        </li>
      </ul>
      <!--cust: {{selectedCustomer.name}}-->
    </div>
  </nav>

</body>

</html>