import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { LoggedInUser } from "@shared/apiinterfaces";
import { AuthService } from "./auth.service";

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let expectedRole = route.data.role;
    let userStatus: LoggedInUser = this.auth.subUser.value;

    if (!userStatus) {
      await this.auth.setClaimsFromToken(); //set token
      userStatus = this.auth.subUser.value; //updated saved value
    }
    if (!userStatus) {
      alert(`invalid user`);
      //this.auth.logout();
      return false;
    }
    switch (expectedRole) {
      case "All":
        if (!userStatus.isLoggedIn)
          alert("Sorry you must be logged in to access that page.");
        return userStatus.isLoggedIn;
      case "Employee":
        if (!userStatus.isEmployee)
          alert("Sorry you must be an employee to go there.");
        return userStatus.isEmployee;
      default:
        alert(
          "Unexpected role: " +
            expectedRole +
            " please contact an Inventiv Support person and tell them what you were trying to do"
        );
    }

    return false;
  }
}
