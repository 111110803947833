import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EditTransformComponent } from "./edit-transform/edit-transform.component";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { StatusviewComponent } from "./statusview/statusview.component";
import { RoleGuardService } from "./auth/RoleGuardService";

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: "app", component: AppComponent },
      { path: "home", component: HomeComponent },
      {
        path: "transform",
        component: EditTransformComponent,
        canActivate: [RoleGuardService],
        data: { role: "Employee" },
      },
      {
        path: "status",
        component: StatusviewComponent,
        canActivate: [RoleGuardService],
        data: { role: "All" },
      },
      { path: "", redirectTo: "/home", pathMatch: "full" }, //default route
      { path: "*", redirectTo: "/home" }, //wildcard. if unexpected path
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
