import { Injectable } from "@angular/core";
import { Company, Customer } from "@shared/customer";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppService {
  //auth service is accessed through here in eah of the other classes
  //constructor(private http: HttpClient, public auth: AuthService) { }

  public _selectedEnvIsLiveSub = new BehaviorSubject<boolean>(true);
  public _selectedCustomerSub = new BehaviorSubject<Customer>(null);
  public _selectedCompanySub = new BehaviorSubject<Company>(null);
  public _customerListSub = new BehaviorSubject<Customer[]>([]);

  public _selectedEnvIsLiveObservable() {
    //console.log('svc pushed observable') //happens once
    return this._selectedEnvIsLiveSub.asObservable();
  }

  //create Observable so other classes can watch for changes
  public _selectedCustomerObservable() {
    return this._selectedCustomerSub.asObservable();
  }
  public _selectedCompanyObservable() {
    return this._selectedCompanySub.asObservable();
  }
  public _customerListObservable() {
    return this._customerListSub.asObservable();
  }

  //how to watch for changes:
  /*
        this._selectedCustomer = this.appSvc
          ._selectedCustomerObservable()
          .subscribe((c: Customer) => {
            this.cuustomer=c;
          });
  */

  public setCustomer(c: Customer) {
    this._selectedCustomerSub.next(c);
  }
  public setLg(lt: "live" | "test") {
    this._selectedEnvIsLiveSub.next(lt == "live" ? true : false);
  }
  public setCompany(c: Company) {
    this._selectedCompanySub.next(c);
  }
}
