import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable, Inject } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { InvFileSpec, InvFileTypes } from "@shared/shared.models";
import {
  SqlApiObject,
  GenerateFileResult,
  MapToDbResult,
  IpModel,
  Page,
  SqlResponseObject,
} from "@shared/apiinterfaces";
import { Company, Customer, CustomerName } from "@shared/customer";

@Injectable({
  providedIn: "root",
})
export class EditTransformService {
  constructor(private httpClient: HttpClient) {}

  //of a SPECIFIC sql table
  getLastUpdatedTime = async (
    selectedCustomer: Customer,
    tablename: string
  ): Promise<string | null> => {
    let query = `SELECT top(1) paulPulledDateCST FROM ${selectedCustomer.dbname}..${tablename}`;
    console.log("getLastUpdatedTime: ", query);
    try {
      let resp = await this.httpClient
        .post<SqlApiObject>(
          "rdsquery",
          JSON.stringify({
            custname: selectedCustomer.name,
            table: query,
            awscoid: selectedCustomer.companies[0].awscoid,
          })
        )
        .pipe(
          catchError((e) =>
            this.handleError<SqlApiObject>(
              e,
              "rdsquery-sigle endpoint update times",
              []
            )
          )
        )
        .toPromise();
      return resp.data[0]["paulPulledDateCST"] as string;
    } catch (e) {
      //alert(`Err getting last update time. Re-pulling files`);
      return null; //force pull again
    }
  };

  mapSql = async (
    c: Customer,
    co: Company,
    i: InvFileTypes
  ): Promise<string | null> => {
    console.log("mapSql: ", c.name, co.awscoid, i);
    try {
      let resp = await this.httpClient
        .post<SqlApiObject>(
          "mapSql",
          JSON.stringify({
            custname: c.name,
            dealtype: i,
            awscoid: co.awscoid,
          })
        )
        .pipe(
          catchError((e) => this.handleError<SqlApiObject>(e, "mapSql", []))
        )
        .toPromise();
      return "";
    } catch (e) {
      //alert(`Err getting last update time. Re-pulling files`);
      return null; //force pull again
    }
  };

  //of sql tables
  public GetAllUpdatedTime(selectedCustomer: Customer) {
    let query = `USE ${selectedCustomer.name} SELECT name,modify_date FROM sys.Tables order by modify_date desc`;
    console.log(query);
    return this.httpClient
      .post<SqlApiObject>(
        "rdsquery",
        JSON.stringify({
          custname: selectedCustomer.name,
          table: query,
          awscoid: selectedCustomer.companies[0].awscoid,
        })
      )
      .pipe(
        catchError((e) =>
          this.handleError<SqlApiObject>(e, "rdsquery-ALLupdated times", [])
        )
      )
      .toPromise();
  }

  public loadOdataEndpoint(
    selectedCustomer: Customer,
    co: Company,
    selectedEndpoint: string
  ): Promise<SqlResponseObject> {
    return this.httpClient
      .post<SqlResponseObject>(
        "pullD365Data",
        JSON.stringify({
          custname: selectedCustomer.name,
          endpoint: selectedEndpoint,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        catchError((e) =>
          this.handleError<SqlResponseObject>(e, "pullD365Data", [])
        )
      )
      .toPromise();
  }
  public loadPkgApiEndpoint(
    selectedCustomer: Customer,
    co: Company,
    selectedEndpointName: string
    //`{"custname":"${cust.name}","pkgApiEndpointName":"${endpoint.name}","awscoid":"00"}`,
  ): Promise<SqlResponseObject> {
    let o = {
      custname: selectedCustomer.name,
      endpoint: selectedEndpointName,
      awscoid: "00",
    };
    console.log("pkg api: " + JSON.stringify(o));
    return this.httpClient
      .post<SqlResponseObject>("pullPkgApi", JSON.stringify(o))
      .pipe(
        catchError((e) =>
          this.handleError<SqlResponseObject>(e, "pullPkgApi", [])
        )
      )
      .toPromise();
  }
  public loadCustomEndpoint(
    selectedCustomer: Customer,
    co: Company,
    selectedEndpoint: string
  ): Promise<SqlResponseObject> {
    return this.httpClient
      .post<SqlResponseObject>(
        "pullCustomApi",
        JSON.stringify({
          custname: selectedCustomer.name,
          endpoint: selectedEndpoint,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        catchError((e) =>
          this.handleError<SqlResponseObject>(e, "pullCustomApi", [])
        )
      )
      .toPromise();
  }

  public getSqlCols(
    tablename: string,
    co: Company,
    custname: string
  ): Observable<InvFileSpec> {
    if (tablename.includes("select"))
      throw "this should be just the table name, not a query";

    return this.httpClient
      .post<InvFileSpec>(
        "getSqlCols",
        JSON.stringify({ tablename, custname, awscoid: co.awscoid })
      )
      .pipe(
        //map(x=>{console.log(x);return x;}),
        catchError((e) => this.handleError<InvFileSpec>(e, "getSqlCols", []))
      );
    //return of("{\"transforms\": [\"cust.first_res=cust['first'].toString().toLowerCase()\"]}")
  }

  //used for emergency overwrites
  public getTransformString(c: Customer, co: Company): Promise<SqlApiObject> {
    console.log(`get str trans: ${c.name} ${co.awscoid}`);
    return this.httpClient
      .post<SqlApiObject>(
        "rdsquery",
        JSON.stringify({
          custname: CustomerName.Admin,
          table: `select top(1) * from ICTD..Transform_v2 where CustomerName='${c.name}' and AwsCoId=${co.awscoid} order by DateSaved desc`,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        //map(x=>{console.log(x);return x;}),
        catchError((e) =>
          this.handleError<SqlApiObject>(e, "getTransformString", "")
        )
      )
      .toPromise();
  }

  //credentials: eg "SanAntonio"
  //tablequery: "select * from ...."
  //return is array of sql rows [{"SalesOrderId":"x",....},{},....]
  //for credentials=Murphy: tablequery is: select top(1) * from ...

  public getCustSQL(
    custname,
    tablequery,
    co: Company,
    ignoreSelectCheck = false
  ): Observable<SqlApiObject> {
    /*
    console.log(
      "sql svc cust: " +
        custname +
        " table: " +
        tablequery.substring(0, 100) +
        "... awscoid: " +
        co.awscoid
    );
    */

    //call rdsquery to get sql direct----------------------------------------------------------------
    if (
      !ignoreSelectCheck &&
      (!tablequery || !tablequery.toLowerCase().startsWith("select"))
    ) {
      alert("cant get cust sql. no valid sql query: " + tablequery);
      return;
    }
    if (!ignoreSelectCheck && (!co || co.awscoid <= 0)) {
      alert("cant get cust sql. no valid co: " + JSON.stringify(co));
      return;
    }

    //call GK or Inventiv Sql
    return this.httpClient
      .post<SqlApiObject>(
        "rdsquery",
        JSON.stringify({
          custname: custname,
          table: tablequery,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        catchError((e) =>
          this.handleError<SqlApiObject>(e, "rdsquery-getCustSQL: ", {
            data: [],
            err: e["err"] + " see console for more info",
            version: "Warning err getting sql",
          })
        )
      );
  }

  //filename: dbo.TOMAS_SO_SalesOrderHeader
  //Customer: SanAntonio
  public generateFile(
    c: Customer,
    filename: InvFileTypes,
    co: Company
  ): Observable<GenerateFileResult> {
    console.log("calling generate transform for file: " + filename);
    if (!InvFileTypes[filename]) {
      alert("invalid filetype. not generating file: " + filename);
      return;
    }
    if (
      [
        InvFileTypes.Null,
        InvFileTypes.SalesOrderHeader,
        InvFileTypes.SalesOrderDetail,
      ].includes(InvFileTypes[filename])
    ) {
      alert(
        "cannot generate file for salesorder/null. should map instead: " +
          filename
      );
      return;
    }
    let opts = {
      filename: filename,
      custname: c.name,
      generateBlank: false,
      awscoid: co.awscoid,
    };
    console.log("generateFile opts: " + JSON.stringify(opts));
    return this.httpClient
      .post<GenerateFileResult>("generateFile", JSON.stringify(opts))
      .pipe(
        catchError((e) =>
          this.handleError<GenerateFileResult>(e, "generateFile", null)
        )
      );
  }

  public mapReturns(
    c: Customer,
    co: Company,
    doRestock: boolean
  ): Observable<MapToDbResult> {
    console.log("calling mapReturns for cust: " + c.name);

    return this.httpClient
      .post<MapToDbResult>(
        "mapReturn",
        JSON.stringify({
          custname: c.name,
          awscoid: co.awscoid,
          doRestock: doRestock,
        })
      )
      .pipe(
        catchError((e) => this.handleError<MapToDbResult>(e, "mapReturn", null))
      );
  }

  public mapOrders(c: Customer, co: Company): Observable<MapToDbResult> {
    console.log("calling mapOrder for cust: " + c.name);

    return this.httpClient
      .post<MapToDbResult>(
        "mapOrder",
        JSON.stringify({
          custname: c.name,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        catchError((e) => this.handleError<MapToDbResult>(e, "mapOrder", null))
      );
  }

  public getLambdaIp(): Observable<IpModel> {
    return this.httpClient
      .post<IpModel>("lambdaip", JSON.stringify({}))
      .pipe(catchError((e) => this.handleError<IpModel>(e, "lambdaip", null)));
  }

  public async checkSafeBeforeUpdatingTransform(
    cust: Customer,
    co: Company,
    LastUser: string,
    DateSaved: string
  ): Promise<boolean> {
    //check to make sure no one else has made changes
    let qres: SqlApiObject = await this.getTransformString(cust, co);
    //console.log(`got qres: ${JSON.stringify(qres.data[0])}`);
    let tr = JSON.parse(JSON.stringify(qres.data[0]));
    if (tr.LastUser != LastUser) {
      if (
        !confirm(
          `Looks like user: ${tr.LastUser} has made changes at: ${tr.DateSaved} for company ${co.awscoid} you last saved at ${DateSaved}. Do you want to overwrite their changes?`
        )
      )
        return false;
    }

    return true;
  }

  //writes file to sql
  public async writeTransformToSQL(
    data: string,
    co: Company
  ): Promise<boolean> {
    let p = this.httpClient
      .post<SqlApiObject>(
        "rdsquery",
        JSON.stringify({
          custname: CustomerName.Admin,
          table: data,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        map((x) => {
          return x.err.length <= 0;
        }),
        catchError((e) => {
          console.log(`query ERR: ${data}`);
          return this.handleError<boolean>(e, "rdsquery-writetransform", false);
        })
      )
      .toPromise();
    return await p;
  }

  //writes file to s3- Used for compatability with Murphy/SA
  /*
  public writeTransformToS3(
    cust: Customer,
    data: string,
    co: Company
  ): Observable<boolean> {
    if (
      !(
        cust.name == CustomerName.Murphy || cust.name == CustomerName.SanAntonio
      )
    ) {
      alert(`only SA/Murphy should need to write to S3`);
      return of(false);
    }
    let kk = "transforms/san_antonio.json";
    if (cust.name == CustomerName.Murphy) kk = "transforms/murphy.json";

    let mainwrite = this.httpClient
      .post<boolean>(
        "writeTransform",
        JSON.stringify({
          Key: kk,
          Bucket: "gk-website",
          Data: data,
          awscoid: co.awscoid,
        })
      )
      .pipe(
        catchError((e) => this.handleError<boolean>(e, "writeTransform", false))
      );
    return mainwrite;
  }
  */

  public handleError<T>(error, methodname, emptyobj): Observable<T> {
    let errstr = `Err occurred in method: ${methodname}\n`;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errstr += error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errstr += `Backend returned code ${
        error.status
      }\nresponse body was: ${JSON.stringify(error)}`;
    }
    /*
    Err occurred in method: rdsquery
Backend returned code 500
response body was: {"headers":{"normalizedNames":{},"lazyUpdate":null},
"status":500,"statusText":"OK",
"url":"https://e5183ieny1.execute-api.us-east-1.amazonaws.com/alpha/api/rdsquery",
"ok":false,"name":"HttpErrorResponse",
"message":"Http failure response for https://e5183ieny1.execute-api.us-east-1.amazonaws.com/alpha/api/rdsquery: 500 OK",
"error":{"data":[],"err":"rdsquery() bott: localrdsquery() global: creds: sauser:sa-web-db.cpiiu9qemkvf.us-east-1.rds.amazonaws.com:SanAntonio error : query ERR:RequestError: Incorrect syntax near the keyword 'select'. querystring: select top(5000) * from select * from tomas_so_salesorderheader db=gk","version":"2.7.0"}}
    */
    console.log(errstr);

    if (error.status == "0") {
      //cast timeout lambda err FOR THESSE TWO FUNCTIONS TO A SPECIFIC ERROR OBJECT
      if (methodname == "generateFile") {
        return of({
          data: "",
          twodigitcoid: "",
          filename: "",
          s3path: "",
          err: "This took longer than 30 seconds to generate. Wait another 30 seconds, thenm press OK on this dialog and try downloading the file using the .bat on the server",
          rowsInFile: 0,
        } as GenerateFileResult as unknown as T);
      }
      if ("mapOrder" == methodname) {
        return of({
          status: "timed out, wait then check sql to see if updated",
          position: "",
          params: "",
          sqlpretransform: [],
          sqlwritten: [],
          transforms: [],
          errmsg: errstr,
        } as MapToDbResult as unknown as T);
      }
      //on status page
      if (methodname == "pullD365Data" || methodname == "pullPkgApi") {
        return of({
          status: "TIMED OUT...wait, then check sql to see if updated",
          position: "",
          params: "",
          sqlpretransform: [],
          sqlwritten: [],
          transforms: [],
          errmsg: errstr,
        } as MapToDbResult as unknown as T);
      }
    }
    // alert(
    //   "return value was invalid for: " +
    //     methodname +
    //     "\nCopy and paste the error from the console (press f12) to send to Paul. Heres the important info:\n" +
    //     error.message +
    //     "\n---\n" +
    //     JSON.stringify(error.error)
    //);
    return of(emptyobj as T);
  }

  //---------------------------------------------------PDF
  //see Pdf class for structure of object. each object is a seperate PAGE
  public getPDFLines(filename: string): Observable<object[]> {
    console.log("calling getPDFLines for file: " + filename);
    return this.httpClient
      .post<object[]>("pdfParse", JSON.stringify({ filename }))
      .pipe(
        catchError((e) => this.handleError<object[]>(e, "getPDFLines", null))
      );
  }
  //---------------------------------------------------PDF
}
