import { CustomerName } from "./customer";

//CLOG HELPERS
export var lambdaOrEc2: "lambda" | "ec2" = "lambda"; //set by sqlRunSchedule
export function setLambdaOrEc2(l: "lambda" | "ec2") {
  lambdaOrEc2 = l;
}

//CLOG HELPERS
// export var lambdaMethodName: string = ""; //set by sqlRunSchedule
// export function setLambdaMethodName(l: string) {
//   lambdaMethodName = l;
// }

// export var custname: CustomerName; //set by sqlRunSchedule
// export function setCustomerName(l: CustomerName) {
//   custname = l;
// }
function formatDate(date: any) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    h = d.getHours();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

//enable/disable log
export var clog = function (...s: any) {
  console.log(...s); //info so wont get swept up in console.log replacements
  /*
  try {
    if (lambdaOrEc2 == "lambda") {
      console.info(...s); //info so wont get swept up in console.log replacements
    } else {
      try {
        const fs = require("fs"); //FOR EC@ NOT LAMBDA

        //EC2: write to file
        fs.appendFileSync(
          `./logs/${custname}/${lambdaMethodName}-${formatDate(
            new Date()
          )}.txt`,
          [...s, "\n"]
        );
      } catch (e) {
        console.info(`ERR WRITING VIA FS: ${e}`);
        console.info(...s); //info so wont get swept up in console.log replacements
      }
    }
  } catch (e) {
    console.log(`CLOG ERR: ${e}`);
  }
  */
};
