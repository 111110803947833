import {
  Component,
  OnInit,
  Inject,
  ɵɵtrustConstantResourceUrl,
} from "@angular/core";
import { EditTransformService } from "./edittransform.service";
import {
  faCoffee,
  faSkull,
  faCheck,
  faCheckCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "./editdialog/editdialog.component";
import {
  TransformFile,
  DialogResponse,
  DialogSend,
  TransformPartsUI,
} from "@shared/ui.models";
import {
  TransformParts,
  InvFileSpec,
  InvFileSpecCol,
  Transform,
  InvFileTypes,
} from "@shared/shared.models";
import {
  MapToDbResult,
  GenerateFileResult,
  SqlApiObject,
  IpModel,
} from "@shared/apiinterfaces";
import { parseTableName, getLongFilename } from "@shared/helper"; //Required for html
import { AppService } from "../app.service";
import { DOCUMENT } from "@angular/common";
import { Company, Customer, CustomerName } from "@shared/customer";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-edit-transform",
  templateUrl: "./edit-transform.component.html",
  styleUrls: ["./edit-transform.component.css"],
})
export class EditTransformComponent implements OnInit {
  private _tFile: TransformFile;
  public isModTr = false;
  //FOR HTML
  public tFileKeys: string[];
  public enumlistui = Object.keys(InvFileTypes).sort(function (a, b) {
    return a.toUpperCase() < b.toUpperCase()
      ? -1
      : a.toUpperCase() > b.toUpperCase()
      ? 1
      : 0;
  });
  //end FOR HTML
  public selectedCustomer: Customer;
  public selectedCompany: Company;
  public selectedCustomerCopyTo: Customer;
  public selectedCompanyCopyTo: Company;
  public loading$ = "";

  public strtransformfile = ""; //used for early mods before file is loaded and parsed
  public lambdaip = "";
  public isDirty = false;

  //set in step 2 to help ensure i dont overwrite data when multiple people use the site
  public LastUser = "";
  public DateSaved = "";

  public _selectedInvFile: TransformPartsUI; //{name:"Item",transforms:[],starttable:"",collist:[]}

  //transform params
  intable: string = "tablecol";
  outtable: string = "fil";

  //set by generateTransform()
  public transResult: string[][]; //every 10th row
  allData: GenerateFileResult | MapToDbResult; //all generated output (not just every 10th row)
  transColList: string[]; //list of columns to display
  public disableCust: boolean = false;

  //CONFIRM UNSAVED CHANGES
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return !this.isDirty;
  }

  constructor(
    private esvc: EditTransformService,
    private library: FaIconLibrary,
    public lgsvc: AuthService,
    private dialog: MatDialog,
    private appSvc: AppService,
    @Inject(DOCUMENT) public document: Document
  ) {
    library.addIcons(faSkull, faEdit);
  }

  async ngOnInit() {
    console.log(`ngOnInit started`);
    this.appSvc._selectedEnvIsLiveObservable().subscribe((y: boolean) => {
      console.log("edit detected env change: " + y);
    });
    this.appSvc._selectedCustomerObservable().subscribe((y: Customer) => {
      this.selectedCustomer = y;
    });
    this.appSvc._selectedCompanyObservable().subscribe((y: Company) => {
      this.selectedCompany = y;

      this.resetVars(); //clears vars on page
      if (this.selectedCompany) {
        console.log(`COCO:`, this.selectedCompany);
        this.loading$ =
          "loading and parsing transform file for customer: " +
          (!y ? "" : y.name);
        this.loadTransform();
      }
    });
    //warm the rdsquery lambda by forcing it to look up random cust on start
    this.loading$ = "warming up the lambdas to check for ghosts";
    try {
      let cc = Customer.CustomerListOLD.filter(
        (n) => n.name == CustomerName.FavoriteBrandsTest
      )[0];
      let x = await this.esvc.getTransformString(cc, cc.companies[0]);
      console.log(`xxl:`, x);
      if (x.err || !x) throw x.err || `paul1: invalid rdsquery reply`;
      this.loading$ = `lambda warmup rdsquery done, now checking for datalink version and outside internet access`;
    } catch (e) {
      console.log(`lambda warmup failed!!`);
      this.loading$ = `lambda warmup failed: ${e}`;
      return;
    }

    //used for testing
    this.esvc.getLambdaIp().subscribe((x: IpModel) => {
      this.loading$ = "";
      if (x != null) this.lambdaip = x.ip + " apiversion: " + x.version;
      else {
        this.lambdaip =
          "WARNING: Either a problem in the API backend, or your login token is invalid. Try logging out and back in.";
      }
    });
  }

  get fileType() {
    return this._selectedInvFile.filetype;
  }

  set fileType(s: string) {
    this._selectedInvFile.filetype = InvFileTypes[s];
    console.log("filetype:" + s + " " + this._selectedInvFile.filetype);
  }

  get selectedInvFile(): TransformPartsUI {
    return this._selectedInvFile;
  }

  //used in html to get file list
  get tFile() {
    return this._tFile;
  }

  async copyCustTo() {
    if (
      confirm(
        `copying ${this.selectedCustomer.name}(${this.selectedCompany.awscoid}) TO ${this.selectedCustomerCopyTo.name}(${this.selectedCompanyCopyTo.awscoid})`
      )
    ) {
      let objtowrite = this._tFile.toString();

      JSON.parse(objtowrite); //throws err if invalid.DELIBERATE

      let sqlinsert = this.TransformToSQLSave(
        this._tFile,
        this.selectedCustomerCopyTo,
        this.lgsvc.subUser.value.email,
        this.selectedCompanyCopyTo
      );
      let sqlres = await this.esvc.writeTransformToSQL(
        sqlinsert,
        this.selectedCompanyCopyTo
      );
      if (sqlres) alert(`copy success`);
      else alert(`copy failed`);
    }
  }

  parseTableName(s: string) {
    return parseTableName(s);
  }

  //2
  async loadTransform() {
    this.loading$ = `loading transform file for customer: ${this.selectedCustomer.name} with co: ${this.selectedCompany.awscoid}`;
    console.log(this.loading$);
    try {
      //updated to get transform property, not the whole row: CustomerName,Transform,DateSaved,LastUser
      let qres: SqlApiObject = await this.esvc.getTransformString(
        this.selectedCustomer,
        this.selectedCompany
      );

      //console.log(`got qres: ${JSON.stringify(qres.data[0])}`);
      let tv2 = JSON.parse(JSON.stringify(qres.data[0]));
      this.strtransformfile = tv2.Transform;
      this.LastUser = tv2.LastUser;
      this.DateSaved = tv2.DateSaved;
      this._tFile = new TransformFile(this.strtransformfile);
      this.tFileKeys = Object.keys(this.tFile)
        .filter((x) => x.toLowerCase() != "stringval")
        .sort(function (a, b) {
          return a.toUpperCase() < b.toUpperCase()
            ? -1
            : a.toUpperCase() > b.toUpperCase()
            ? 1
            : 0;
        });
    } catch (e) {
      //only paul is allowed to create a new transform.... this is far more likely to be an issue somewhere than
      //a genuine need to create a transform
      let isPaul = this.lgsvc.subUser.value.email == "paul.weiler@inventiv.com";
      if (
        confirm(
          `Cant find transform in SQL for customer: ${
            this.selectedCustomer.name
          } ${
            isPaul
              ? "Do you want to create a new transform in SQL for this customer?"
              : ""
          } \n\nerror: ${JSON.stringify(e)}`
        ) &&
        isPaul
      ) {
        try {
          this.loadEmptyTransformFile(); //sets _tfile and strtransform as empty
          this.updateTransform("overwrite"); //when overwriting uses this.strtransform, and reloads the page
        } catch (ex) {
          alert(`err loading new transform: ${ex}`);
        }
      } else {
        alert(
          `contact Paul to create a new transform for the customer. Or try reloading the page if you know a transform exists- it may be another error`
        );
        this.loading$ = ``; //chose not to create new customer
        return;
      }
    }
    this.loading$ = "";
  }

  //3
  //when selected file eg Detail is changed. parse to TransformUI
  set parseInvFile(tpartname: string) {
    console.log("tpart: " + tpartname);
    if (tpartname == null || tpartname.length <= 0) return;
    this.resetVars();
    this.loading$ =
      "parsing transform for customer: " + this.selectedCustomer.name;
    this.parseTransform(tpartname); // initializes parsed transform as "selectedInvFile"
  }
  get parseInvFile() {
    if (this._selectedInvFile && this._selectedInvFile.name.length > 0)
      return this._selectedInvFile.name;
    else return "";
  }

  //4
  //parse selected transform and add UI cols from there
  async parseTransform(tpartsname: string) {
    try {
      let trex = this._tFile[tpartsname] as TransformParts;
      console.log("trex: " + Object.keys(trex));
      let res = new TransformParts(tpartsname, {
        customer: this.selectedCustomer,
        name: tpartsname,
        inputtablename: trex.inputtablename,
        outputtablename: trex.outputtablename,
        transforms: trex.transforms,
        filetype: trex.filetype,
      });

      try {
        //actually cast to obj
        //set outputtable default if needed
        if (!res.outputtablename || res.outputtablename.length <= 0)
          res.outputtablename = tpartsname; //set the default output table to the name of the JSON object eg Customer
        try {
          if (this.selectedCustomer.name.toLowerCase() == "convoy") {
            this.loading$ = `adding pdf cols for UI from: ${this.selectedCustomer.pockadvservername}`;
            this._selectedInvFile = await this.addPdfColsForUI(res);
          } else {
            this.loading$ = `adding cols for UI from: ${this.selectedCustomer.pockadvservername}`;
            this._selectedInvFile = await this.addSqlColsForUI(res);
          }
        } catch (e) {
          throw "err addSqlColsForUI: " + e;
        }
      } catch (e) {
        alert(
          `Err parsing transform into TransformParts: ${e} \nkey: ${tpartsname}. Running in non-UI mode.\n\nFix the In/Out SQL tables before doing anything else.`
        );
        this._selectedInvFile = new TransformPartsUI(
          res,
          null,
          null,
          null,
          null
        );
      }
      this.loading$ = "";
      return;
    } catch (e) {
      this.loading$ = "";
      alert("error loading transform:" + e);
    }
  }

  //when the customer or the file was changed. this is called BEFORE the new customer or file is set
  resetVars() {
    this._selectedInvFile = null;
    this.transResult = null;
  }

  //select val in add transform dialog, then transform it and add it to array of transforms
  outfield(s) {
    let command = "";
    let obj: InvFileSpecCol = this.selectedInvFile.outputCol.filter(
      (x) => x.val == s
    )[0];
    let col = obj.val;
    let cast = obj.type;

    if (cast == "int")
      command = command.concat(`${this.outtable}.${col}=parseInt()|| 0`);
    //parseInt(fil.Name=)
    else if (cast.startsWith("slice"))
      command = command.concat(`${this.outtable}.${col}=.${cast}`);
    //tablecol["Name"].slice(10)
    else if (cast.startsWith("hard")) {
      let h = cast.split("hard=")[0];
      command = command.concat(`${this.outtable}.${col}=${h}`); //tablecol["Name"]="Y"
    } else command = command.concat(`${this.outtable}.${col}=${cast}`);

    //remove item from select list
    //this.selectedInvFile.collist = this.selectedInvFile.collist.filter(x => !(x.val == s));
    this.selectedInvFile.transforms.push({
      val: command,
      note: "",
    } as Transform);
    this.updateTransform("");
  }

  //called by html, adds file to the transform and reloads the "file to edit" dropdown
  addFile(fname: string, filetype: string, inputsql: string, outputsql) {
    try {
      if (!InvFileTypes[filetype]) throw "must select a filetype";
      let ret = new TransformParts(fname, {
        customer: this.selectedCustomer,
        inputtablename: inputsql,
        outputtablename:
          outputsql && outputsql.length > 0 ? outputsql : "no table specified",
        transforms: [],
        filetype: InvFileTypes[filetype],
      });
      this.updateTransform("reload", ret);
      alert("transform added. reload page to see changes");
    } catch (e) {
      alert(e);
    }
  }

  loadEmptyTransformFile() {
    try {
      this._tFile = new TransformFile("{}");
      console.log(`h1`);
      for (let tname of ["Customer", "Item", "Transact"]) {
        this._tFile[tname] = new TransformParts(tname, {
          customer: this.selectedCustomer,
          inputtablename: tname,
          outputtablename: "no table specified",
          transforms: [],
          filetype: tname as InvFileTypes,
        });
      }
      console.log(`h2`);
      //set string representation to empty as well
      this.strtransformfile = this._tFile.toString();
    } catch (e) {
      alert(`unable to create blank transform: ${e}`);
    }
  }

  //filename=this.selectedInvFile.name
  //showSql is true when select specific file and display. false when generate all files
  async generateFile(showSql = true, filename?: string) {
    this.transColList = [];
    this.transResult = [];
    //if there is no filename param sent (ie not generating all files) then use the current one
    if (filename == null) {
      filename = this._selectedInvFile.filetype;
      console.log("generating currently selected file in UI: " + filename);
    }
    let filetype: InvFileTypes = InvFileTypes[filename];
    if (!filetype) {
      alert("File Type not valid: " + filename);
      throw "filetype not valid";
    }
    //id cust,Item,Detail,etc
    let isInventivOutput =
      ["Item", "Customer", "Transact", "Detail"].filter((f) =>
        filename.startsWith(f)
      ).length > 0;

    if (!isInventivOutput) {
      alert(`handle ${filename} on the status page, not here`);
      return;
    }

    this.loading$ = `writing ${filename} to Inventiv PocketAdvantage server`;
    //generate a file from sql(eg SanAntonio Item,Cust,etc), or api (eg Murphy)
    let generatedFile = await this.esvc
      .generateFile(
        this.selectedCustomer,
        InvFileTypes[filename],
        this.selectedCompany
      )
      .toPromise();
    this.loading$ = "";
    if (!generatedFile || generatedFile == null) {
      alert("return was null");
      return;
    } else if (generatedFile.err && generatedFile.err.length > 0) {
      alert(
        `ERROR generating ${filename} for ${this.selectedCustomer}: ` +
          generatedFile.err
      );
      return;
    } else if (showSql) {
      try {
        this.allData = generatedFile as GenerateFileResult;

        //parse data into vars to display in table
        this.transResult = this.allData.data
          .split(/\r?\n/)
          .map((x) => x.split(/\|/));
        //get every 10th row to display with max 50 rows displayed
        this.transResult = this.transResult.filter(function (
          value,
          index,
          Arr
        ) {
          return index % 10 == 0 && index < 500;
        });

        this.transColList = this.transResult[0].map((x) => x);
        //end parse data

        if (
          !window.confirm(`${this.allData.filename} file rows: ${this.allData.rowsInFile} rows\nfor: ${this.selectedCustomer.name}
              \nat url: ${this.allData.s3path}\nClick "ok" to display SQL values on this site. Click "Cancel" to view the generated file in S3`)
        ) {
          window.open(
            `https://s3.console.aws.amazon.com/s3/object/gk-website/${this.allData.s3path}?region=us-east-1&tab=overview`,
            "_blank"
          );
        }
        //console.log('gen file: '+JSON.stringify(y))
      } catch (e) {
        alert("Error parsing generated file: " + e);
      }
    }
  }

  //generate all files for the given customer
  generateICTD() {
    if (!this.selectedCustomer.CanGenerateFiles) {
      alert("not allowed to generate files yet");
      return;
    }
    let ictdfiles = this.tFileKeys.filter(
      (x) => !this.tFile[x].outputtablename.startsWith("dbo.")
    );

    for (let trans of ictdfiles) {
      //generate only inventiv ICTD. dont display b/c more than 1 file
      this.generateFile(false, (this.tFile[trans] as TransformParts).filetype);
    }
  }

  generateICBlankTD() {
    if (!this.selectedCustomer.CanGenerateFiles) {
      alert("not allowed to generate files yet");
      return;
    }

    this.generateFile(false, InvFileTypes.Item);
    this.generateFile(false, InvFileTypes.Customer);
    this.generateFile(false, InvFileTypes.Transact);
    this.generateFile(false, InvFileTypes.Detail);
  }

  writeOrdersToCust() {
    if (!this.selectedCustomer.CanGenerateFiles) {
      alert("not allowed to generate files yet");
      return;
    }

    //generate only NON inventiv ICTD
    let mapfiles = this.tFileKeys.filter((x) =>
      this.tFile[x].outputtablename.startsWith("dbo.")
    );
    for (let trans of mapfiles) {
      //only display files if EXACTLY one written
      this.generateFile(
        mapfiles.length == 1,
        (this.tFile[trans] as TransformParts).filetype
      );
    }
  }

  getRowStyle(strtrans: any) {
    if (
      strtrans == null ||
      this.selectedInvFile == null ||
      this.selectedInvFile.outputCol == null
    )
      return;
    //if(typeof this.selectedInvFile.collist=='undefined')
    //  alert('colist undefined')

    let res = {
      "background-color": "white",
      display: "flex",
      "justify-content": "space-between",
      overflow: "auto",
    };
    let outcols = this.selectedInvFile.outputCol.map((x) => x.val);
    let incols = this._selectedInvFile.inputCol.map((x) => x.val);

    //check if fil.__ exists in OUTPUT dropdown
    let invcol = strtrans.val.match(/^fil\.([a-zA-Z0-9]+)=/); //first val is entire string, 2nd is match
    if (invcol == null || !invcol[1] || !outcols.includes(invcol[1])) {
      res["background-color"] = "brown";
      res["color"] = "white";
      return res;
    }

    //check if EACH name inside tablecol[] matches INPUT cols
    let strcols = strtrans.val.match(/tablecol\['([a-zA-Z0-9\_]+)']/);
    if (strcols != null && strcols.length >= 2) {
      strcols.shift(); //remove entire string
      strcols.forEach((x) => {
        if (!incols.includes(x)) {
          //console.log("col not found in cust data: " + x);
          res["background-color"] = "pink";
          //res["color"] = "white";
          return res;
        }
      });
    }

    //make sure inventiv field occurs only once
    let ct = this.selectedInvFile.transforms.map((x) => this.getFieldNumber(x));
    let curr = this.getFieldNumber(strtrans);
    if (ct.indexOf(curr) != ct.lastIndexOf(curr))
      res["background-color"] = "#ffcc00"; //yellow

    return res;
  }

  //FOR UI ONLY
  async addPdfColsForUI(tr: TransformParts): Promise<TransformPartsUI> {
    if (
      this.selectedCustomer.dbname.length <= 0 ||
      tr.outputtablename.length <= 0
    )
      throw "invalid params for addPdfColsForUI()";

    if (!tr.inputtablename.endsWith(".pdf")) throw "input must be pdf file";
    if (!tr.inputtablename.includes(":")) throw "pdf must include type";
    if (!tr.outputtablename.startsWith("select"))
      throw "output must be SQL statement";

    try {
      let filename = tr.inputtablename.split(":")[1];

      let incols = await this.esvc.getPDFLines(filename).toPromise();
      if (!incols) throw "no data returned from pdf. filename: " + filename;

      let pdfSqlFormatIn = new SqlApiObject();
      pdfSqlFormatIn.data = incols["res"];
      //console.log("pdf as sql format: " + JSON.stringify(pdfSqlFormatIn));

      let outcoldata = await this.esvc
        .getCustSQL(
          this.selectedCustomer.name,
          tr.outputtablename,
          this.selectedCompany
        )
        .toPromise();
      if (!outcoldata || !outcoldata.data || outcoldata.data.length <= 0)
        throw "invalid OUTPUT table data returned. Is the query correct? does the sql table have at least one row?";
      let outcolnames = await this.esvc
        .getSqlCols(
          parseTableName(tr.outputtablename),
          this.selectedCompany,
          this.selectedCustomer.name
        )
        .toPromise();
      return new TransformPartsUI(
        tr,
        pdfSqlFormatIn,
        null,
        outcoldata,
        outcolnames
      );
    } catch (e) {
      console.log("Error addPdfColsForUI on parse: " + e);
      throw "Error addPdfColsForUI on parse: " + e;
    }
  }

  async addSqlColsForUI(tr: TransformParts): Promise<TransformPartsUI> {
    if (
      this.selectedCustomer.dbname.length <= 0 ||
      tr.outputtablename.length <= 0
    )
      throw "invalid params for addSqlColsForUI()";
    try {
      //------------------------------------------------------
      //incols
      //------------------------------------------------------

      let sql = tr.inputtablename;
      console.log(
        `setting dialog (test) values from query. filetype: ${tr.filetype}. SQL: ${sql}`
      );
      let incoldata = await this.esvc
        .getCustSQL(this.selectedCustomer.name, sql, this.selectedCompany)
        .toPromise();
      if (!incoldata || incoldata.data.length <= 0) {
        console.log(`incoldata obj:`, incoldata);
        alert(
          `WARNING: Testing a transform will return nothing. \nquery: ${sql}\n cust: ${this.selectedCustomer.name} on ${this.selectedCustomer.pockadvservername}`
        );
      }

      let incolnames: InvFileSpec = new InvFileSpec();
      let tn: string = parseTableName(sql);
      if (tn.includes("select")) {
        console.log(
          "cant get input col names b/c sql join (cant parse table): " + tn
        );
      } else {
        this.loading$ = `getting sql columns from: ${tn}`;
        incolnames = await this.esvc
          .getSqlCols(tn, this.selectedCompany, this.selectedCustomer.name)
          .toPromise();
        this.loading$ = "";
      }
      //console.log("incolnames " + JSON.stringify(incolnames));

      //------------------------------------------------------
      //out cols
      //------------------------------------------------------
      tn = parseTableName(tr.outputtablename);
      let outcoldata = await this.esvc
        .getCustSQL(
          this.selectedCustomer.name,
          tr.outputtablename,
          this.selectedCompany
        )
        .toPromise();
      let outcolnames: InvFileSpec = new InvFileSpec();
      if (tn.includes("select")) {
        console.log(
          "cant get output col names b/c sql join (cant parse table): " + tn
        );
      } else
        outcolnames = await this.esvc
          .getSqlCols(tn, this.selectedCompany, this.selectedCustomer.name)
          .toPromise();
      //console.log("outcolnames " + JSON.stringify(outcolnames));
      //create UI object
      let res: TransformPartsUI = new TransformPartsUI(
        tr,
        incoldata,
        incolnames,
        outcoldata,
        outcolnames
      );
      return res;
    } catch (e) {
      console.log("Error addSqlColsForUI on parse: " + e);
      throw "Error addSqlColsForUI on parse: " + e;
    }
  }

  //calculates Field order of OUTPUT table field by order of the matching sql column
  //eg is CoId is sql first field then return 1
  getFieldNumber = function (strtrans: Transform) {
    try {
      let invcols = (this.selectedInvFile as TransformPartsUI).outputCol.map(
        (x) => x.val
      );
      //console.log("getFieldNumber: " + invcols);
      let invcol = strtrans.val.match(/^fil\.([a-zA-Z0-9]+)=/); //first val is entire string, 2nd is match
      let filcol = invcol[1];

      if (filcol) {
        let idx = invcols.indexOf(filcol) + 1; //+1=offset start=1
        //if (idx <= 0) console.log(`\nfilcol:${filcol}\ndoesnt exist in ${invcols.join(",").substring(0, 50) + "..."}`);
        return idx;
      } else {
        alert("invalid fil col: " + JSON.stringify(strtrans));
      }
    } catch {}

    return -1;
  };

  onDelete(i: number) {
    if (
      (
        prompt(
          "Sure you want to delete transform: " +
            this.selectedInvFile.transforms[i].val +
            " ? If so, type delete in the box below",
          ""
        ) || ""
      ).toLowerCase() == "delete"
    ) {
      this.selectedInvFile.transforms.splice(i, 1);
      this.updateTransform("");
    } else {
      alert(`phew, that was close! row not deleted!`);
    }
  }

  //when you click the edit icon
  onEdit(rownum: number) {
    if (!this.selectedInvFile.outputObjArray) throw "no output obj array";
    if (!this.selectedInvFile.inputCol) throw "no output col array";

    let data: DialogSend = {
      inputmappingname: parseTableName(this.selectedInvFile.inputtablename),
      outputmappingname:
        this.selectedCustomer.dbname +
        ".." +
        parseTableName(this.selectedInvFile.outputtablename) +
        " for co: " +
        this.selectedCompany.name +
        `(${this.selectedCompany.awscoid}) SAVING TO Transform_v2`,
      type: this.selectedCustomer.name == "Convoy" ? "PDF" : "SQL",
      trans: this.selectedInvFile.transforms[rownum],
      objArray: this.selectedInvFile.inputObjArray,
      colArray: this.selectedInvFile.inputCol,
    };

    let dialogref = this.dialog.open(EditDialogComponent, {
      width: "90vw",
      height: "90vw",
      data: data as DialogSend,
    });

    dialogref.afterClosed().subscribe((result: DialogResponse) => {
      if (result) {
        this.selectedInvFile.transforms[rownum].val =
          result.filcol + "=" + result.trans;
        this.selectedInvFile.transforms[rownum].note = result.notes;
        //this.updateTransform(""); ----dont save after exit dialog, must hit SAVE
        this.isDirty = true;
      }
    });
  }

  //input eg: Detail12
  getLongFilename(x: string) {
    this._tFile = new TransformFile(this.strtransformfile);
    let tt: TransformParts = this._tFile[x];
    //console.log(JSON.stringify(tt));
    let px = "";
    try {
      px = parseTableName(tt.inputtablename);
    } catch {}
    return getLongFilename(x) + "   intable: " + px;
  }

  async updateTransform(
    s: "overwrite" | "reload" | "",
    trobj?: TransformParts
  ) {
    let objtowrite; //obkect that is being serialized
    let sqlinsert; //sql statement that inserts object into sql

    //normal action -dont force overwrite
    //validate data changes before writing
    try {
      if (s !== "overwrite") {
        if (!trobj) {
          //not passing in a transform object. just want to update the JSON based on changes to the file stored in this.selectedInvFile
          let selected = new TransformParts(
            this.selectedInvFile.name,
            this.selectedInvFile
          );
          //update the tFile with the selected Inv File data
          this._tFile[this.selectedInvFile.name] = selected;
        } else {
          //passing transform from the addDetail method
          this._tFile[trobj.name] = trobj;
        }
        console.log("TFILE: " + this._tFile.toString());
        objtowrite = this._tFile.toString();

        //<<<<<<<if change transform object edit the to string here
      } else objtowrite = this.strtransformfile;

      this.strtransformfile = objtowrite; //update JSON string whether or not success, so can copy and backup manually
      JSON.parse(objtowrite); //throws err if invalid.DELIBERATE

      sqlinsert = this.TransformToSQLSave(
        this._tFile,
        this.selectedCustomer,
        this.lgsvc.subUser.value.email,
        this.selectedCompany
      );
    } catch (e) {
      alert(
        `Invalid json. LIKELY an ENTER character in a transform row, or a double quote in transform.\nerr: ${e}.`
      );
      console.log(objtowrite.toString());
      return; //dont write invalid json. return instead
    }
    try {
      if (objtowrite && sqlinsert && s == "overwrite") {
        //dont check safe, just write
        this.loading$ = "writing transform";
        let sqlres = await this.esvc.writeTransformToSQL(
          sqlinsert,
          this.selectedCompany
        );
        if (!sqlres)
          alert("transform update failed. changes WILL NOT be saved.");
        else {
          window.location.href = this.document.location.origin;
          //console.log(`transform updated successfully`);
        }
        this.loading$ = "";
        this.isDirty = false;
      } else if (objtowrite && sqlinsert) {
        //check to make sure no one else has made changes
        console.log(`checking to make sure no one else has made changes`);
        let isSafe = this.esvc.checkSafeBeforeUpdatingTransform(
          this.selectedCustomer,
          this.selectedCompany,
          this.LastUser,
          this.DateSaved
        );
        console.log(`isSafe: ${isSafe}`);
        if (isSafe) {
          this.loading$ = "writing transform";
          let sqlres = await this.esvc.writeTransformToSQL(
            sqlinsert,
            this.selectedCompany
          );
          if (!sqlres) {
            alert(
              "transform update failed. changes WILL NOT be saved. Copy the GLOBAL transform to your machine to backup before reloading the page so you dont lose all your changes."
            );
            return;
          }
          this.loading$ = "";
          this.isDirty = false;
          //window.location.href = this.document.location.origin;
          this.loading$ =
            "loading and parsing transform file for customer: " +
            (!this.selectedCustomer ? "" : this.selectedCustomer.name) +
            " after save";
          this.loadTransform();
          //console.log(`transform updated successfully`);
        } else {
          alert(`transforms not updated due to user mismatch`);
        }
      } else alert("cant write undefined JSON file obj");
    } catch (x) {
      alert(`err in updateTransform() part2: ${x}`);
    }
  }

  //RETURNS THE SQL STRING NEEDED TO SAVE THE FILE
  TransformToSQLSave = function (
    tf: TransformFile,
    cust: Customer,
    loggedInEmail: string,
    co: Company
  ) {
    try {
      return `insert into ICTD..Transform_v2(Transform,DateSaved,LastUser,CustomerName,AwsCoId) values('${tf
        .toString()
        .replace(/'/g, "''")}',getdate(),'${loggedInEmail}','${cust.name}',${
        co.awscoid
      })`;
    } catch (e) {
      throw `ERR converting transform to SQL string for saving on Transform: ${tf} for customer: ${cust.name} due to err: ${e}. Transform was: ${tf}`;
    }
  };
}
