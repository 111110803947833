import { Component, OnInit } from "@angular/core";
import { Company, Customer } from "@shared/customer";
import { Subscription } from "rxjs";
import { AppService } from "../app.service";
import { AuthService } from "../auth/auth.service";

@Component({
  selector: "coselect",
  templateUrl: "./coSelect.html",
  //styleUrls: ["./edit-transform.component.css"],
})
export class CoSelectComponent implements OnInit {
  public _sub1: Subscription;
  public _sub2: Subscription;
  private _selectedCustomer: Customer;
  private _selectedCompany: Company;
  public disableCust: boolean = false;
  //public _sub3: Subscription;

  constructor(private appSvc: AppService, public lgsvc: AuthService) {}

  async ngOnInit() {
    console.log(`ngOnInit started`);
    this.appSvc._selectedEnvIsLiveObservable().subscribe((y: boolean) => {
      console.log("edit detected env change: " + y);
    });
    this._sub1 = this.appSvc
      ._selectedCustomerObservable()
      .subscribe((y: Customer) => {
        this._selectedCustomer = y;
      });
    this._sub2 = this.appSvc
      ._selectedCompanyObservable()
      .subscribe((y: Company) => {
        this._selectedCompany = y;
      });
  }

  ngOnDestroy() {
    this._sub1.unsubscribe();
    this._sub2.unsubscribe();
  }

  set selectedCustomer(c: Customer) {
    //this._selectedCustomer = c;
    this.disableCust = true;
    this.appSvc.setCustomer(c);
  }
  get selectedCustomer() {
    return this._selectedCustomer;
  }
  get selectedCompany() {
    return this._selectedCompany;
  }
  set selectedCompany(x) {
    this.appSvc.setCompany(x);
  }
  refreshCustomers() {
    //resets token permissions and reloads customers
    console.log(`refreshing customers. resetting token`);
    this.lgsvc.setClaimsFromToken();
  }
}
