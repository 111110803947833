import { Company, Customer } from "./customer";
import { InvFileTypes, TransformParts } from "./shared.models";
import { transformSql } from "./_helperfunc";

export interface GenerateFileResult {
  data: string; //RAW must split on \r\n for rows and | for cells
  twodigitcoid: string;
  awscoid: number;
  filename: string;
  s3path: string;
  err: string;
  rowsInFile: number;
}

export interface RouteEmail {
  route: string;
  driver: string;
  TwoDigitCoId: string;
  date: string; //must be sql formatted
  orig: object[]; //vwFB_Route_Email object original
  payment: object[];
}
//ICTD..Email_Send
export interface Email_Send {
  CustomerName: string;
  Email: string;
  isActive: boolean;
  EmailTopic: EmailTopic;
  awscoid: number;
}

export enum EmailTopic {
  RouteClose = "RouteClose",
}

//person who is logged into website
export interface LoggedInUser {
  isLoggedIn: boolean;
  email: string;
  isEmployee: boolean;
  allowedCust: Customer[];
}

export interface MapToDbResult {
  status: string;
  position: string; //string denoting where code stopped (for troubleshooting)
  errmsg: string;
  params: string; //input params eg filename, db, etc

  sqlpretransform: string[];
  transforms: string[]; //from the TransformParts object. just the string transforms
  sqlwritten: Object[]; //[{"outputcol1":"xx","outputcol2":"xx"}] //data written to output sql table
}

//used by odata.getD365 response
export interface SqlResponseObject {
  res: string;
  status: number;
  version: string; //api version that responded to the request
}

export enum LambdaName {
  sqlRunSchedule = "sqlRunSchedule",
  rdsquery = "rdsquery",
  rdsexec = "rdsexec",
  generateFile = "generateFile",
  mapOrder = "mapOrder",
  mapPaymentJournal = "mapPaymentJournal",
  mapReceipt = "mapReceipt",
  mapReturn = "mapReturn",
  pullPkgApi = "pullPkgApi",
  pullCustomApi = "pullCustomApi",
  pullD365Data = "pullD365Data",
  sendSlack = "sendSlack",
  generateTxtFile = "generateTxtFile",
  mapEmail = "mapEmail",
  SendApprovalStatusWebhook = "SendApprovalStatusWebhook",
  mapSql = "mapSql",
}

export interface HealthCheck {
  orders: object;
  returns: object;
  payments: object;
  emails: object;
  datapull: object;
  krsp: object;
}

export interface Edi750StatusResponse {
  CompanyId: string;
  Notes: string;
  order: OrderExportDataLink[];
}

export interface ProviEdiOrderStatusResponse {
  order_token: string;
  distributor_token: string;
  customer_id: string;
  delivery_date: string;
  products: ProviEdiProductsStatusResponse[];
}
export interface ProviEdiProductsStatusResponse {
  product_id: string;
  product_name: string;
  case_quantity: number;
  case_price: number;
  unit_quantity: number;
  unit_price: number;
  delivery_date: string; //YYYY-MM-DD
  status: OrderStatus;
  InventivLineGuid: string;
}
export enum OrderStatus {
  pending = "pending",
  accepted = "accepted",
  removed = "removed",
  out_of_stock = "out_of_stock",
  backordered = "backordered",
  added = "added",
  itemNotFound = "itemNotFound", //tell provi 750 to accept this
}
export enum TransmissionType {
  STRAIGHTTHRU = "STRAIGHTTHRU",
  PACC = "PACC",
  PAPLUS = "PAPLUS",
}
export class OrderExportDataLink {
  OrderId: string;
  CompanyId: string;
  AwsCoId: string;
  CustomerId: string;
  ItemId: string;
  SalesRepId: string;
  Cases: string;
  Units: string;
  CasePrice: string;
  UnitPrice: string;
  OrderTypeId: string;
  OrderSource: string;
  RetailSKU: string;
  PONumber: string;
  CreatedBy: string;
  DateCreated: string;
  SentToHost: string;
  SentToHostAt: string;
  Notes: string;
  DeliveryDate: string;
  ParentOrderId: string;
  MessageId: string;
  OrderLineGuid: string; //was OrderGuid
  ClientSalesOrderId: string;
  CaseSize: string;
  CustomerEmail: string;
  CustomerPhone: string;
  Location: string;
  TransmissionType: string;
  ItemDescription: string;
  CustomerTradeName: string;
  SalesRepName: string;
  OrderHeaderGuid: string; //order unique
  //these are stored on ecom db, not datalink because the update for these values requires
  //MessageID (which is updated on Ecom, by Jeremy- not the DataLink tables)
  ApprovalStatus: string; //---used by webhook to know when to send update
  ApprovalStatusHasBeenSentToCustomer: boolean; //---used by webhook to know when to send update
  ItemNotInDB: boolean;

  public constructor(init?: Partial<ApprovalRepOrders>) {
    Object.assign(this, init);
  }

  ProviStatusResponse(x: OrderStatus): ProviEdiProductsStatusResponse {
    return {
      product_id: this.ItemId,
      product_name: this.ItemDescription,
      case_quantity: parseInt(this.Cases) || 0,
      case_price: parseFloat(this.CasePrice) || 0,
      unit_quantity: parseInt(this.Units) || 0,
      unit_price: parseFloat(this.UnitPrice) || 0,
      delivery_date: this.DeliveryDate, //YYYY-MM-DD
      status: x,
      InventivLineGuid: this.OrderLineGuid,
    } as ProviEdiProductsStatusResponse;
  }
}
export class rndcOrder {
  SalesOrder: {
    HeaderId: string;
    OrderNumber: string;
    SourceTransactionNumber: string;
    SourceTransactionSystem: string;
    SourceTransactionId: string;
    BusinessUnitId: string;
    BusinessUnitName: string;
    TransactionOn: string;
    BuyingPartyId: string;
    BuyingPartyName: string;
    BuyingPartyNumber: string;
    BuyingPartyPersonFirstName: string;
    BuyingPartyPersonLastName: string;
    BuyingPartyPersonMiddleName: string;
    BuyingPartyPersonNameSuffix: string;
    BuyingPartyPersonTitle: string;
    BuyingPartyContactId: string;
    BuyingPartyContactName: string;
    BuyingPartyContactNumber: string;
    BuyingPartyContactFirstName: string;
    BuyingPartyContactLastName: string;
    BuyingPartyContactMiddleName: string;
    BuyingPartyContactNameSuffix: string;
    BuyingPartyContactTitle: string;
    PreferredSoldToContactPointId: string;
    CustomerPONumber: string;
    TransactionTypeCode: string;
    TransactionType: string;
    SubstituteAllowedFlag: string;
    PackingInstructions: string;
    ShippingInstructions: string;
    ShipsetFlag: string;
    PartialShipAllowedFlag: string;
    RequestedShipDate: string;
    RequestedArrivalDate: string;
    LatestAcceptableShipDate: string;
    LatestAcceptableArrivalDate: string;
    EarliestAcceptableShipDate: string;
    ShipmentPriorityCode: string;
    ShipmentPriority: string;
    ShippingCarrierId: string;
    ShippingCarrier: string;
    ShippingServiceLevelCode: string;
    ShippingServiceLevel: string;
    ShippingModeCode: string;
    ShippingMode: string;
    FOBPointCode: string;
    FOBPoint: string;
    DemandClassCode: string;
    DemandClass: string;
    FreightTermsCode: string;
    FreightTerms: string;
    RequestedFulfillmentOrganizationId: string;
    RequestedFulfillmentOrganizationCode: string;
    RequestedFulfillmentOrganizationName: string;
    SupplierId: string;
    SupplierName: string;
    SupplierSiteId: string;
    PaymentTermsCode: string;
    PaymentTerms: string;
    SalespersonId: string;
    Salesperson: string;
    PricedOn: string;
    TransactionalCurrencyCode: string;
    TransactionalCurrencyName: string;
    AppliedCurrencyCode: string;
    CurrencyConversionDate: string;
    CurrencyConversionRate: string;
    CurrencyConversionType: string;
    PricingSegmentCode: string;
    PricingSegment: string;
    PricingStrategyId: string;
    PricingStrategyName: string;
    AllowCurrencyOverrideFlag: string;
    SegmentExplanationMessageName: string;
    PricingSegmentExplanation: string;
    StrategyExplanationMessageName: string;
    PricingStrategyExplanation: string;
    SalesChannelCode: string;
    SalesChannel: string;
    Comments: string;
    StatusCode: string;
    Status: string;
    OnHoldFlag: string;
    CanceledFlag: string;
    CancelReasonCode: string;
    CancelReason: string;
    RequestedCancelDate: string;
    RequestingBusinessUnitId: string;
    RequestingBusinessUnitName: string;
    RequestingLegalEntityId: string;
    RequestingLegalEntity: string;
    LLC;
    FreezePriceFlag: string;
    FreezeShippingChargeFlag: string;
    FreezeTaxFlag: string;
    SubmittedFlag: string;
    SubmittedBy: string;
    SubmittedDate: string;
    TransactionDocumentTypeCode: string;
    PreCreditCheckedFlag: string;
    RevisionSourceSystem: string;
    SourceTransactionRevisionNumber: string;
    TradeComplianceResultCode: string;
    TradeComplianceResult: string;
    LastUpdatedBy: string;
    LastUpdateDate: string;
    CreatedBy: string;
    CreationDate: string;
    OpenFlag: string;
    OrigSystemDocumentReference: string;
    OrderKey: string;
    AppliedCurrencyName: string;
    SupplierSiteName: string;
    MessageText: string;
    AgreementHeaderId: string;
    AgreementNumber: string;
    AgreementVersionNumber: string;
    billToCustomer: [
      {
        AddressId: string;
        CustomerAccountId: string;
        PartyName: string;
        AccountNumber: string;
        PersonFirstName: string;
        PersonLastName: string;
        PersonMiddleName: string;
        PersonNameSuffix: string;
        PersonTitle: string;
        SiteUseId: string;
        Address1: string;
        Address2: string;
        Address3: string;
        Address4: string;
        City: string;
        State: string;
        PostalCode: string;
        County: string;
        Province: string;
        Country: string;
        ContactId: string;
        ContactName: string;
        ContactNumber: string;
        ContactFirstName: string;
        ContactMiddleName: string;
        ContactLastName: string;
        ContactNameSuffix: string;
        ContactTitle: string;
        PreferredContactPointId: string;
        SourceAddressId: 1;
      }
    ];
    shipToCustomer: [
      {
        AddressId: string;
        PartyId: string;
        PartyName: string;
        PartyNumber: string;
        PersonFirstName: string;
        PersonMiddleName: string;
        PersonLastName: string;
        PersonNameSuffix: string;
        PersonTitle: string;
        SiteId: string;
        Address1: string;
        Address2: string;
        Address3: string;
        Address4: string;
        City: string;
        State: string;
        PostalCode: string;
        County: string;
        Province: string;
        Country: string;
        ContactId: string;
        ContactName: string;
        ContactNumber: string;
        ContactFirstName: string;
        ContactMiddleName: string;
        ContactLastName: string;
        ContactNameSuffix: string;
        ContactTitle: string;
        PreferredContactPointId: string;
        SourceAddressId: 1;
      }
    ];
    lines: [
      {
        HeaderId: string;
        LineId: string;
        FulfillLineId: string;
        SourceTransactionLineId: string;
        SourceTransactionLineNumber: string;
        SourceTransactionId: string;
        SourceTransactionNumber: string;
        SourceTransactionSystem: string;
        RequestingBusinessUnitId: string;
        RequestingBusinessUnitName: string;
        AccountingRuleCode: string;
        AccountingRule: string;
        ActionTypeCode: string;
        ActionType: string;
        ActualCompletionDate: string;
        ActualShipDate: string;
        AppliedPriceListId: string;
        AppliedPriceListName: string;
        AssessableValue: string;
        AssetGroupNumber: string;
        AssetTrackedFlag: string;
        AssetTrackingCode: string;
        AssetTracking: string;
        BillingTransactionTypeId: string;
        BillingTransactionTypeName: string;
        BuyerId: string;
        BuyerName: string;
        BuyerFirstName: string;
        BuyerLastName: string;
        BuyerMiddleName: string;
        CancelReasonCode: string;
        CancelReason: string;
        CanceledFlag: string;
        CanceledQuantity: string;
        ShippingCarrierId: string;
        ShippingCarrier: string;
        TransactionCategoryCode: string;
        Comments: string;
        ComponentIdPath: string;
        ConfigCreationDate: string;
        ConfigHeaderId: string;
        ConfigInventoryItemId: string;
        ConfigInventoryItemNumber: string;
        ConfigInventoryItemDescription: string;
        ConfigItemReference: string;
        ConfigRevisionNumber: string;
        ConfigTradeComplianceResultCode: string;
        ConfigTradeComplianceResult: string;
        ConfiguratorPath: string;
        ContractEndDate: string;
        ContractStartDate: string;
        CreatedBy: string;
        CreationDate: string;
        CreditCheckAuthExpiryDate: string;
        CreditCheckAuthorizationNumber: string;
        CustomerProductId: string;
        CustomerProductNumber: string;
        CustomerProductDescription: string;
        CustomerPOLineNumber: string;
        CustomerPONumber: string;
        CustomerPOScheduleNumber: string;
        DefaultTaxationCountry: string;
        DefaultTaxationCountryShortName: string;
        DemandClassCode: string;
        DemandClass: string;
        DestinationLocationId: string;
        DestinationShippingOrganizationId: string;
        DestinationShippingOrganizationCode: string;
        DestinationShippingOrganizationName: string;
        DocumentSubtype: string;
        DocumentSubtypeName: string;
        EarliestAcceptableShipDate: string;
        EstimateFulfillmentCost: string;
        EstimateMargin: string;
        ExemptionCertificateNumber: string;
        ExtendedAmount: string;
        FinalDischargeLocationId: string;
        FinalDischargeLocationAddressLine1: string;
        FinalDischargeLocationAddressLine2: string;
        FinalDischargeLocationAddressLine3: string;
        FinalDischargeLocationAddressLine4: string;
        FinalDischargeLocationAddressCity: string;
        FinalDischargeLocationAddressPostalCode: string;
        FinalDischargeLocationAddressState: string;
        FinalDischargeLocationAddressProvince: string;
        FinalDischargeLocationAddressCounty: string;
        FinalDischargeLocationAddressCountry: string;
        FirstPartyTaxRegistration: string;
        FirstPartyTaxRegistrationNumber: string;
        FOBPointCode: string;
        FOBPoint: string;
        FreightTermsCode: string;
        FreightTerms: string;
        FulfillInstanceId: string;
        FulfillLineNumber: string;
        RequestedFulfillmentOrganizationId: string;
        RequestedFulfillmentOrganizationCode: string;
        RequestedFulfillmentOrganizationName: string;
        OverFulfillmentTolerance: string;
        UnderFulfillmentTolerance: string;
        FulfilledQuantity: string;
        FulfillmentDate: string;
        FulfillmentMode: string;
        FulfillmentSplitReferenceId: string;
        GopReferenceId: string;
        GopRequestRegion: string;
        IntendedUseClassificationId: string;
        IntendedUseClassificationName: string;
        ProductId: string;
        ProductNumber: string;
        ProductDescription: string;
        InventoryOrganizationId: string;
        InventoryOrganizationCode: string;
        InventoryOrganizationName: string;
        InvoiceEnabledFlag: string;
        InvoiceInterfacedFlag: string;
        InvoiceableItemFlag: string;
        InvoicingRuleCode: string;
        InvoicingRule: string;
        ItemSubTypeCode: string;
        ItemSubType: string;
        ItemTypeCode: string;
        LastUpdateDate: string;
        LastUpdateLogin: string;
        LastUpdatedBy: string;
        LatestAcceptableArrivalDate: string;
        LatestAcceptableShipDate: string;
        TransactionLineTypeCode: string;
        TransactionLineType: string;
        OnHoldFlag: string;
        OpenFlag: string;
        OrderedQuantity: string;
        OrderedUOMCode: string;
        OrderedUOM: string;
        BusinessUnitId: string;
        BusinessUnitName: string;
        OrigSystemDocumentLineReference: string;
        OrigSystemDocumentReference: string;
        OriginalProductId: string;
        OriginalProductNumber: string;
        OriginalProductDescription: string;
        OverrideScheduleDateFlag: string;
        OwnerId: string;
        PackingInstructions: string;
        ParentFulfillLineId: string;
        PartialShipAllowedFlag: string;
        PaymentTermsCode: string;
        PaymentTerms: string;
        POStatusCode: string;
        PricedOn: string;
        ProcessNumber: string;
        ProductFiscalCategoryId: string;
        ProductFiscalCategory: string;
        ProductCategory: string;
        ProductCategoryName: string;
        ProductType: string;
        ProductTypeName: string;
        PromiseArrivalDate: string;
        PromiseShipDate: string;
        PurchasingEnabledFlag: string;
        PurchasingUOMCode: string;
        PurchasingUOM: string;
        QuantityForEachModel: string;
        ReceivablesOrgId: string;
        ReceivablesOrgName: string;
        RemnantFlag: string;
        RequestedArrivalDate: string;
        RequestedCancelDate: string;
        RequestedShipDate: string;
        RequiredFulfillmentDate: string;
        RequisitionBusinessUnitId: string;
        RequisitionBusinessUnitName: string;
        RequisitionInventoryOrganizationId: string;
        RequisitionInventoryOrganizationCode: string;
        RequisitionInventoryOrganizationName: string;
        ReservableFlag: string;
        ReservationId: string;
        ReservedQuantity: string;
        ReturnReasonCode: string;
        ReturnReason: string;
        ReturnableFlag: string;
        RMADeliveredQuantity: string;
        RootParentFulfillLineId: string;
        SalesProductTypeCode: string;
        SalesProductType: string;
        SalespersonId: string;
        Salesperson: string;
        ScheduleArrivalDate: string;
        ScheduleShipDate: string;
        SchedulingReason: string;
        SchedulingReasonCode: string;
        ServiceDuration: string;
        ServiceDurationPeriodCode: string;
        ServiceDurationPeriodName: string;
        ShippingServiceLevelCode: string;
        ShippingServiceLevel: string;
        ShippingModeCode: string;
        ShippingMode: string;
        ShipSetName: string;
        ShipmentPriorityCode: string;
        ShipmentPriority: string;
        ShippableFlag: string;
        ShippedQuantity: string;
        ShippedUOMCode: string;
        ShippedUOM: string;
        ShippingInstructions: string;
        ShowInSales: string;
        SourceTransactionRevisionNumber: string;
        SourceTransactionScheduleId: string;
        SourceScheduleNumber: string;
        SplitFromFlineId: string;
        StatusCode: string;
        Status: string;
        SubinventoryCode: string;
        Subinventory: string;
        SubstitutionAllowedFlag: string;
        SubstitutionReasonCode: string;
        SubstitutionReason: string;
        SupplierId: string;
        SupplierName: string;
        SupplierSiteId: string;
        SupplierSiteName: string;
        SupplyStatusCode: string;
        TaxClassificationCode: string;
        TaxClassification: string;
        TaxExemptFlag: string;
        TaxExemptCode: string;
        TaxExempt: string;
        TaxExemptReasonCode: string;
        TaxExemptReason: string;
        TaxInvoiceDate: string;
        TaxInvoiceNumber: string;
        ThirdPartyTaxRegistration: string;
        ThirdPartyTaxRegistrationNumber: string;
        TotalContractAmount: string;
        TotalContractQuantity: string;
        TradeComplianceDate: string;
        TradeComplianceResultCode: string;
        TradeComplianceResult: string;
        TransportationPlannedFlag: string;
        TransportationPlannedStatusCode: string;
        TransactionBusinessCategory: string;
        TransactionBusinessCategoryName: string;
        UnitListPrice: string;
        UnitQuantity: string;
        UnitSellingPrice: string;
        UserDefinedFiscalClass: string;
        UserDefinedFiscalClassName: string;
        ValidConfigurationFlag: string;
        LineNumber: string;
        DisplayLineNumber: string;
        ParentSourceTransactionLineId: string;
        TransformFromLineId: string;
        UnreferencedReturnFlag: string;
        ServiceCancelDate: string;
        CoveredProductId: string;
        CoveredProductNumber: string;
        CoveredProductDescription: string;
        CoveredCustomerProductId: string;
        CoveredCustomerProductNumber: string;
        CoveredCustomerProductDescription: string;
        JeopardyPriorityCode: string;
        JeopardyPriority: string;
        AgreementHeaderId: string;
        AgreementLineId: string;
        AgreementLineNumber: string;
        AgreementVersionNumber: string;
        AgreementNumber: string;
        SellingProfitCenterBusinessUnitId: string;
        SellingProfitCenterBusinessUnitName: string;
        TransportationPlanningOrder: string;
        ContractEndDateTime: string;
        ContractStartDateTime: string;
        SubscriptionProfileId: string;
        SubscriptionProfileName: string;
        ExternalPriceBookName: string;
        EndReasonCode: string;
        EndReason: string;
        EndCreditMethodCode: string;
        EndCreditMethod: string;
        EndDate: string;
        InventoryTransactionFlag: string;
        InventoryInterfacedFlag: false;
      }
    ];
    totals: [
      {
        OrderTotalId: string;
        TotalName: string;
        TotalCode: string;
        TotalAmount: string;
        CurrencyName: string;
        CurrencyCode: string;
        TotalGroup: string;
        PrimaryFlag: string;
        EstimatedFlag: string;
        DisplayName: string;
      }
    ];
    additionalInformation: [
      {
        Category: string;
        CreatedBy: string;
        CreationDate: string;
        HeaderId: string;
        LastUpdateDate: string;
        LastUpdateLogin: string;
        LastUpdatedBy: string;
        ObjectVersionNumber: string;
        SourceTransactionIdentifier: string;
        SourceTransactionSystem: string;
        headerAdditionalInformation: [
          {
            SourceSystem: string;
            SeparateInvoice: string;
            ThermalInvoice: string;
            CoopOrPoolNumber: string;
            ContextValue: 1234;
          }
        ];
      }
    ];
  };
  allTrans: Map<InvFileTypes, TransformParts>;

  public constructor(
    init: Partial<rndcOrder>,
    allTransa: Map<InvFileTypes, TransformParts>
  ) {
    //console.log(`assigning: `, init);
    Object.assign(this, init);
    this.allTrans = allTransa;
  }

  async getTransformed(
    i: InvFileTypes,
    headlines: object[]
  ): Promise<object[]> {
    //console.log(`getTransformed() this class params: `, this.allTrans.keys());
    let x = this.allTrans.get(i);
    if (!x || !x.transforms) throw `undefined getTrans() on ${i}`;
    // console.log(
    //   `applying `,
    //   i,
    //   " transforms ",
    //   x.transforms,
    //   " to ",
    //   headlines,
    //   " async ",
    //   false
    // );
    return transformSql(x.transforms, headlines, false);
  }
}

export interface OrderExportStatus {
  OrderLineGuid: string;
  SentToPAServerAt: string;
  OrderedCases: number;
  OrderedUnits: number;
}

export interface PocketOrders {
  CompanyID: string;
  SalesRepID: string;
  CustomerID: string;
  ItemID: string;
  OrderType: string;
  DateCreated: string;
  BackOrder: string;
  OutofStock: string;
  ClassificationString: string;
  ClassificationOther: string;
  DateSent: string;
  DeliveryInstructions: string;
  DeliveryDate: string;
  CasesConfirmed: string;
  UnitsConfirmed: string;
  Voided: string;
  VoidedConfirmed: string;
  Cases: string;
  Units: string;
  PriceOverride: string;
  DiscountOverride: string;
  DiscountPercent: string;
  DealCode: string;
  RetailPrice: string;
  DisplayCases: string;
  InstoreCases: string;
  InstoreUnits: string;
  SenttoHost: string;
  SentToHostAt: string;
  CasePrice: string;
  UnitPrice: string;
  InvoiceBreak: string;
  Vintage: string;
  PricingChoice: string;
  BillAndHold: string;
  Comment: string;
  LumpSumDiscount: string;
  OrderReason: string;
  Rejected: string;
  Approval: string;
  InStoreCasesDetail: string;
  InStoreUnitsDetail: string;
  ConnectionDate: string;
  OrderSumDateID: string;
  DealCode2: string;
  Config1: string;
  Config2: string;
  Config3: string;
  Config4: string;
  Config5: string;
  NoSplitCharge: string;
  Config6: string;
  Config7: string;
  Config8: string;
  Config9: string;
  Config10: string;
  NetCasePrice: string;
  NetUnitPrice: string;
  Config11: string;
  Config12: string;
  Config13: string;
  Config14: string;
  Config15: string;
  Config16: string;
  Config17: string;
  Config18: string;
  Config19: string;
  Config20: string;
  OrderID: string;
  AccountID: string;
  AdvCloudID: string;
  DistanceFromCreated: string;
  OrderGuid: string;
  SentToAWS: string;
  SentToAwsType: string;
  SentToAWSAt: string;
  OrderSource: string;
  Disposition: string;
  RecordGUID: string;
  OrderSummaryGuid: string;
  err?: string; //added to return and put into paMessage if there are issues submitting a line
}

export interface PocketOrderSummary {
  CompanyID: string;
  SalesRepID: string;
  CustomerID: string;
  Comment: string;
  SignatureDate: string;
  NameOnSignature: string;
  SignatureImage: string;
  ExpectedEntries: string;
  DateSent: string;
  PullAttempts: string;
  Instructions: string;
  IPAddress: string;
  Comment2: string;
  PONumber: string;
  DelivInstr: string;
  InvoiceBreak: string;
  ConnectionType: string;
  ClientHardwareID: string;
  Comment3: string;
  Comment4: string;
  Instructions2: string;
  DateField1: string;
  DateField2: string;
  ConnectionDate: string;
  DateID: string;
  NoSplitCharge: string;
  ServerStamp: string;
  AccountID: string;
  AdvCloudID: string;
  DistanceFromSent: string;
  SummaryGuid: string;
  Confirmed: string;
  eComName: string;
  eComMobile: string;
  eComEmail: string;
  eComUserID: string;
  UserProfileCoID: string;
  UserProfileRepID: string;
}

export interface RndcResponse {
  invSummaryGuid: string;
  headerkey: string;
  lineitems: string[];
  err: string;
  htrans: PocketOrderSummary;
  ltrans: PocketOrders[];
}
export interface ApprovalRepOrders {
  OrderID: string;
  ServerID: string;
  CompanyID: string;
  OrderLineID: string;
  MessageID: string;
  StatusID: string;
  CustomerID: string;
  OrderType: string;
  ItemID: string;
  ItemDesciption: string;
  Cases: string;
  Bottles: string;
  OrderReason: string;
  DealCode: string;
  ProdComment: string;
  InStock: string;
  FrontLineCase: string;
  DiscountOverride: string;
  NetCase: string;
  NetBottle: string;
  CaseGP: string;
  DateSent: string;
  UnitsPerCase: string;
  DirtyRecord: string;
  DeniedOrderReason: string;
  DeliveryDate: string;
  TrackingID: string;
  OrderSource: string;
  PersonOrdering: string;
  Guid: string;
  RetailSKU: string;
  AutoApprove: string;
  UsePA: string;
  SalesRepID: string;
  CoID: string;
  DeviceID: string;
  StatusDescription: string; //from ApprovalStatus
}

export class SqlApiObject {
  data: Object[] = []; //rows of sql data eg: [{"ItemCode":"DMS1926","ItemType":"1"...},{...}]
  err: string = "";
  version: string = ""; //api version that responded to the request
}

export interface IpModel {
  err: string;
  version: string;
  ip: string;
}

export interface LoginModel {
  email: string | null;
  key: string | null; //ENCODED TOKEN
  err: string | null;
}

export interface MapOrdersTestResult {
  custjson: Object[];
  headerInsertQuery: string;
  detailInsertQueries: string[];
}

//---------------------------------------------PDF
export enum CoType {
  OP = "OP",
  CBA = "CBA",
  VER = "Vervet",
}

//matches inventiv file spec
export class TransactDetail {
  HeaderGuid: string; //matcher headerguid
  LineID: string;
  ItemID: string;
  Cases: string;
  Units: string;
  CasePricePaid: string;
  UnitPricePaid: string;

  toString() {
    let xx: string[] = [];
    for (let k of Object.keys(this)) xx.push(k + ":" + this[k] + "\n");
    return xx.join("");
  }
}

//matches inventiv file spec
export class TransactHeader {
  //matches Inventiv transact.fil
  CompanyID: string;
  TransactionType: string;
  DocumentID: string;
  DocumentDate: string;
  CustomerID: string;
  SalesRepID: string;
  SalesDollars: string;
  NetCost: string;
  DeliveryType: string;
  OrderSource: string;
  CaseDiscount: string;
  DepletionAllowance: string;
  CreditRefInvoiceID: string;
  CreditReason: string;
  BillDate: string;
  OrderRepID: string;
  DeliveryPONumber: string;
  DeliveryRoute: string;
  DeliveryStop: string;
  DeliveryDate: string;
  DeliveryTime: string;
  InvoiceType: string;
  Optional1: string;
  Optional2: string;
  Optional3: string;
  Optional4: string;
  Optional5: string;
  Optional6: string;
  DeliveryTax: string;
  DeliveryFreight: string;
  DeliveryBottleChg: string;
  DeliveryRecycleFee: string;
  DeliveryLineID: string;
  DeliveryInstructions: string;
  DeliveryCreditTerms: string;
  SplitChargeApplied: string;
  DeliveryCollectType: string;
  DeliveryInvoiceDueDate: string;
  LineItem1: string;
  LineItem2: string;

  //--------------------paul added
  InvoiceFileName: string;
  InvoiceNo: string;
  BillToAddress1: string;
  ShipToAddress1: string;
  BillToAddress2: string;
  ShipToAddress2: string;
  BillToAddress3: string;
  ShipToAddress3: string;
  CustomerNo: string;

  Footer572: string;
  Header542: string;
  AddnlCharge563: string;
  SalesRepContact542: string;
  TimestampPdfReceived: string;
  HeaderGuid: string;

  toString() {
    let xx: string[] = [];
    for (let k of Object.keys(this)) xx.push(k + ":" + this[k] + "\n");
    return xx.join("");
  }
}

//[{"val@0@15@0":"ccc","val@0@15@1":"Terms",...},{"val@0@16@0":"xx"}]
export class Page {
  lines: object[];
}
export class Cell {
  key: string = "";
  val: string = "";
  page: string = "";
  line: string = "";
  cell: string = "";
  //returns page,line,cell from: {"val@0@0@0":"Invoice"}
  constructor(x: object) {
    this.key = Object.keys(x)[0];

    this.val = x[this.key];
    this.page = this.key.split("@")[1];
    this.line = this.key.split("@")[2];
    this.cell = this.key.split("@")[3];
  }
}
