export var parseTableName = function (q: string) {
  if (!q) throw "parseTableName() doesnt work on undefined";
  q = q.replace(/\[/g, "").replace(/\]/g, "");
  let res = q;
  let parts = q.match(/from ([\w\.]+)/i); //get table eg Murphy..Customer
  if (parts) {
    //clog("parsetableName: " + JSON.stringify(parts));
    res = parts[1]; //get only the match part, not the entire thing. has:['from Customer','Customer']
    res.replace("dbo.", "");
    if (res.includes("..")) res = res.split("..")[1]; //returns Customer
  }
  return res;
};

export var getLongFilename = function (x: string) {
  try {
    if (!x.startsWith("Detail")) return x;
    x += " - ";
    switch (parseInt(x.match(/[0-9]+/)![0])) {
      case 10:
        return x + "company";
      case 11:
        return x + "sales team";
      case 12:
        return x + "sales rep";
      case 13:
        return x + "profit center";
      case 14:
        return x + "other grouping!!do not send, do not populate";
      case 15:
        return x + "Sales Team 2";
      case 16:
        return x + "Sales Team 3";
      case 17:
        return x + "Sales Team 4";
      case 18:
        return x + "Sales Team 5";
      case 20:
        return x + "inventory division";
      case 21:
        return x + "supplier";
      case 22:
        return x + "item group";
      case 23:
        return x + "brand";
      case 25:
        return x + "item type";
      case 26:
        return x + "item FamilyID";
      case 30:
        return x + "customer category";
      case 31:
        return x + "customer type";
      case 32:
        return x + "chain";
      case 33:
        return x + "county";
      case 34:
        return x + "region";
      case 35:
        return x + "country";
      case 36:
        return x + "traffic level";
      case 41:
        return x + "credit terms";
      case 42:
        return x + "credit reason";
      case 43:
        return x + "invoice type";
      case 50:
        return x + "delivery type";
      case 51:
        return x + "order source";
      case 52:
        return x + "item category";
      case 55:
        return x + "Co-ops";
      case 56:
        return x + "Co-op Customers";
      case 57:
        return x + "Chain or Customer Authorized Products";
      case 58:
        return x + "Chain/Customer Product Ordering";
      case 59:
        return x + "Product/Division";
      case 61:
        return x + "Single Item Deals";
      case 62:
        return x + "AR detail";
      case 63:
        return x + "Freight Zones";
      case 64:
        return x + "Rep Product Case Goals"!!;
      case 65:
        return x + "Rep Brand Case Goals"!!;
      case 66:
        return x + "Rep Group Case Goals"!!;
      case 67:
        return x + "Customer Company Notes"!!;
      case 68:
        return x + "FACTS customer reference code list"!!;
      case 69:
        return x + "FACTS contract price overrides"!!;
      case 70:
        return x + "Customer or Category specific Pricing"!!;
      case 71:
        return x + "Chain specific Pricing"!!;
      case 72:
        return x + "FACTS contract price Customer Class by Item Price Class";
      case 73:
        return x + "FACTS contract price Customer Class by Item";
      case 74:
        return x + "FACTS contract price Customer Class by Vendor";
      case 75:
        return x + "FACTS contract price Customer by All Items";
      case 76:
        return x + "FACTS contract price Customer by Item Price Class";
      case 77:
        return x + "FACTS contract price Customer by Item";
      case 78:
        return x + "FACTS contract price Customer by Vendor";
      case 79:
        return x + "FACTS whse location by rep (1-8)";
      case 80:
        return x + "FACTS whse location by rep (9-12)";
      case 81:
        return x + "Family1 Deals";
      case 82:
        return x + "Family2 Deals";
      case 83:
        return x + "Family3 Deals  (Combo Deals)"!!;
      case 84:
        return x + "FACTS warehouse location column heading captions (1-8)";
      case 85:
        return x + "FACTS warehouse location column heading captions (9-12)";
      case 86:
        return x + "Deal Descriptions";
      case 87:
        return x + "Price Levels";
      case 88:
        return x + "Product Allocations";
      case 89:
        return x + "Bill and Hold remaining quantity";
      case 90:
        return x + "PA Translation table";
      case 91:
        return x + "Delivery Instructions";
      case 92:
        return x + "Order pad by Rep";
      case 93:
        return x + "PocketAdvantage Return reasons!!Discontinued.Do not send";
      case 94:
        return x + "PocketAdvantage Samples reasons!!Discontinued. Do not send";
      case 95:
        return x + "Open Invoices";
      case 96:
        return x + "Backordered/Sampled";
      case 97:
        return x + "Out of Stock";
      case 98:
        return x + "ProductsBought";
      case 99:
        return x + "Allocation /Last Discount";
      case 101:
        return x + "Deal4 Header";
      case 102:
        return x + "Deal4 Products to Buy";
      case 103:
        return x + "Deal4 Free Goods / Discounted Combo Choice list";
      case 104:
        return x + "Channel Pricing";
      case 105:
        return x + "List Price";
      case 106:
        return x + "Deal Invoiced (checking for Extended Cases  availability)";
      case 107:
        return (
          x + "Extended Invoiced (checking for Extended Cases  availability)"
        );
      case 108:
        return x + "Level Pricing";
      case 109:
        return x + "MultiProduct Level Pricing";
      case 111:
        return x + "Item Order Limit By Date Range";
      case 112:
        return x + "Date Ranges";
      case 113:
        return x + "Future Price List for PockAdv";
      case 121:
        return x + "Sample Budget Remaining for Order Approval";
      case 123:
        return x + "Family Deal List";
      case 124:
        return x + "Multiple Product Family Deals";
      case 125:
        return x + "One Time Family Deal Invoiced";
      case 126:
        return x + "Multiple Product Family Deals Free Goods list";
      case 133:
        return x + "Replacement Items";
      case 141:
        return x + "PockDelivDriver";
      case 142:
        return x + "PockDelivHelper";
      case 143:
        return x + "PockDelivVehicle";
      case 151:
        return x + "Show in Products Bought";
      case 158:
        return x + "ProductsBoughtExtended";
      case 161:
        return x + "Chargeback Fund";
      case 162:
        return x + "Supplier Fund";
      case 171:
        return x + "Sellsheet Users";
      case 181:
        return x + "Items Rep can Sell to ANY customer";
      case 182:
        return x + "Items Rep can Sell by Customer";
      case 131:
        return x + "Targeted Products";
      case 132:
        return x + "Suggested order";
      case 141:
        return x + "Rep Customer Call Sequence";
      case 142:
        return x + "Additional Territories";
      default:
        return x + "- UNKNOWN TYPE";
    }
  } catch (e) {
    return x + "- UNKNOWN";
  }
};
